<section id="main">
    <section class="hero has-background-secondary is-overlapped">
        <div class="hero-body">
            <div class="container">
                <div class="field">
                    <a class="link has-icon is-inverted" target="_blank" (click)="goToQualification()">
                        <span class="icon is-small">
                            <i class="tri-arrow-left" aria-hidden="true"></i>
                            <span>Retour</span>
                        </span>
                    </a>
                </div>
                <h1 class="title is-level-2 is-loaded is-inverted">Boite à outils</h1>
                <div class="suptitle is-inverted">
                    Sur cette page, vous trouverez différents accordéons vous permettant d'accéder aux différents
                    environnements de la télévente.
                </div>
            </div>
        </div>
    </section>
    <section class="section is-loaded">
        <div class="container">
            <div class="box is-loaded">
                <div class="is-centered is-flex is-flex-widescreen is-aligned-center ap-version">
                    <div class="title is-level-2 is-loaded is-marginless">Choisissez une version d'AP :</div>
                    <div class="is-flex">
                        <div class="control has-dynamic-placeholder">
                            <input [(ngModel)]="ap_version" id="ap_version"  class="input is-default apInput" value="" placeholder="apXXXX" type="text">
                            <label for="ap_version">Version de l'AP: </label>
                        </div>
                        <div class="select">
                            <select [(ngModel)]="ap_version" class="apSelect" id="apList">
                                <option *ngIf="!apRegexPattern.test(ap_version)" value="">apXXXX</option>
                                <option *ngFor="let apItem of apList" [value]="apItem.value">{{ apItem.value }}</option>
                                <option *ngFor="let environment of environments" [value]="getDomainFromUrl(environment)">{{ getDomainFromUrl(environment).split('.')[0] }}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div *ngIf="!apRegexPattern.test(ap_version) && !environments.map(getDomainFromUrl).includes(ap_version)" class="field">
                    <div class="alert has-body is-error is-flex is-justified-center">
                        <span class="icon is-flex"><i class="tri-exclamation-circle" aria-hidden="true"></i></span>
                        <div class="body">
                            <div class="title is-level-3 is-loaded">Attention, le format du numero d'AP est incorrect !</div>
                        </div>
                        <span class="icon is-flex"><i class="tri-exclamation-circle" aria-hidden="true"></i></span>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section *ngIf="ap_version && (apRegexPattern.test(ap_version) || environments.map(getDomainFromUrl).includes(ap_version))" class="accordionsContainer columns">
        <div class="column">
            <section class='accordions'>
                <article class='accordion'[ngClass]="{'is-active': currentAccordion === ACCORDION_TYPES.SALESFORCE}">
                    <div class='accordion-header' (click)="currentAccordion = ACCORDION_TYPES.SALESFORCE">
                        SalesForce
                        <button class='toggle' aria-label='toggle' type='button'></button>
                    </div>
                    <div class='accordion-body is-clipped'>
                        <div class='accordion-content'>
                            <form [formGroup]="urlGeneratorForm" (ngSubmit)="onSubmitSalesforce()">
    
                                <div class="columns">
                                    <div class="column">
                                        <div class="field">
                                            <div class="control">
                                                <div class="select">
                                                    <select id="typeappel" formControlName="typeappel">
                                                        <option value="">- Choisissez le type d'appel -</option>
                                                        <option *ngFor="let option of typeAppelOptions">
                                                            {{ option.name }}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        
            
                                        <div class="field">
                                            <div class="control has-dynamic-placeholder">
                                                <input id="provenanceappel" class="input is-default" value="" placeholder="provenanceappel" type="text" formControlName="provenanceappel">
                                                <label for="provenanceappel">Provenance de l'appel: </label>
                                            </div>
                                        </div>
                                        
            
                                        <div class="field">
                                            <div class="control has-dynamic-placeholder">
                                                <input id="codecampagne" class="input is-default" value="" placeholder="codecampagne" type="text" formControlName="codecampagne">
                                                <label for="codecampagne">Code campagne: </label>
                                            </div>
                                        </div>
            
                                        <div class="field">
                                            <div class="control">
                                                <div class="select">
                                                    <select id="typecontact" formControlName="typecontact">
                                                        <option value="">- Choisissez le type de contact -</option>
                                                        <option *ngFor="let option of typeContactOptions">
                                                            {{ option.name }}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
            
                                        <div class="field">
                                            <div class="control">
                                                <div class="select">
                                                    <select id="type_vente" formControlName="type_vente">
                                                        <option value="">- Choisissez le type de vente -</option>
                                                        <option *ngFor="let option of typeVenteOptions">
                                                            {{ option.name }}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
            
                                        <div class="field">
                                            <div class="control has-dynamic-placeholder">
                                                <input id="lead_email" class="input is-default" value="" placeholder="lead_email" type="text" formControlName="lead_email">
                                                <label for="lead_email">Lead email: </label>
                                            </div>
                                        </div>
            
                                        <div class="field">
                                            <div class="control has-dynamic-placeholder">
                                                <input id="lead_id" class="input is-default" value="" placeholder="lead_id" type="text" formControlName="lead_id">
                                                <label for="lead_id">Lead ID: </label>
                                            </div>
                                        </div>
            
                                        <div class="field">
                                            <div class="control has-dynamic-placeholder">
                                                <input id="pu_id" class="input is-default" value="" placeholder="pu_id" type="text" formControlName="pu_id">
                                                <label for="pu_id">PU ID: </label>
                                            </div>
                                        </div>
            
                                        <div class="field">
                                            <div class="control has-dynamic-placeholder">
                                                <input id="prm_id" class="input is-default" value="" placeholder="prm_id" type="text" formControlName="prm_id">
                                                <label for="prm_id">PRM ID: </label>
                                            </div>
                                        </div>
    
                                        <div class="field">
                                            <div class="control has-dynamic-placeholder">
                                                <input id="lead_codepostal" class="input is-default" value="" placeholder="lead_codepostal" type="text" formControlName="lead_codepostal">
                                                <label for="lead_codepostal">Lead code postal: </label>
                                            </div>
                                        </div>
                                    </div>
        
                                    <div class="column">
                                        <div class="field">
                                            <div class="control has-dynamic-placeholder">
                                                <input id="lead_ville" class="input is-default" value="" placeholder="lead_ville" type="text" formControlName="lead_ville">
                                                <label for="lead_ville">Lead ville: </label>
                                            </div>
                                        </div>
            
                                        <div class="field">
                                            <div class="control has-dynamic-placeholder">
                                                <input id="lead_nomdevoie" class="input is-default" value="" placeholder="lead_nomdevoie" type="text" formControlName="lead_nomdevoie">
                                                <label for="lead_nomdevoie">Lead nom de la voie: </label>
                                            </div>
                                        </div>
            
                                        <div class="field">
                                            <div class="control has-dynamic-placeholder">
                                                <input id="lead_numdevoie" class="input is-default" value="" placeholder="lead_numdevoie" type="text" formControlName="lead_numdevoie">
                                                <label for="lead_numdevoie">Lead num de voie: </label>
                                            </div>
                                        </div>
            
                                        <div class="field">
                                            <div class="control has-dynamic-placeholder">
                                                <input id="lead_compvoie" class="input is-default" value="" placeholder="lead_compvoie" type="text" formControlName="lead_compvoie">
                                                <label for="lead_compvoie">Lead compvoie: </label>
                                            </div>
                                        </div>
    
                                        <div class="field">
                                            <div class="control">
                                                <div class="select">
                                                    <select id="lead_salutation" formControlName="lead_salutation">
                                                        <option value="">- Choisissez la salutation -</option>
                                                        <option *ngFor="let option of leadSalutationsOptions">
                                                            {{ option.name }}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
            
                                        <div class="field">
                                            <div class="control has-dynamic-placeholder">
                                                <input id="lead_lastname" class="input is-default" value="" placeholder="lead_lastname" type="text" formControlName="lead_lastname">
                                                <label for="lead_lastname">Lead lastname: </label>
                                            </div>
                                        </div>
            
                                        <div class="field">
                                            <div class="control has-dynamic-placeholder">
                                                <input id="lead_firstname" class="input is-default" value="" placeholder="lead_firstname" type="text" formControlName="lead_firstname">
                                                <label for="lead_firstname">Lead firstname: </label>
                                            </div>
                                        </div>
            
                                        <div class="field">
                                            <div class="control has-dynamic-placeholder">
                                                <input id="lead_mobile" class="input is-default" value="" placeholder="lead_mobile" type="text" formControlName="lead_mobile">
                                                <label for="lead_mobile">Lead mobile: </label>
                                            </div>
                                        </div>
            
                                        <div class="field">
                                            <div class="control has-dynamic-placeholder">
                                                <input id="sourceprospect" class="input is-default" value="" placeholder="sourceprospect" type="text" formControlName="sourceprospect">
                                                <label for="sourceprospect">Source prospect: </label>
                                            </div>
                                        </div>
            
                                        <div class="field">
                                            <div class="control has-dynamic-placeholder">
                                                <input id="caseopportunite" class="input is-default" value="" placeholder="caseopportunite" type="text" formControlName="caseopportunite">
                                                <label for="caseopportunite">Case opportunite: </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
    
                                <div class="alert has-body is-info">
                                    <p class="text is-loaded is-wrapped">
                                        {{salesforceUrl}}
                                    </p>
                                </div>
                                
                                <div class="field">
                                    <button class="button is-loaded is-secondary is-fullwidth" type="button" (click)="autoComplete()">
                                        <span class="icon"><i class="tri-good-practices" aria-hidden="true"></i></span>
                                        Préremplir le formulaire
                                    </button>
                                </div>

                                <div class="field">
                                    <button class="button is-loaded is-primary is-fullwidth" type="submit">
                                        <span class="icon"><i class="tri-check-circle" aria-hidden="true"></i></span>
                                        Accéder à la TLV
                                    </button>
                                </div>
                                
                            </form>
                        </div>
                    </div>
                </article>
                <article class='accordion' [ngClass]="{'is-active': currentAccordion === ACCORDION_TYPES.REPRISE_COMMANDE}">
                    <div class='accordion-header' (click)="currentAccordion = ACCORDION_TYPES.REPRISE_COMMANDE">
                        Reprise Blanche
                        <button class='toggle' aria-label='toggle' type='button'></button>
                    </div>
                    <div class='accordion-body is-clipped'>
                        <div class='accordion-content'>
                            <form [formGroup]="repriseCommandeForm" (ngSubmit)="onSubmitCommandeKo()">
    
                                <div class="field">
                                    <div class="control has-dynamic-placeholder">
                                        <input id="commandeId" class="input is-default" value="" placeholder="ID de commande" type="text" formControlName="commandeId">
                                        <label for="commandeId">ID de commande: </label>
                                    </div>
                                </div>

                                <div class="alert has-body is-info">
                                    <p class="text is-loaded is-wrapped">
                                        {{commandekoUrl}}
                                    </p>
                                </div>

                            <div class="field">
                                <button class="button is-loaded is-primary is-fullwidth" type="submit" [disabled]="!repriseCommandeForm.valid">
                                    <span class="icon"><i class="tri-check-circle" aria-hidden="true"></i></span>
                                    Valider
                                </button>
                            </div>
                            </form>
                        </div>
                    </div>
                </article>
                <article class='accordion' [ngClass]="{'is-active': currentAccordion === ACCORDION_TYPES.REPRISE_ID}">
                    <div class='accordion-header' (click)="currentAccordion = ACCORDION_TYPES.REPRISE_ID">
                        Reprise ID PU
                        <button class='toggle' aria-label='toggle' type='button'></button>
                    </div>
                    <div class='accordion-body is-clipped'>
                        <div class='accordion-content'>
                            <form [formGroup]="repriseIdPuForm" (ngSubmit)="onSubmitReprisePu()">
                                <div class="field">
                                    <div class="control has-dynamic-placeholder">
                                        <input id="pu_id" class="input is-default" value="" placeholder="ID de reprise" type="text" formControlName="pu_id">
                                        <label for="pu_id">ID de commande: </label>
                                    </div>
                                </div>

                                <div class="alert has-body is-info">
                                    <p class="text is-loaded is-wrapped">
                                        {{reprisepuUrl}}
                                    </p>
                                </div>

                                <div class="field">
                                    <button class="button is-loaded is-primary is-fullwidth" type="submit" [disabled]="!repriseIdPuForm.valid">
                                        <span class="icon"><i class="tri-check-circle" aria-hidden="true"></i></span>
                                        Valider
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </article>
            </section>
        </div>
        <div class="column">
            <section class='accordions' data-accordion-initialized='true'>
                <article class='accordion' class='accordion'[ngClass]="{'is-active': currentAccordion === ACCORDION_TYPES.LISTE_IHM}">
                    <div class='accordion-header' (click)="currentAccordion = ACCORDION_TYPES.LISTE_IHM">
                        Liste des différentes IHM
                        <button class='toggle'  aria-label='toggle' type='button'></button>
                    </div>
                    <div class='accordion-body is-clipped'>
                        <div class='accordion-content'>
                            <div class="field columns">
                                <div class="column">
                                    <form (ngSubmit)="onSubmitPromo()">
                                        <div class="field">
                                            <button class="button is-loaded is-primary is-fullwidth is-flex" type="submit">
                                                IHM de Promotions
                                                &nbsp;
                                                <span class="icon is-small">
                                                    <i class="tri-new-tabbed-page" aria-hidden="true"></i>
                                                </span>
                                            </button>
                                        </div>
                                    </form>
                                </div>
                                <div class="column">
                                    <form (ngSubmit)="onSubmitNotif()">
                                        <div class="field">
                                            <button class="button is-loaded is-primary is-fullwidth is-flex" type="submit">
                                                IHM de Notifications
                                                &nbsp;
                                                <span class="icon is-small">
                                                    <i class="tri-new-tabbed-page" aria-hidden="true"></i>
                                                </span>
                                            </button>
                                        </div>
                                    </form>
                                </div>
                                <div class="column">
                                    <form (ngSubmit)="onSubmitClosing()">
                                        <div class="field">
                                            <button class="button is-loaded is-primary is-fullwidth is-flex" type="submit">
                                                IHM de Closing
                                                &nbsp;
                                                <span class="icon is-small">
                                                    <i class="tri-new-tabbed-page" aria-hidden="true"></i>
                                                </span>
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>
                <article class='accordion' [ngClass]="{'is-active': currentAccordion === ACCORDION_TYPES.MAGENTO_BO}">
                    <div class='accordion-header' (click)="currentAccordion = ACCORDION_TYPES.MAGENTO_BO">
                        Magento BO
                        <button class='toggle'  aria-label='toggle' type='button'></button>
                    </div>
                    <div class='accordion-body is-clipped'>
                        <div class='accordion-content'>
                            
                            <form (ngSubmit)="onSubmitMagentoBo()">
    
                                <div class="field">
                                    <button class="button is-loaded is-primary is-fullwidth" type="submit">
                                        Accéder à Magento
                                        &nbsp;
                                        <span class="icon is-small">
                                            <i class="tri-new-tabbed-page" aria-hidden="true"></i>
                                        </span>
                                    </button>
                                </div>
    
                            </form>
                        </div>
                    </div>
                </article>
                <article class="accordion" [ngClass]="{'is-active': currentAccordion === ACCORDION_TYPES.BRANCHES_EPHEMERES}">
                    <div class="accordion-header" (click)="currentAccordion = ACCORDION_TYPES.BRANCHES_EPHEMERES">
                        Branches Ephémères
                        <button class="toggle" aria-label="toggle" type="button"></button>
                    </div>
                    <div class="accordion-body is-clipped">
                        <div class="accordion-content">
                            <p class="text is-fullwidth has-text-centered is-marginless" *ngIf="!environments?.length"> Aucune branche trouvée pour le moment... </p>
                            <div class="is-flex eph-container">
                                <div *ngFor="let environment of environments" class="eph-item-container">
                                    <a href="{{ environment }}" target="_blank" rel="noopener" class="button is-loaded is-secondary is-fullwidth">
                                        {{ getDomainFromUrl(environment).split('.')[0] }}
                                        &nbsp;
                                        <span class="icon is-small">
                                            <i class="tri-new-tabbed-page" aria-hidden="true"></i>
                                        </span>
                                    </a>
                                </div>
                            </div>
                            <hr class="has-background-grey-dark field">
                            <button class="button is-loaded is-primary is-fullwidth" type="button" (click)="onAccordionOpened()">
                                <span class="icon"><i class="tri-refresh" aria-hidden="true"></i></span>
                                Rafraichir la liste
                            </button>
                        </div>
                    </div>
                </article>
            </section>
        </div>
    </section>
</section>