import { Fai, FaiBox, ProductFactory, Product, Subscription, Equipment } from '@bytel/bytel-sales';
import { EquipmentComponent } from './product-block/equipment/equipment.component';
import { FaiBoxComponent } from './product-block/fai-box/fai-box.component';
import { FaiComponent } from './product-block/fai/fai.component';
import { SubscriptionComponent } from './product-block/subscription/subscription.component';

export class CartComponentMapping {

    public static getComponent<T extends Product>(product: T): any {

        let component: any = EquipmentComponent;

        switch (true) {
            case ProductFactory.Is(product, Fai) :
                component = FaiComponent;
                break;

            case ProductFactory.Is(product, FaiBox) :
                component = FaiBoxComponent;
                break;

            case ProductFactory.Is(product, Subscription) :
                component = SubscriptionComponent;
                break;

            case ProductFactory.Is(product, Equipment) :
                component = EquipmentComponent;
                break;
        }

        return component;
    }
}
