import { BasicObject } from '@common-modules';


export interface CartApi {
    canal: string;
    contratSigne?: string;
    idCommande?: string;
    idDossier?: string;
    idOrcom?: string;
    idPanier?: number;
    idPanierRetours?: string;
    version?: string;
    idPanierCrypte?: string;
    parcourCourant?: number;
    parcours?: ParcoursApi[];
    referenceExterne?: string;
    typologieAppel: string;
    campagne: string;
}

export interface Consent {
    toBeContacted: boolean;
    toBeContactedMailSms: boolean;
    toBeContactedProposal: boolean;
}

export interface ParcoursApi {
    consent?: Consent;
    contextProduit: ProductContextApi;
    idParcours?: number;
    idUnique?: string;
    produits?: ProductApi[];
    typeRegles?: string;
    idEligFai?: string;
}


export interface ProductApi {
    configuration?: BasicObject;
    data?: JsonProduct;
    gencode: string;
    idParcours?: number;
    idProduit?: number;
    idUnique: string;
    prix: number;
    sku: string;
    type_id?: string;
    technology?: string;
    eligTech?: BasicObject;
    appointement?: BasicObject;
}

export interface ProductContextApi {
    contextService?: ContextServiceApi;
    documents?: DocumentApi[];
    edp?: boolean;
    edpData?: EdpData;
    ocPriceData?: OcPriceData;
    plan?: string;
    rsfData?: RsfDataApi;
}

export interface DocumentApi {
    id: number;
    type?: string;
}

export interface RsfDataApi {
    rsfAmount?: number;
    rsfPdf?: string;
}

export interface ContextServiceApi {
    acquisition?: AcquisitionContextApi;
    acquisitionFix?: BasicObject;
    client?: CustomerContextApi;
    parcours: BrowseConfigApi;
    promo?: BasicObject;
    renouvellement?: BasicObject;
    sav?: SavContextApi;
    scoring?: BasicObject;
    utilisateur?: BasicObject;
}

export interface SavContextApi {
    contractId: number;
    eligible: boolean;
}

export interface AcquisitionContextApi {
    idContrat?: number;
}

export interface ContractApi {
    _actions?: BasicObject;
    _links?: BasicObject;
    abonnement: ContractSubscriptionApi;
    contratsAppaireDataPartagee: BasicObject;
    id: number;
    idPfs: string;
    ligneMarche: string;
    numeroTel: string;
    partitionSic: string;
    status: string;
    typeLigne: string;
    utilisateur: BasicObject;
}

interface ContractSubscriptionApi {
    _links: BasicObject;
    dateActivation: string;
    libelle: string;
    offreDataMobile: boolean;
    offrePremium: boolean;
    offreTokyo: boolean;
}

export interface CustomerContextApi {
    batiment?: string;
    categorie?: CUSTOMER_CATEGORY;
    cite?: string;
    civilite?: string;
    codePostal?: string;
    company?: ScreeningApi;
    complementAdresse?: string;
    comptePayeur?: BasicObject;
    contractId?: string;
    contrats?: ContractApi[];
    dateFromSI?: boolean;
    dateNaissance?: string;
    departementNaissance?: string;
    editable?: boolean;
    email?: string;
    escalier?: string;
    etage?: string;
    idIdentite?: string;
    idPays?: string;
    ligneMarche?: string;
    lockedInfo?: string[];
    nom?: string;
    numeroRue?: string;
    personId?: number;
    possibleIbans?: string[];
    powerletter?: boolean;
    prenom?: string;
    residence?: string;
    rue?: string;
    telephone?: string;
    telephonePrepaye?: string;
    type?: CUSTOMER_TYPE;
    rna?: string;
}

export interface ScreeningApi {
    adresseDeclaree?: AddressProApi;
    codeApeNaf?: string;
    codeEffectif?: string;
    codeNIC?: string;
    dateCreation?: string;
    error?: string;
    errorDescription?: string;
    formeJuridique?: string;
    noSiren?: string;
    raisonSociale?: string;
    representantLegal?: RepresentantLegalApi;
    situationEntreprise?: string;
}

export interface RepresentantLegalApi {
    civilite?: string;
    dateNaissance?: string;
    departementNaissance?: string;
    nom: string;
    prenom: string;
}

export interface AddressProApi {
    cp: string;
    num: string;
    ville: string;
    voie: string;
}

export interface BrowseConfigApi {
    activationAutomatique?: boolean;
    adresseMailProspect?: string;
    baseRoute?: string[];
    bic?: string;
    categorieProspect?: CUSTOMER_CATEGORY;
    cite?: string;
    civilite?: string;
    codePostal?: string;
    complementAdresse?: string;
    comptePayeur?: PaymentApi;
    dateCreationEntrepriseProspect?: string;
    dateEffet?: string;
    dateNaissanceProspect?: string;
    debug?: string;
    emailAccesPartenaire?: string;
    iban?: string;
    idCommande?: string;
    idContrat?: string;
    idPU?: number;
    idOpportunite?: string;
    idTemporaireProspect: string;
    idProspect: string;
    idPanier?: number;
    idParcours?: number;
    lieuNaissanceProspect?: string;
    ligneMarche?: string;
    matricule?: string;
    motifSouscriptionCle?: string;
    nomProspect?: string;
    numeroRue?: string;
    parcoursOffreAdditionnelle?: string;
    partnerCustomerId?: string;
    phone?: string[];
    phoneRoute?: string[];
    prenomProspect?: string;
    raisonSocialeProspect?: string;
    referenceAppareil?: string;
    referenceExterne?: string;
    rue?: string;
    sirenProspect?: string;
    telephonePrepaye?: string;
    telephoneProspect?: string;
    typeAction?: string;
    sourceProspect?: string;
    typeParcours?: string;
    typeVisiteur?: string;
    urlCallBack?: string;
    urlMaintienSession?: string;
}

export interface PaymentApi {
    action?: string;
    bic?: string;
    customerCode?: string;
    iban?: string;
    ligneMarche?: string;
    maskIban?: string;
    payerAccount?: number;
    paymentMode?: PAYMENT_MODE;
    paymentModeFMS?: PRICE_TYPES;
    proofOfPayment?: string;
    quoteId?: number;
    rum?: string;
    type?: string;
}

export interface PortabilityPricingSim {
    aUnePortabilite: PricingSim;
    nAPasDePortabilite: PricingSim;
}

export interface PricingSim {
    price: number;
    type?: string;
}

/* Catalog Interfaces */

export interface JsonProduct {
    _links?: BasicObject[];
    allowed_roles?: string;
    bullet_description?: string;
    can_migrate?: boolean;
    can_show_details?: boolean;
    categories?: string[];
    description?: string;
    ecotax?: string | number;
    edp?: EdpData;
    edp_phone?: EdpData;
    entity_id?: number;
    final_price?: number;
    gencode: string;
    image?: string;
    imageHd?: string;
    in_stock?: boolean;
    is_preorder?: boolean;
    manufacturer?: string;
    medias?: string[];
    name: string;
    obligation?: string;
    obligation_id?: string;
    plan_bonus: string;
    play?: string;
    price?: number;
    prices?: {'min': ArrayStringIndex; 'plans': ObjectOfType<PriceType>};
    product_ids?: string[];
    qty?: number;
    relatedProduct: BasicObject;
    rsf?: RsfDataApi;
    sim_type?: string;
    sku: string;
    soon_exhausted?: boolean;
    technology?: string;
    type_id: string;
    type_service?: string;
    upSellsProducts: BasicObject[];
    url?: string;
    url_key?: string;

    [index: string]: any;
}

export interface PriceType {
    acquisition: MinPrice;
    edp?: EdpData;
    renew: RenewPrice;
    sort_order?: number;
}

export interface RenewPrice {
    client: PriceClientType;
    collaborator: PriceClientType;
}

export interface PriceClientType {
    [index: string]: MinPrice;
}

export interface EdpData {
    amount?: number;
    duration?: number;
    price?: number;
}

export interface OcPriceData {
    fmdPrice: number;
    rentalPrice: number;
    zndPrice: number;
}

export interface MinPrice {
    edp?: boolean;
    edp_duration?: number;
    edp_price?: number;
    price?: number;
}

export interface ObjectOfType<interfaceType> {
    [index: string]: interfaceType;
}

export interface ArrayStringIndex {
    [index: string]: ArrayStringIndex;
}

/* ENUM */

export enum CUSTOMER_CATEGORY {
    GP = 'GP',
    PRO = 'PRO',
    ENTREPRISE = 'ENTREPRISE'
}

export enum CUSTOMER_TYPE {
    CLIENT = 'CLIENT',
    PROSPECT = 'PROSPECT'
}

export enum PRICE_TYPES {
    Today,
    EvryMonth,
    Other,
    Reported,
    EvryMonthIncluded
}

export enum PAYMENT_MODE {
    PRELEVEMENT = 'PRELEVEMENT',
    CB_OU_CHEQUE = 'CB_OU_CHEQUE'
}
