import { Component, Input } from '@angular/core';
import { Product } from '@bytel/bytel-sales';
import { DialogService } from '@ngneat/dialog';

import { OptionsModalComponent } from '../../../modals/option/option-modal.component';

@Component({
    selector: 'tlv-option-block',
    templateUrl: './option-block.component.html',
    styleUrls: ['./option-block.component.scss']
})
export class OptionBlockComponent {

    @Input() public product: Product;
    @Input() public quoteIndex: number;

    constructor(private dialogService: DialogService) {}

    public showDetails(product: Product): void {
        this.dialogService.open(OptionsModalComponent, { data: { product, quoteIndex: this.quoteIndex } });
    }

}
