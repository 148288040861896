import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { IStep, STEP_STATUS } from '@interfaces/step.interface';
import { CustomerProDetailsModel } from '@models/customer/customer-pro-details.model';
import { HotToastService } from '@ngneat/hot-toast';
import { CartTeleSalesService } from '@services/checkout/cart-telesales.service';
import { CustomerService } from '@services/customer/customer.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class CustomerInfoStep implements IStep{

    public icon: string = 'customerInfo';
    public name: string = 'Info-client';
    public label: string = 'Coordonnées';
    public route: string[] = [
        'panier',
        'info-client'
    ];
    public status: STEP_STATUS = STEP_STATUS.WAITING;
    public canBeValidate: boolean = false;

    constructor(private cartTeleSalesService: CartTeleSalesService,
                protected customerService: CustomerService,
                protected toastService: HotToastService,
                @Inject(DOCUMENT) private document: Document) {}

    public validateStep(): boolean {
        return true;
    }

    public canAccess(): boolean {
        if (!this.canDisplay()){
            return false;
        }
        if (!this.customerService.customer){
            this.customerService.searchCustomerIhm.next(true);
            this.document.defaultView.window.scrollTo(0, 0);
            this.toastService.warning('Recherche client est obligatoire pour passer à l\'étape suivante !');
            return false;
        }
        if (this.cartTeleSalesService.cartModel.getQuote().hasProProduct()
            && !(this.customerService?.customer as CustomerProDetailsModel).company) {
            this.toastService.warning('Recherche siren obligatoire pour passer à l\'étape suivante !');
            return false;
        }
        return true;
    }

    public canActivate(): Observable<boolean> {
        return this.cartTeleSalesService.resolve().pipe(map(()=>this.canAccess()));
    }

    public canDisplay(): boolean {
        return !this.cartTeleSalesService.cartModel.isCartEmpty();
    }
}
