import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IStep, STEP_STATUS } from '@interfaces/step.interface';
import { HotToastService } from '@ngneat/hot-toast';
import { CartTeleSalesService } from '@services/checkout/cart-telesales.service';

@Injectable({
    providedIn: 'root'
})
export class CartStep implements IStep{

    public icon: string = 'cart';
    public name: string = 'Panier';
    public label: string = 'Récapitulatif';
    public route: string[] = ['panier'];
    public canBeValidate: boolean = false;
    public status: STEP_STATUS = STEP_STATUS.WAITING;

    constructor(
        private readonly cartService: CartTeleSalesService,
        private toastService: HotToastService,
        private router: Router,
    ){}

    public validateStep(): boolean {
        try {
            return this.cartService.cartModel.getQuote()?.checkIsValid();
        } catch (err) {
            this.toastService.warning(err.message, { duration: 1000 });
            return false;
        }
    }

    public canAccess(): boolean {
        return this.canDisplay();
    }

    public canActivate(): boolean {
        return this.canAccess();
    }

    public canDisplay(): boolean {
        const canDisplay = !this.cartService.cartModel?.isCartEmpty() && !this.cartService.isQuoteMixed() ;
        if (!canDisplay && this.cartService.isQuoteMixed() && this.router.url.split('?')[0].split('#')[0]  === '/panier' ) {
            this.router.navigate(['panier','mobile']);
        }

        return canDisplay;
    }

}
