import { DOCUMENT } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, Inject, OnDestroy, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {
    Accessory,
    Activation,
    CartModel,
    Equipment,
    Fai,
    Option,
    Plan,
    PRICE_TYPES,
    Product,
    ProductFactory, QuoteContextFaiModel,
    QuoteModel,
    Sim,
    TYPE_MARKETING
} from '@bytel/bytel-sales';
import { SummaryOrderModalComponent } from '@components/checkout/step/payment/summary-order-modal/summary-order-modal.component';
import { CustomerProGpDetailsModel } from '@interfaces/customer.interface';
import { IPaymentFormData, NO_PAYMENT, PAYMENT_METHODS } from '@interfaces/payment.interface';
import { AddressModel } from '@models/cart/address.model';
import { AppointmentModel } from '@models/cart/appointment.model';
import { PortabilityModel } from '@models/cart/portability.model';
import { FaiCartModel } from '@models/fai/fai-cart.model';
import { OrderModel } from '@models/order/order.model';
import { PaymentResponseModel } from '@models/payment-response.model';
import { ScoringModel } from '@models/scoring.model';
import { DialogRef, DialogService } from '@ngneat/dialog';
import { HotToastService } from '@ngneat/hot-toast';
import { ProductRepository } from '@repositories/product.repository';
import { CheckoutStorage } from '@repositories/storages/checkout.storage';
import { FaiStorage } from '@repositories/storages/fai.storage';
import { CallRecordService } from '@services/call-record.service';
import { CatalogService } from '@services/catalog.service';
import { AddressService } from '@services/checkout/address.service';
import { AppointmentService } from '@services/checkout/appointment.service';
import { CartTeleSalesService } from '@services/checkout/cart-telesales.service';
import { DeliveryService } from '@services/checkout/delivery.service';
import { PortabilityService } from '@services/checkout/portability.service';
import { SalesService } from '@services/checkout/sales.service';
import { ScoringService } from '@services/checkout/scoring.service';
import { CustomerService } from '@services/customer/customer.service';
import { FaiEligibilityService } from '@services/fai/fai-eligibility.service';
import { FundingService } from '@services/funding.service';
import { MobileRecoveryService } from '@services/mobile-recovery.service';
import { SalesUserService } from '@services/sales-user.service';
import { SalesForceService } from '@services/salesforce.service';
import { EdpService } from '@services/checkout/edp.service';
import { forkJoin, merge, Observable, of, Subject, Subscription } from 'rxjs';
import { catchError, delayWhen, finalize, map, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import bind from 'src/app/helper/decorators/bind';
import {
    AppointmentCancellationWarningModalComponent
} from './appointment-cancellation-warning-modal/appointment-cancellation-warning-modal.component';
import { MainCartModel } from '@models/cart/main-cart.model';
import { OrderService } from '@services/order.service';
import { SimService } from '@services/checkout/sim.service';
import { QualificationService } from '@services/qualification.service';
import { OpportunityService } from '@services/opportunity.service';
import { OptionCategory } from 'src/app/constants/options';
import {POLITIQUE_TARIFAIRE} from '@interfaces/delivery.inteface';
import { PaymentMethodsService } from '@services/checkout/payment-methods.service';
import {PrismeLoggerService} from '@services/prisme-logger.service';
import { HTTP_ERROR, OpenBankingService } from '@services/checkout/open-banking.service';
import { RechercherVerificationBancaireOut } from '@bytel/pt-ihm-api-r-banque-verification-bancaire-rechercher-verification-bancaire';
import { ControleRisqueVente, ResultatControleUnitaire } from '@bytel/pt-ihm-api-egide-controle-risque-vente-demander-autorisation-vente';
import { CancelOpenBankingModalComponent } from './cancel-open-banking-modal/cancel-open-banking-modal.component';
import { IChecksCarriedOut } from '@interfaces/scoring.interface';
import { ConfigurationService } from 'src/app/common-modules/services/configuration.service';

interface IFmsChoice {
    value: boolean;
    disabled: boolean;
}

export enum OPEN_BANKING_COMMUNICATION_TYPE {
    SMS = 'SMS',
    MAIL = 'MAIL'
}

export interface SubmitOpenBankingEventPayload {
    email: string;
    phone: string;
    type: OPEN_BANKING_COMMUNICATION_TYPE;
}

const TOAST_DURATION = 3000;

@Component({
    selector: 'tlv-payment-step',
    templateUrl: './payment.component.html',
    styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnDestroy {

    public PRICE_TYPES: typeof PRICE_TYPES = PRICE_TYPES;
    public billingAddress: string;
    public shippingAddress: string;
    public shippingAddressSim: string;
    public customer: CustomerProGpDetailsModel;
    public quote: QuoteModel;
    public cartModel: MainCartModel;
    public plan: Plan;

    public TYPE_MARKETING: typeof TYPE_MARKETING = TYPE_MARKETING;
    public showIban: boolean = true;
    public addressTypes: { [key: string]: string } = {
        BILLING: 'BILLING',
        SHIPPING: 'SHIPPING',
        INSTALLATION: 'INSTALLATION',
        DELIVERYBOX: 'DELIVERYBOX',
        DELIVERYSIM: 'DELIVERYSIM',
    };

    public addressType: string = this.addressTypes.BILLING;
    public showPaymentStep: boolean = true;
    public isFai: boolean = false;
    public isFaiOnly: boolean = false;
    public isCDL: boolean = false;
    public isLoading: boolean = false;
    public isLoadingIban: boolean = false;
    public isLoadingGetOpenBankingIban: boolean = false;
    public isLoadingOpenBanking: boolean = false;
    public paymentForm: FormGroup = new FormGroup({
        iban: new FormControl<boolean | null>(null, [Validators.required]),
        paymentType: new FormControl<boolean | null>(null, [Validators.required]),
        chooseFMS: new FormControl<IFmsChoice | null>({ value: true, disabled: true }, [Validators.required])
    });
    public ALLOWED_PAYMENT_METHODS: { [key: string]: string } = PAYMENT_METHODS;
    public PAYMENTS_METHODS_NAMES: { [key: string]: string } = {
        [PAYMENT_METHODS.CB]: 'REC',
        [PAYMENT_METHODS.HYBRIDE]: 'HYBRIDE'
    };
    public PAYMENTS_METHODS_NAMES_BYPASS: { [key: string]: string } = {
        [PAYMENT_METHODS.HYBRIDE]: 'HYBRIDE'
    };
    public paymentMethods: string[] = [];
    public hasEquipment: boolean = false;

    public paymentData: IPaymentFormData = {
        url: null,
        method: null,
        inputs: []
    };
    public unfoundProductImage: { [key: string]: string } = {
        sim: 'tri-sim-card',
        shipping: 'tri-truck',
        edp: 'tri-euros',
        qr_esim: 'tri-esim',
    };
    public hasESim: boolean = false;
    public hasESimOnly: boolean = false;
    // Native form is used to avoid CORS problem with external url from payment responses
    @ViewChild('paymentDataFormElement') paymentDataFormElement;
    @ViewChild('orderSummaryTpl') orderSummaryTpl;

    public hasFMSOptionInQuote: boolean = false;
    public showFmsPaymentOptionWarning: boolean = false;
    public fmsOptionProduct: Option;
    public priceFms: number;
    public portability: PortabilityModel;
    public phoneNumber: string;
    public appointment: AppointmentModel;
    public faiCart: FaiCartModel;
    public scoringModalSubscriptionAction: Subscription;
    public appointmentModalSubscriptionAction: Subscription;
    public noPayment: boolean = false;
    public cartHasMobileRecovery: boolean = false;
    public orderRecovery: OrderModel;
    public isFMSEditable: boolean;
    public fmsPaymentType: string;
    public isPro: boolean = false;
    public lockOrder: boolean = false;
    public cartIntegrityErrorMsg: string = 'Impossible de procéder à la création de la commande, veuillez vous rapprocher du support: ';
    public selectedStoreName: string = '';
    public products: Product[] = [];
    public productsPrincipal: Product[] = [];
    public showEdp: boolean;
    public summaryOrderModalLoading: boolean = false;
    public isMultiQuote: boolean = false;
    public isInternetGarantie: boolean = false;
    public isScoringModalOpened: boolean = false;
    public isOpenBankingEnabled = false;
    public isOpenBankingDone = false;
    public isUrlOpenBankingSend = false;
    public ibanFromOpenBanking: string;
    public checkOpenBankingStatusMessage: string | null;
    public editOpenBanking$: Observable<void>;
    public usingExistingIban = false;
    public isOpenBankingActive = false;

    private _fmsOptionGencode: string = '72155147';
    private _onScoringError$: Subject<boolean> = new Subject();
    private _onCheckCartError$: Subject<boolean> = new Subject();
    private _subscriptions: Subscription[] = [];
    private _editOpenBankingSubject: Subject<void> = new Subject<void>();

    constructor(
        private catalogService: CatalogService,
        private cartService: CartTeleSalesService,
        private faiEligibilityService: FaiEligibilityService,
        private addressService: AddressService,
        private customerService: CustomerService,
        private salesService: SalesService,
        private scoringService: ScoringService,
        private router: Router,
        private callRecordService: CallRecordService,
        private appointmentService: AppointmentService,
        private portabilityService: PortabilityService,
        private dialogService: DialogService,
        private deliveryService: DeliveryService,
        private mobileRecoveryService: MobileRecoveryService,
        private salesForceService: SalesForceService,
        private toastService: HotToastService,
        private salesUserService: SalesUserService,
        private fundingService: FundingService,
        private edpService: EdpService,
        private ref: ChangeDetectorRef,
        private orderService: OrderService,
        private simService: SimService,
        private qualificationService: QualificationService,
        private opportunityService: OpportunityService,
        private paymentMethodsService: PaymentMethodsService,
        private prismeLoggerService: PrismeLoggerService,
        private openBankingService: OpenBankingService,
        private configService: ConfigurationService,
        @Inject(DOCUMENT) private document: any
    ) {
        this.isLoadingIban = true;

        const updateFundingMethods$ = this.paymentMethodsService.updateFundingMethodsFromAPI(this.cartService.cartModel);
        const openBankingResult$ = this.openBankingService.getOpenBankingScoring(this.cartService.cartModel.cartId.toString(), false);
        this.editOpenBanking$ = this._editOpenBankingSubject.asObservable();
        this.isOpenBankingActive = this.configService.data_refconf.openBanking.active;

        this._subscriptions.push(
            forkJoin(
                {
                    updateFundingMethodsFromAPIResult: updateFundingMethods$,
                    openBankingResult: this.isOpenBankingActive ? openBankingResult$ : of(null),
                })
                .pipe(
                    map(({ openBankingResult }) => {
                        this._processOpenBankingResult(openBankingResult);
                    }),
                    switchMap(() => (this.customerService.customer?.idPerson && !this.isOpenBankingEnabled && this.isOpenBankingActive ?
                        this.openBankingService.bankCheck(this.customerService.customer.idPerson) : of(null)).pipe(
                        map((bankCheckResult) => this._processBankCheckResult(bankCheckResult))
                    )),
                    finalize(() => {
                        this._handlePaymentMethod();
                        this.isLoadingIban = false;
                    })
                ).subscribe()
        );

        this.showEdp = this.edpService.isEdpAllowed(this.cartService.cartModel);
        this.orderRecovery = this.salesForceService.prefilledInfo.order;

        if (this.orderRecovery) {
            this.fmsPaymentType = this._getFmsPaymentType(this.orderRecovery.cart.payment.initialFmsPaymentType);
        }

        this.cartHasMobileRecovery = !!this.mobileRecoveryService.currentMobileRecovery?.id;
        this.paymentForm.get('paymentType').valueChanges.subscribe(this._showWarningOnAppointment);
        this.billingAddress = this.addressService.currentBillingAddress.getInline();
        this.shippingAddress = this._getShippingAddress();
        this.customer = this.customerService.customer;
        this.appointment = this.appointmentService.currentAppointment;
        this.quote = this.cartService.cartModel.getQuote();
        this.cartModel = this.cartService.cartModel;
        this.products = this.cartService.cartModel
            .getAllProducts()
            .filter(p => p.gencode !== ProductRepository.OPTION_PRO_GENCODE);
        this.shippingAddressSim = this.addressService?.currentShippingAddress?.getInline()
            || this.addressService?.currentBillingAddress?.getInline();
        this.isMultiQuote = this.cartService.isQuoteMixed();
        this.productsPrincipal = this.cartService.cartModel
            .getAllProducts()
            .filter((p: Product) => ProductFactory.Is(p, Plan) && !!p.isPrincipal);
        this.plan = this.quote?.getProductByType<Plan>('Plan');
        this.isPro = this.plan?.isPro
            || this.cartService.cartModel.getQuote().getProductByType('Option')?.gencode === ProductRepository.OPTION_PRO_GENCODE;
        this.hasEquipment = !!this.quote?.getProductByType<Equipment>('Equipment');
        this._handleFai();
        this.showIban = this.quote?.totals.monthly.base > 0 || this.cartHasMobileRecovery;

        if (!this.showIban) {
            this.paymentForm.get('iban').disable();
        }

        if (this.cartService.cartModel.getAllProducts().some(p => (p as Option).category === OptionCategory.internetGarantie)) {
            this.isInternetGarantie = true;
            this.paymentForm.patchValue({ chooseFMS: false });
        }

        this.hasESim = this.simService.hasESimInCart(this.cartModel);
        this.hasESimOnly = this.simService.hasNoPhysicalProductInQuote(this.cartModel);
    }

    public ngOnDestroy(): void {
        this.ref.detach();
        this.scoringModalSubscriptionAction?.unsubscribe();
        this.appointmentModalSubscriptionAction?.unsubscribe();
        this._subscriptions.forEach(sub => sub?.unsubscribe());
    }

    public get monthlyTitle(): string {
        const options: Option[] = this.quote.getProductsByType('Option');
        const hasOptions: boolean = options.length > 0;
        let title: string = this.showIban ? 'Je paie mon ' : 'Mon ';
        switch (true) {
            case !!this.plan && !!hasOptions:
                title += `offre et ${options.length === 1 ? 'mon option' : 'mes options'}`;
                break;
            case !!this.plan && !hasOptions:
                title += 'offre';
                break;
            default:
                title = 'Je saisi mon Iban';
                break;
        }
        return title;
    }

    public get paymentTitle(): string {
        const equipments: Equipment[] = this.quote.getProductsByType<Equipment>('Equipment');
        const sim: Sim = this.quote.getProductByType<Sim>('Sim');
        const accessories: Accessory[] = this.quote.getProductsByType<Accessory>('Accessory');
        let title: string = 'Je paie';
        let hasEquipmentLabel: boolean = false;
        if (!accessories.length || sim || equipments.length > accessories.length) {
            title += ' mon équipement';
            hasEquipmentLabel = true;
        }
        if (accessories?.length) {
            title += `${hasEquipmentLabel ? ' et ' : ''} ${accessories?.length > 1 ? 'mes accessoires' : 'mon accessoire'}`;
        }
        return title;
    }

    public createOrder(): void {

        if (this.qualificationService.isDadCampaign()) {
            this.opportunityService.setIdTmpOpportunities(this.cartService.getIdOpportunitiesUsed());
        }

        this._createOrder(
            this.orderRecovery ?
                PAYMENT_METHODS.HYBRIDE : this.paymentForm.controls.paymentType.value,
            this._getDifferedFMSPayment()
        ).subscribe({next: () => {
            this.orderService.orderSucceeded(true);
        }, error: () => this.isLoading = false});
    }

    public havePromotion(product: Product): boolean {
        return !!product.promotions.automatic.concat(product.promotions.manual).filter(p => p.amount).length;
    }

    public getPromotionAmount(product: Product): number {
        return [...product.promotions.automatic, ...product.promotions.manual]
            .filter(p => !!p.duration)
            .map(p => p.getAmount(product.prices.base))[0];
    }

    public showFinalStep(): void {
        this.showPaymentStep = !this.showPaymentStep;
        this.document.defaultView.window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }

    public endCall(): void {
        this.salesUserService.closeCall();
    }

    public showSummaryOrderModal(): void {
        this.summaryOrderModalLoading = true;
        const paymentType = this.orderRecovery ? PAYMENT_METHODS.HYBRIDE : this.paymentForm.controls.paymentType.value;

        of(this.cartService.cartModel.availableFundingMethods
            .find((method) => this.cartService.cartModel.fundingMethod.type === method.type)).pipe(
            mergeMap((fundingMethodExists) => fundingMethodExists ?
                of(null) :
                this.cartService.setFundingMethod(this.cartService.cartModel.availableFundingMethods[0], false)),
            mergeMap(() => this.fundingService.postFundingMethod(this.cartService.cartModel, paymentType).pipe(
                catchError((err) => {
                    this.isLoading = false;
                    return of(err);
                }),
                mergeMap(() => this._checkScoring(paymentType, this._getDifferedFMSPayment()).pipe(
                    catchError((err) => {
                        this.isLoading = false;
                        return of(err);
                    })))))
        ).subscribe({
            next: () => {
                if (this.isScoringModalOpened) {
                    this.summaryOrderModalLoading = false;
                } else {
                    this._openSummaryOrderModal();
                }
            },
            error: (err) => {
                this.toastService.error(err?.error?.error + ' : ' + err?.error?.error_description);
                this.summaryOrderModalLoading = false;
            }
        });
    }

    public onOrderSummaryClick(): void {
        this.showPaymentStep = !this.showPaymentStep;
        this.prismeLoggerService.sendDataToPrisme('Click sur onglet Récapitulatif Commande', {
            puid: this.customer.idPerson
        });
    }

    public sendOpenBanking(payloadDetails: SubmitOpenBankingEventPayload): void {
        this.isLoadingOpenBanking = true;
        this.openBankingService.requestOpenBanking({
            demande: {
                communications: [
                    {
                        coordonneesContact: {
                            email: payloadDetails.email,
                            noTelephone: payloadDetails.phone
                        },
                        type: payloadDetails.type
                    }
                ],
                noPersonne: this.customerService.customer.idPerson,
                urlCallBack: ''
            }
        }).pipe(
            catchError(() => {
                this.toastService.error('Une erreur est survenue avec le partenaire open banking', { duration: TOAST_DURATION });
                return of(null);
            }),
            finalize(() => this.isLoadingOpenBanking = false)
        ).subscribe((result) => {
            this.isUrlOpenBankingSend = !!result;
        });
    }

    public cancelOpenBanking(): void {
        this.isLoadingOpenBanking = true;

        const customerId = this.customerService.customer?.idPerson;
        const cartId = this.cartService.cartModel?.cartId?.toString();

        if (!customerId || !cartId) {
            this.toastService.error('Une erreur est survenue avec le partenaire open banking', { duration: TOAST_DURATION });
            this.isLoadingOpenBanking = false;
            return;
        }

        this.openBankingService.requestOpenBanking({ demande: { noPersonne: this.customerService.customer.idPerson } }).pipe(
            switchMap(() => this.openBankingService.cancelOpenBanking(customerId, { statut: 'REFUSE' })),
            switchMap(() => this.openBankingService.getOpenBankingScoring(cartId, false)),
            catchError(() => {
                this.toastService.error('Une erreur est survenue avec le partenaire open banking', { duration: TOAST_DURATION });
                return of(null);
            }),
            finalize(() => this.isLoadingOpenBanking = false)
        ).subscribe((result: ControleRisqueVente | null) => {
            const message = result?.resultats?.find(r =>
                r.resultat === 'KO_SAUF_ACTION_REALISEE' && this._isBankCheckDone(r as ResultatControleUnitaire)
            )?.message || '';
            this.dialogService.open(CancelOpenBankingModalComponent, {
                data: { message }
            }).afterClosed$.subscribe((results: { [key: string]: boolean }) => {
                if (results?.endCall) {
                    this.endCall();
                }
            });
        });
    }

    public getOpenBankingIban(): void {
        this.isLoadingGetOpenBankingIban = true;
        this.openBankingService.bankCheck(this.customerService.customer.idPerson)
            .subscribe((result: RechercherVerificationBancaireOut | null) => {
                this._processBankCheckResult(result);
                if (!result) {
                    const httpError = HTTP_ERROR;
                    this.openBankingService.enableModifyIbanSubject.next(httpError);
                    this.openBankingService.statusMessageSubject.next(this.openBankingService.getOpenBankingStatusMessage(httpError));
                    this.toastService.error('Une erreur est survenue avec le partenaire open banking', { duration: TOAST_DURATION });
                }
                this.isLoadingGetOpenBankingIban = false;
            });
    }

    public editOpenBankingIban(): void {
        this.isOpenBankingDone = false;
        this.isUrlOpenBankingSend = false;
        this._updatePaymentForm();
        this.paymentForm.patchValue({
            iban: null
        });
        this.ibanFromOpenBanking = '';
        this._editOpenBankingSubject.next();
        this.isOpenBankingEnabled = true;
    }

    public get showOpenBankingBtnsCtrls(): boolean {
        return (this.isOpenBankingDone || this.isUrlOpenBankingSend) && !this.usingExistingIban && this.isOpenBankingEnabled;
    }

    @bind
    private _showWarningOnAppointment(paymentMethod: string): void {
        if (this.orderRecovery) {
            return;
        }
        if (this.paymentMethods.includes(PAYMENT_METHODS.CB) && paymentMethod === PAYMENT_METHODS.HYBRIDE && this.appointment?.id) {
            this.dialogService.dialogs.forEach(dialog => dialog.close());
            const dialogRef: DialogRef<any> = this.dialogService.open(AppointmentCancellationWarningModalComponent, { data: {} });
            this.appointmentModalSubscriptionAction = dialogRef.afterClosed$.subscribe((results: { [key: string]: boolean }) => {
                if (results?.keepHybrid === false) {
                    this.paymentForm.patchValue({
                        paymentType: PAYMENT_METHODS.CB
                    }, { emitEvent: false });
                }
            });
        }
    }

    private _isBankCheckDone(result: ResultatControleUnitaire): boolean {
        return result.controleEffectue?.id === IChecksCarriedOut.BANK_CHECK;
    }

    private _handleFai(): void {

        this.isFai = this.cartService.hasFaiInCart();
        const planFai = this.cartService.getPlanFaiInCart();
        this.isFaiOnly = ProductFactory.Is(planFai, Fai, true);
        this.portability = this.portabilityService.portability;

        if (this.isFai) {
            this.faiCart = this.faiEligibilityService.currentCart;
        }
        this.phoneNumber = this.faiCart?.selectedVoip ?? this.portability?.phoneNumber;

        if (this.isFaiOnly || this.cartService.isQuoteMixed()) {
            this.isCDL = this.faiCart.eligibility.isCDL;
            this.hasFMSOptionInQuote = !!this.cartService.cartModel.getAllProducts().find((p) => p.gencode === this._fmsOptionGencode);
            // eslint-disable-next-line max-len
            this.showFmsPaymentOptionWarning = planFai.data.upSellsProducts.some((gencode: string) => gencode === this._fmsOptionGencode);
            if (this.showFmsPaymentOptionWarning) {
                this.catalogService.getProductByGencode<Option>(this._fmsOptionGencode).subscribe((p: Option) => {
                    this.fmsOptionProduct = p;
                });
            }
            this._handleFms();
        }
    }

    private _handleFms(): void {
        this.paymentForm.get('chooseFMS').valueChanges.subscribe(val => {
            this.isLoading = true;
            const fms: Activation = this.cartService.cartModel.getQuote(
                this.cartService.getQuoteIndexByContext(QuoteContextFaiModel)
            )?.getProductByType('Activation');
            this.priceFms = fms.prices?.final;
            if (
                this.cartService.cartModel.getAllProducts().filter(p => (p as Option).category === OptionCategory.internetGarantie).length
            ) {
                fms.priceType =  PRICE_TYPES.Today;
            } else {
                fms.priceType = val ? PRICE_TYPES.Reported : PRICE_TYPES.Today;
            }
            this.cartService.refresh().then(() => this.isLoading = false);
        });

        this.isFMSEditable = !this.orderRecovery || (this.orderRecovery &&
            this.orderRecovery.cart.payment.politiqueTarifaire === POLITIQUE_TARIFAIRE.PRIX_INFERIEUR_OU_EGAL_COMPTANT);

        if (this.isFMSEditable) {
            this.paymentForm.enable();
            this.addressType = this.addressTypes.INSTALLATION;
        }

        if (this.orderRecovery) {
            this.paymentForm.get('iban').disable();
            this.paymentForm.patchValue({
                chooseFMS: this.orderRecovery.cart.payment.initialFmsPaymentType === PRICE_TYPES.Reported,
                paymentType: this.orderRecovery.cart.payment.method
            });
        }

    }

    private _createOrder(paymentType: string, differedFMSPayment: boolean, mustRecheckScoring = false): Observable<void> {
        this.isLoading = true;

        let obs: Observable<any> = of(null);
        if (mustRecheckScoring) {
            obs = (this.fundingService.postFundingMethod(this.cartService.cartModel, paymentType).pipe(
                catchError((err) => {
                    this.isLoading = false;
                    return of(err);
                }),
                mergeMap(() => this._checkScoring(paymentType, differedFMSPayment))));
        }

        return obs.pipe(
            mergeMap(() => this._notifyClientWithOrderSummary()),
            mergeMap(() => this.orderRecovery ? this._checkCartIntegrity() : of(null)),
            mergeMap(() => this.salesService.createOrder(paymentType, differedFMSPayment)),
            delayWhen(() => this.callRecordService.pause()),
            tap((data: PaymentResponseModel) => {
                if (data.redirectUrl) {
                    this.router.navigate([data.redirectUrl]);
                } else {
                    this.paymentData = data.formDataToPost;
                    const cartModelClone: CartModel = this.cartService.cartModel.clone();
                    this.cartService.clear();
                    this.cartService.saveToStorage();
                    this.deliveryService.clear();
                    CheckoutStorage.clear();
                    FaiStorage.clear();
                    this.quote = cartModelClone.getQuote();
                    this.cartModel.quotes = { ...cartModelClone.quotes };
                    this.cartModel.totals = { ...cartModelClone.totals };
                    this.ref.detectChanges();
                    // This is done to avoid CORS on external url data post
                    this.paymentDataFormElement.nativeElement.submit();
                }
            }),
            takeUntil(merge(this._onScoringError$, this._onCheckCartError$)),
            map(() => null)
        );
    }

    private _checkScoring(paymentType: string, differedFMSPayment: boolean): Observable<void> {
        return this.scoringService.getScoring(this.cartService.cartModel).pipe(
            catchError(() => of(null)),
            map((data: ScoringModel) => {
                if (Object.keys(data.detailed).length) {
                    this.isLoading = false;
                    this.scoringService.set(data);
                    this._openScoringModal(data, paymentType, differedFMSPayment);
                }
            }));
    }

    private _notifyClientWithOrderSummary(): Observable<boolean> {
        if (this.paymentForm.get('paymentType').value === PAYMENT_METHODS.CB) {
            return this.salesService.notifyClientWithOrderSummary(
                this.cartService.cartModel.cartId.toString(),
                this.customer.idPerson
            );
        }
        return of(false);
    }

    private _checkCartIntegrity(): Observable<void> {
        return this.salesService.checkCartIntegrity(this.cartService.cartModel.cartId)
            .pipe(
                catchError((err: HttpErrorResponse) => {
                    if (err?.status.toString().startsWith('40') || err?.status.toString().startsWith('50')) {
                        return of(null);
                    }
                    this._lockOrderOnCartIntegrityError();
                    return of(err);
                }),
                map(()=>null)
            );
    }

    private _lockOrderOnCartIntegrityError(errFeedback: string = ''): void {
        this.lockOrder = true;
        this.cartIntegrityErrorMsg = this.cartIntegrityErrorMsg.concat(errFeedback);
        this.toastService.error(this.cartIntegrityErrorMsg, { duration: TOAST_DURATION });
        this._onCheckCartError$.next(true);
        this.isLoading = false;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    private _openScoringModal(data: ScoringModel, paymentType: string, differedFMSPayment: boolean): void {
        this.isScoringModalOpened = true;
        const dialogRef: DialogRef<any> = this.scoringService.openScoringModal(data.detailed);
        this.scoringModalSubscriptionAction = dialogRef.afterClosed$.subscribe((results: { [key: string]: string }) => {
            if (results?.ACTION === 'FINISH_ORDER') {
                this.fundingService.postFundingMethod(this.cartService.cartModel, paymentType).pipe(
                    catchError((err) => {
                        this.isLoading = false;
                        return of(err);
                    })).subscribe( () =>  this._openSummaryOrderModal());
            }
            dialogRef.afterClosed$ = null;
            this.isScoringModalOpened = false;
        });
        this._onScoringError$.next(true);
    }

    private _openSummaryOrderModal(): void {
        // Used dom manipulation since the modal container api is not opened to all property except width and minHeight
        const paymentType: string = this.orderRecovery ? PAYMENT_METHODS.HYBRIDE : this.paymentForm.controls.paymentType.value;
        const container: HTMLElement = this.orderSummaryTpl.nativeElement;
        const constainerOffset: DOMRect = container.getBoundingClientRect();
        const refDialog = this.dialogService.open(SummaryOrderModalComponent,
            {
                data: {
                    cartId: this.cartService.cartModel.cartId,
                    paymentType,
                    isPaymentFmsDiffered: this.isFaiOnly ? this.paymentForm.get('chooseFMS')?.value : false
                },
                id: 'order-modal',
                width: constainerOffset.width + 30,
                container
            });
        refDialog.afterClosed$.subscribe((results: { [key: string]: string }) => {
            if (results?.ACTION === 'CREATE_ORDER') {
                this.createOrder();
            }
        });

        // 15 is the padding removed to center the block
        this.document.defaultView.window.document
            .querySelector('.ngneat-dialog-content')
            .style
            .setProperty('left', `${constainerOffset.left - 15}px`);

        this.summaryOrderModalLoading = false;
    }

    private _getShippingAddress(): string {
        let shippingAddress: string = '';
        if (this.deliveryService.selectedStore) {
            this.selectedStoreName = this.deliveryService.selectedStore.name;
            shippingAddress = new AddressModel({
                postalCode: this.deliveryService.selectedStore?.postalCode?.toString(),
                city: this.deliveryService.selectedStore?.city,
                streetNumber: this.deliveryService.selectedStore?.street_number?.toString(),
                street: this.deliveryService.selectedStore?.street,
                valid: false
            }).getInline();
        } else {
            shippingAddress = this.addressService?.currentShippingAddress?.getInline() ||
                this.addressService?.currentBillingAddress?.getInline();
        }

        return shippingAddress;
    }

    private _getDifferedFMSPayment(): boolean {
        let paiementDiffereFraisMiseService = false;
        if (this.isFaiOnly || this.cartService.isQuoteMixed()) {
            paiementDiffereFraisMiseService = this.hasFMSOptionInQuote ? true : this.paymentForm.get('chooseFMS').value;
        }
        return paiementDiffereFraisMiseService;
    }

    private _handlePaymentMethod(): void {
        this.cartService.getCartService().refresh(this.cartModel);
        this.paymentMethods = this.cartService.cartModel.fundingMethod?.paymentMethods?.
            filter(
                m => this.appointmentService.skipStep ?
                    !!this.PAYMENTS_METHODS_NAMES_BYPASS[m] :
                    !!this.PAYMENTS_METHODS_NAMES[m]
            ) || [];
        this.noPayment = !this.paymentMethods.length ||
            (this.paymentMethods.length === 1 && this.paymentMethods[0] === NO_PAYMENT);
        if (this.paymentMethods.length) {
            if (this.paymentForm.get('paymentType').invalid ) {
                this.paymentForm.get('paymentType').setValue(this.paymentMethods[0]);
            }
        } else {
            this.paymentForm.get('paymentType').disable();
        }
    }

    private _processOpenBankingResult(openBankingResult: ControleRisqueVente): void {
        this.isOpenBankingEnabled = openBankingResult?.resultats?.some(
            result => result.resultat === 'KO_SAUF_ACTION_REALISEE'
                && result.actions.some(action => action.action === 'VERIFICATION_BANCAIRE')
        ) ?? false;
        if (this.isOpenBankingEnabled) {
            this._updatePaymentForm();
        }
    }

    private _processBankCheckResult(bankCheckResult: RechercherVerificationBancaireOut): void {
        if (!bankCheckResult) {
            this.isOpenBankingDone = false;
            this.ibanFromOpenBanking = '';
            this.openBankingService.statusMessageSubject.next(null);
            this.paymentForm.patchValue({ iban: null });
            return;
        }

        const { statut, informationsSupplementaires } = bankCheckResult;
        this.isOpenBankingDone = statut === 'REALISE';
        if (this.isOpenBankingDone) {
            this.ibanFromOpenBanking = informationsSupplementaires?.iban || '';
            this.openBankingService.ibanFromOpenBankingSubject.next(this.ibanFromOpenBanking);
            this.openBankingService.statusMessageSubject.next(null);
            this.paymentForm.patchValue({ iban: true });
        } else {
            this.ibanFromOpenBanking = '';
            this.openBankingService.enableModifyIbanSubject.next(statut);
            this.openBankingService.statusMessageSubject.next(this.openBankingService.getOpenBankingStatusMessage(statut));
            this.paymentForm.patchValue({ iban: null });
        }
    }

    private _getFmsPaymentType(initialFmsPaymentType: PRICE_TYPES): string {
        return initialFmsPaymentType === PRICE_TYPES.Reported ? '1ère facture' : 'A payer aujourd\'hui';
    }

    private _updatePaymentForm(): void {
        this.paymentForm.patchValue({
            phone: this.customer?.phone,
            email: this.customer?.email,
            useSms: true
        });
    }
}
