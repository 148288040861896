import { Pipe, PipeTransform } from '@angular/core';
import { PromotionModel, PromotionOdrModel } from '@bytel/bytel-sales';

@Pipe({
    name: 'odrPromotions'
})
export class OdrPromotionsPipe implements PipeTransform {
    public transform(items: PromotionModel[],reverse: boolean = false): PromotionOdrModel[] {
        if (!items) {
            return [];
        }
        if (!reverse){
            return items.filter((promo): promo is PromotionOdrModel => promo instanceof PromotionOdrModel);
        } else {
            return items.filter((promo): promo is PromotionOdrModel  => !(promo instanceof PromotionOdrModel));
        }
    }
}
