<ng-container #modal></ng-container>
<tlv-stepper></tlv-stepper>
<div id="main-content" class="main-content wrapper">
    <div class="columns is-multiline is-tablet page-container">
        <div class="column is-8-tablet is-9-widescreen">
            <router-outlet></router-outlet>
        </div>
        <div class="column is-4-tablet is-3-widescreen align-ticket" id="checkout-ticket">
            <tlv-ticket></tlv-ticket>
        </div>
    </div>
</div>
