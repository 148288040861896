import { QuoteContextAcquisitionModel, QuoteContextFaiModel } from '@bytel/bytel-sales';
import { CartFaiStep } from '@components/checkout/step/cart/cart-fai.step';
import { CartSimoStep } from '@components/checkout/step/cart/cart-simo.step';
import { CartComponent } from '@components/checkout/step/cart/cart.component';
import { CustomerInfoComponent } from '@components/checkout/step/customer-info/customer-info.component';
import { SirenComponent } from '@components/checkout/step/siren/siren.component';
import { DeliveryComponent } from '@components/checkout/step/delivery/delivery.component';
import { AddressRepository } from '@repositories/address.repository';
import { CrossSellComponent } from '@components/checkout/step/cross-sell';
import { PaymentComponent } from '@components/checkout/step/payment/payment.component';
import { AddressService } from '@services/checkout/address.service';
import { FaiEligibilityService } from '@services/fai/fai-eligibility.service';
import { AppointmentComponent } from './step/appointment/appointment.component';
import { DeliveryStep } from './step/delivery/delivery.step';
import { CustomerInfoStep } from './step/customer-info/customer-info.step';
import { PortabilityInfoStep } from './step/portability-info/portability-info.step';
import { SirenStep } from './step/siren/siren.step';
import { CrossSellStep } from './step/cross-sell/cross-sell.step';
import { PaymentStep } from './step/payment/payment.step';
import { AppointmentStep } from './step/appointment/appointment.step';
import {
    PortabilityContainerComponent
} from '@components/checkout/step/portability-container/portability-container.component';

export const CheckoutRoute = [
    {
        path: '',
        component: CartComponent
    },
    {
        path: 'fai',
        canActivate: [CartFaiStep],
        component: CartComponent,
        data: {
            context : QuoteContextFaiModel
        }
    },
    {
        path: 'mobile',
        canActivate: [CartSimoStep],
        component: CartComponent,
        data: {
            context : QuoteContextAcquisitionModel
        }
    },
    {
        path: 'options-accessoires',
        canActivate: [CrossSellStep],
        component: CrossSellComponent
    },
    {
        path: 'siren',
        canActivate: [SirenStep],
        component: SirenComponent
    },
    {
        path: 'portability',
        canActivate: [PortabilityInfoStep],
        component: PortabilityContainerComponent,
        resolve: { FaiEligibilityService }
    },
    {
        path: 'info-client',
        canActivate: [CustomerInfoStep],
        component: CustomerInfoComponent
    },
    {
        path: 'livraisons',
        component: DeliveryComponent,
        canActivate: [DeliveryStep],
        resolve: { AddressRepository, AddressService },
    },
    {
        path: 'paiement',
        canActivate: [PaymentStep],
        component: PaymentComponent
    },
    {
        path: 'rendez-vous',
        canActivate: [AppointmentStep],
        component: AppointmentComponent
    }
];
