import { Accessory, EquipmentData} from '@bytel/bytel-sales';

export class DisplayAccessoryModel extends Accessory {

    public available: boolean;
    public position: number;
    public firstPage: boolean;
    public essential: boolean;

    constructor(product: EquipmentData, available: boolean, position: number, essential = false) {
        super(product);
        this.available = available;
        this.position = position;
        this.essential = essential;
    }

    public clone(): this{
        // @ts-ignore
        return new this.constructor(this.data,this.available,this.position);
    }

}
