<div class="modal is-panel is-active" data-cy="notifications-modal">
    
    <div class="modal-content">
    
        <button aria-label="close modal" class="modal-close is-large" (click)="closeNotifModal()" data-cy="notifications-close-modal"></button>
        <div class="columns">
            <div class="title is-size-3 is-marginless">Notifications</div>
        </div>

        <div class="column"></div>

        <div class="columns is-vcentered is-fullwidth">
            <span class="has-text-weight-semibold">Trier par</span>
            <div class="column">
                <div class="field">
                    <div class="control">
                        <div class="select">
                            <select aria-label="Type" [formControl]="sortByNotificationsTypes" data-cy="notifications-select">
                                <option [ngValue]="'ALL'" selected="selected">Tout type</option>
                                <ng-container *ngFor="let notificationtype of notificationTypesLabelMap | keyvalue">
                                    <option [ngValue]="notificationtype.key">{{notificationtype.value}}</option>
                                </ng-container>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="column"></div>

        <div class="rows is-fullwidth">
            <div *ngFor="let notification of notificationsList" class="box" data-cy="notifications-items">
                <tlv-notifications-item [notification]="notification" (openNotifImageModal)="toggleNotificationsImages($event)" (onNotificationRead)="markAsRead($event)"></tlv-notifications-item>
            </div>
        </div>
        
    </div>

<div class="modal-notifications-images" *ngIf="displayNotificationsImages" data-cy="notifications-modal-images">
    <tlv-notifications-images (closeModal)="toggleNotificationsImages($event)" [imageInfos]="displayNotificationsImages"></tlv-notifications-images>
</div>