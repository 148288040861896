import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { ConfigurationService, Oauth2Service } from '@common-modules';
import { catchError, delayWhen, map, mergeMap, tap } from 'rxjs/operators';
import { SalesForceRepository } from '@repositories/sales-force.repository';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard {
    constructor(
        private oauth2Service: Oauth2Service,
        private router: Router,
        private configurationService: ConfigurationService,
        private salesForceRepository: SalesForceRepository,
    ) {}

    public canActivate(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        route: ActivatedRouteSnapshot, state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree{
        return this.canActivateChild(route, state);
    }

    public canActivateChild(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        route: ActivatedRouteSnapshot, state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.salesForceRepository.loadUrlParam().pipe(
            mergeMap(() => this.oauth2Service.resolve(route).pipe(
                tap((result)=>{
                    if (!result){
                        this.oauth2Service.login();
                    }
                    throwError(()=>'You are not logged');
                }),
                delayWhen(()=>this.configurationService.resolve()),
                map((result) => {
                    if (!this.oauth2Service.jwtPayload) {
                        throwError(() => 'You are not logged');
                        return false;
                    }
                    if ((this.oauth2Service.jwtPayload.roles as unknown as string[]).some(
                        (role) => Object.keys(this.configurationService.data_refconf?.user_roles).includes(role)
                    )) {
                        return result;
                    } else {
                        return this.router.parseUrl('/notauthorized');
                    }
                }),
                catchError((err)=> {
                    console.error(err);
                    if (err === 'IMPOSSIBLE_CONNEXION_2') {
                        return of(this.oauth2Service.logout()).pipe(
                            mergeMap(()=>this.oauth2Service.autoLogin(true)),
                            map(()=>false)
                        );
                    }
                    return of(false);
                })
            ))
        );
    }

}
