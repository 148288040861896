import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import { WetoComponent } from '../weto/weto.component';
import { ACTIONS } from '@directives/prisme-logger.directive';
import {Observable, ReplaySubject, Subject} from 'rxjs';
import {PortabilityWetoInterface} from '@interfaces/weto.interface';
import {PortabilityModel} from '@models/cart/portability.model';

@Component({
    selector: 'tlv-weto-portability',
    templateUrl: './weto-portability.component.html',
    styleUrls: ['./weto-portability.component.scss']
})
export class WetoPortabilityComponent extends WetoComponent {
    @Input() portability: PortabilityModel;
    @ViewChild('widgetWetoPortability') widget?: { nativeElement: any };
    public endToLoad = new Subject<boolean>();
    @Output() onError = new EventEmitter<boolean>();
    public formValid = new Subject<boolean>();
    public LOG_ACTIONS: typeof ACTIONS = ACTIONS;
    public eligeId: string = '';
    private submit$ = new ReplaySubject<PortabilityWetoInterface>(1);

    public render(): void {
        this.eligeId = this.eligibilityService.currentCart.id;

        this.widget?.nativeElement.render(
            {
                hiddenController: true,
                typeVente: 'VA',
                api: {
                    baseUrl: this.configService.baseConfig.resourceUrl,
                    token: this.oauth2Service.access_token ?? null,
                    xVersion: 4,
                    xSource: 'portailvente_tlv'
                }
            },
            this.eligeId,
            this.portability?.rioCode ? this.portability?.phoneNumber : null,
            this.portability?.rioCode ?? null
        );

        this._ngZone.runOutsideAngular(() => {
            this.widget?.nativeElement.onEvent(this.onEvent.bind(this));
        });
    }

    public submit(): Observable<PortabilityWetoInterface> {
        this.submit$ = new ReplaySubject<PortabilityWetoInterface>(1);
        this.widget?.nativeElement?.next();
        return this.submit$;
    }

    public onEvent(e: any): void {

        this._ngZone.run(() => {

            if (e.type === 'STEP_STATUS') {
                if (e.event.status === 'READY') {
                    this.endToLoad.next(true);
                }
                if (e.event.status === 'VALID') {
                    this.formValid.next(true);
                }
                if (e.event.status === 'INVALID') {
                    this.formValid.next(false);
                }
            }

            if (e.event?.type === 'TECHNICAL') {
                this.onError.emit(true);
                this.toastService.error(
                    'Erreur: Un problème technique sur le controle de portabilité est survenu, merci de réessayer',
                    {duration: 3000}
                );
            }

            if (e.event?.type === 'FUNCTIONAL') {
                this.onError.emit(true);
                this.toastService.error('Erreur: ' + e.event?.description, {duration: 3000});
            }

            if (e.type === 'END') {
                this.submit$.next(e.event.result);
                this.submit$.complete();
            }

        });
    }
}
