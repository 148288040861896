import { Injectable } from '@angular/core';
import { Oauth2ResourcesService } from '@common-modules';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CallRecordRepository{

    constructor(
        private oauth2Resource: Oauth2ResourcesService
    ) {}

    public pause(): Observable<void> {
        return this.oauth2Resource.enregistrement()
            .setParams({action:'pause'}).get();
    }

    public start(): Observable<void> {
        return this.oauth2Resource.enregistrement()
            .setParams({action:'start'}).get();
    }

    public resume(): Observable<void> {
        return this.oauth2Resource.enregistrement()
            .setParams({action:'resume'}).get();
    }

}
