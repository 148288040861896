export enum DISPONIBILITY_SLOTS {
    morning = 'MATIN',
    afternoon = 'APRES_MIDI',
    evening = 'SOIR',
    weekend = 'WEEK_END'
}

export enum CUSTOMER_PRESENCE {
    yes = 'OUI',
    no = 'NON'
}

export const COLORS_APPOINTMENT = {
    red: {
        primary: '#ad2121',
        secondary: '#FAE3E3',
    },
    blue: {
        primary: '#1e90ff',
        secondary: '#D1E8FF',
    },
    yellow: {
        primary: '#e3bc08',
        secondary: '#FDF1BA',
    },
};

export interface IAppointmentForm {
    contactNumber: string;
    digicode: string;
    interphone: string;
    comment: string;
    appointment: any;
    disponibilitySlot: string;
}
