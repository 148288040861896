<div class="modal-representant-legal is-tri" data-cy="siren-invalid">
    <div class="columns is-multiline is-marginless is-centered has-text-centered">
        <div class="column is-narrow">
            <span class="icon is-large">
                <i class="tri-frown"></i>
            </span>
            <p class="has-text-weight-semibold is-size-5">Aïe !</p>
            <p>{{ref.data?.socialReason}}</p>
        </div>

        <div class="column is-12">
            <div>
                <p>L'identité du représentant légal de votre entreprise n'est pas renseigné.</p>
                <p>Vous ne pouvez malheureusement pas continuer votre commande en ligne.</p>
                <p>Vous avez la possibilité de passer votre commande dans l'une de nos boutiques Bouygues Telecom avec les documents suivants :</p>
            </div>
        </div>

        <div class="column is-12">
            <div class="has-body is-small is-warning notification">
                <div class="icon">
                  <i class="tri-exclamation-circle"></i>
                </div>
                <div class="body has-text-left">
                    <p class="title is-marginless" *ngFor="let document of documentsList">
                        <span class="icon-and-text">
                            <span class="icon is-small">
                                <i class="tri-check"></i>
                            </span>
                            <span>{{document}}</span>
                        </span>
                    </p>
                </div>
            </div>
        </div>

        <div class="column is-12">
            <div class="buttons">
                <a
                target="_blank"
                rel="noopener noreferrer"
                class="button is-primary"
                href="https://boutiques.bouyguestelecom.fr/"
                data-tc-clic="Trouver_une_boutique"
                data-tc-action="Clic_redirection"
                data-tc-categorie="Action_deblocage">Trouver une boutique</a>
            </div>
        </div>

    </div>
</div>
