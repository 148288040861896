import { Component } from '@angular/core';
import { SalesUserService } from '@services/sales-user.service';

@Component({
    selector: 'tlv-order-recovery-error',
    templateUrl: './order-recovery-error.component.html',
    styleUrls: ['./order-recovery-error.component.scss']
})
export class OrderRecoveryErrorComponent {

    constructor(
        public salesUserService: SalesUserService
    ){}

    public endCall(): void {
        this.salesUserService.closeCall();
    }
}


