import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
    enableProdMode();
}
const xobj = new XMLHttpRequest();
const handleExtraOptions = (config: any, window): Record<string, unknown> => {
    if (window.hasOwnProperty('Cypress')) {
        return {...config, ...window.CypressExtraOptions};
    }
    return config;
};

xobj.overrideMimeType('application/json');
if (window.hasOwnProperty('Cypress')) {
    xobj.open('GET', '/config/default.mock.json', true);
} else {
    xobj.open('GET', '/config/default.json', true);
} // Replace 'my_data' with the path to your file
xobj.onreadystatechange = (): void => {
    if (xobj.readyState === 4 && xobj.status === 200) {
        window.ConfigInitial = handleExtraOptions(JSON.parse(xobj.responseText), window);
        platformBrowserDynamic([{provide: 'appConfig', useValue: window.ConfigInitial}]).bootstrapModule(AppModule)
            .catch(err => console.error(err));
    }
};
xobj.send(null);

