

export enum STEP_STATUS {
    INPROGRESS,
    VALIDATED,
    WAITING
}

export interface IStep {
    status: STEP_STATUS;
    name: string;
    label: string;
    route: string[];
    icon: string;
    canBeValidate: boolean;
    canDisplay: () => boolean;
    canAccess: () => boolean;
    validateStep?: () => boolean;
}
