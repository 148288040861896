<div class="columns is-centered is-multiline is-marginless has-text-centered" data-cy="appointment-cancellation-modal">
    <div class="column is-12">
        <span class="icon is-huge is-info">
            <i class="tri-exclamation-circle"></i>
        </span>
        <p class="has-text-weight-semibold is-size-2">Mode de paiement</p>
    </div>
    <div class="column is-9">
        <p class="is-size-5 has-text-weight-semibold">Si vous choisissez le mode de paiement hybride, Le RDV avec l'installateur va être annulé, le client devra reprendre RDV lors de la finalisation de sa commande</p>
    </div>
    <div class="column is-9">
        <div class="columns is-marginless is-multiline">
            <div class="column is-12">
                <button class="is-primary button is-fullwidth" data-cy="appointment-cancellation-hybrid-yes" (click)="ref.close({keepHybrid: true})">Hybride - le client finalise sa commande</button>
            </div>
            <div class="column is-12">
                <button class="is-secondary button is-fullwidth" data-cy="appointment-cancellation-hybrid-no" (click)="ref.close({keepHybrid: false})">REC - je finalise la commande</button>
            </div>
        </div>
        <div class="is-divider has-background-white"></div>
    </div>
</div>
