import { Component } from '@angular/core';
import { CustomerService } from '@services/customer/customer.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { CatalogService } from '@services/catalog.service';

@Component({
    selector: 'tlv-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class MainComponent {

    public searchClosed: boolean = true;

    constructor(private readonly customerService: CustomerService,
                private readonly router: Router,
                private readonly catalogueService: CatalogService
    ){
        this.catalogueService.resolve().subscribe();
        this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd)=>{
            const url = event.url.split('#')[0].split('?')[0];
            const urlAfterRedirects = event?.urlAfterRedirects?.split('#')[0]?.split('?')[0];

            const isSearchRequired: boolean = [
                '/qualification',
                '/fai',
                '/payment-failure',
                '/order-success',
                '/settings',
            ].every(link => !url.startsWith(link) && !urlAfterRedirects?.startsWith(link));

            if (isSearchRequired && url !== '/' && url !== '/A360') {
                this.searchClosed = !!this.customerService.customer;
            } else {
                this.searchClosed = true;
            }
        });
        this.customerService.searchCustomerIhm.subscribe((status)=>{
            this.searchClosed = !status;
        });
    }

}


