import { Component } from '@angular/core';
import { ScoringModel } from '@models/scoring.model';
import { ScoringService } from '@services/checkout/scoring.service';

@Component({
    selector: 'tlv-partial-scoring',
    templateUrl: './partial-scoring.component.html',
    styleUrl: './partial-scoring.component.scss'
})
export class PartialScoringComponent {

    public scoring: ScoringModel;

    constructor(
        private scoringService: ScoringService
    ) {
        this.scoring = this.scoringService.scoring;
        this.scoringService.scoring$.subscribe((data: ScoringModel) => {
            this.scoring = data;
        });
    }
}
