import { Injectable } from '@angular/core';
import { ConfigurationService, Oauth2ResourcesService } from '@common-modules';
import { Fai, QuoteContextFaiModel, QuoteModel } from '@bytel/bytel-sales';
import {
    IFaiAppointmentAddressParams,
    IFaiAppointmentCustomer,
    IFaiAppointmentParams,
    IFaiTechnicalcharacteristic
} from '@interfaces/api/appointment/appointmentInput';
import { IDeliverySlots, IDeliverySlotsResponse, ISlotResponse } from '@interfaces/api/appointment/appointmentResponse';
import { IOfferResponse } from '@interfaces/api/appointment/offerResponse';
import { CustomerProGpDetailsModel } from '@interfaces/customer.interface';
import { AddressModel } from '@models/cart/address.model';
import { AppointmentModel } from '@models/cart/appointment.model';
import { DeliveryAppointmentModel } from '@models/cart/delivery-appointment.model';
import { MainCartModel } from '@models/cart/main-cart.model';
import { SlotModel } from '@models/cart/slot.model';
import { CustomerProDetailsModel } from '@models/customer/customer-pro-details.model';
import { FaiCartModel } from '@models/fai/fai-cart.model';
import { FaiEligibilityModel } from '@models/fai/fai-eligibility.model';
import { FaiVerticalStructureModel } from '@models/fai/Fai-vertical-structure.model';
import { classToPlain, plainToClass } from 'class-transformer';
import { isValid, parseISO, parse, format } from 'date-fns';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import bind from '../helper/decorators/bind';
import { CheckoutStorage } from './storages/checkout.storage';


@Injectable({
    providedIn: 'root'
})
export class AppointmentRepository {

    constructor(
        private oauth2Resource: Oauth2ResourcesService,
        private configService: ConfigurationService
    ) {}

    public getOffers(gencode?: string): Observable<IOfferResponse>{
        return this.oauth2Resource
            .referentiel()
            .offres(gencode)
            .get();
    }

    public getAvailableAppointments(
        cartModel: MainCartModel,
        faiCart: FaiCartModel,
        customer: CustomerProGpDetailsModel,
        appointment: AppointmentModel,
        idCorbis?: string
    ): Observable<SlotModel[]>{
        return this.oauth2Resource
            .creneauxDisponibles()
            .setParams({idPanier:idCorbis})
            .post(this.getGenericParamsSlots(cartModel, faiCart, customer, appointment)).pipe(
                map(this._mapToSlot)
            );
    }

    public getDeliveryAppointments(address: AddressModel): Observable<DeliveryAppointmentModel[]> {
        return this.oauth2Resource
            .useSalesApi()
            .creneauxLivraison()
            .recherche()
            .post({
                adresseLivraison: {
                    codePostal: address.postalCode,
                    complementAdresse: address.complement,
                    numero: address.streetNumber,
                    rue: address.street,
                    ville: address.city
                },
                reseauLivraison: ['CHRONOPOST']})
            .pipe(map(this._mapDeliveryToSlot));
    }

    public save(appointmentInfo: AppointmentModel): void{
        CheckoutStorage.setItem(CheckoutStorage.KEYS.APPOINTMENT,classToPlain(appointmentInfo));
    }

    public delete(): void{
        CheckoutStorage.removeItem(CheckoutStorage.KEYS.APPOINTMENT);
    }

    public getCurrentAppointment(): AppointmentModel{
        const data = CheckoutStorage.getItem(CheckoutStorage.KEYS.APPOINTMENT);
        if (data){
            return plainToClass(AppointmentModel,data);
        }
        return new AppointmentModel({});
    }


    public getGenericParamsSlots(
        cartModel: MainCartModel,
        faiCart: FaiCartModel,
        customer: CustomerProGpDetailsModel,
        appointment: AppointmentModel,
        paramForSales: boolean = false
    ): IFaiAppointmentParams {
        const quoteIndex: number = cartModel.quotes.findIndex((q: QuoteModel) => q.context instanceof QuoteContextFaiModel);
        const plan: Fai = cartModel.getQuote(quoteIndex).getPrincipalProduct<Fai>('Fai');

        const params: IFaiAppointmentParams = {
            techno: this._getTechno(plan),
            natureProduit: plan.technology,
            idAcces: this._getIdAcces(plan, faiCart),
            client: this._getClientParams(customer, faiCart),
            caracteristiquesTechniques: this._getTechnicalcharacteristic(faiCart.eligibility),
            infoVIP: customer.isVip,
            offrePremium: appointment.isPremuim,
            ...(this.configService?.data_refconf?.fai?.widget?.active ? this._addAppointmentParams(faiCart) : {})
        };

        this._setPtoStatusIfNeeded(params, plan, faiCart);
        this._setDateCommandeIfNeeded(params, faiCart);

        const collect = this._getCollect(plan, faiCart);
        if (collect) {
            params.collecte = collect;
        }

        if (paramForSales) {
            params.typeClient = cartModel.getCustomerCategory();
        } else {
            params.clientType = cartModel.getCustomerCategory();
        }

        return params;
    }

    private _getClientParams(customer: CustomerProGpDetailsModel, faiCart: FaiCartModel): IFaiAppointmentCustomer {
        return {
            nom: customer.lastname,
            prenom: customer.lastname,
            civilite: customer.gender.toString(),
            raisonSociale: customer instanceof CustomerProDetailsModel ? customer.company.socialReason : null,
            adresse: this.configService?.data_refconf?.fai?.widget?.active ? this._getAddressFromElig(faiCart) : {
                codeRivoli: faiCart.eligibility.installationAddress.street.code,
                codeInsee: faiCart.eligibility.installationAddress.city.code,
                codePostal: faiCart.eligibility.installationAddress.postal,
                numero: faiCart.eligibility.installationAddress.street_number,
                nomVoie: faiCart.eligibility.installationAddress.street.libelle,
                ville: faiCart.eligibility.installationAddress.city.libelle
            }
        };
    }

    private _setPtoStatusIfNeeded(params: IFaiAppointmentParams, plan: Fai, faiCart: FaiCartModel): void {
        if (this._getTechno(plan) !== Fai.TECHNOLOGIES.XDSL) {
            params.statutPTO = this._getPtoStatus(faiCart.selectedVerticalStructure);
        }
    }

    private _setDateCommandeIfNeeded(params: IFaiAppointmentParams, faiCart: FaiCartModel): void {
        const dateCommercialOpeningDate = faiCart.selectedVerticalStructure?.dateOuvertureCommerciale ?
            parseISO(faiCart.selectedVerticalStructure?.dateOuvertureCommerciale) : undefined;
        if (dateCommercialOpeningDate && isValid(dateCommercialOpeningDate)) {
            params.dateCommande = format(parseISO(dateCommercialOpeningDate.toJSON()), 'dd/MM/yyyy');
        }
    }

    private _getCollect(plan: Fai, faiCart: FaiCartModel): string {
        const isWebshop: boolean = !this.configService?.data_refconf?.fai?.widget?.active;
        switch (plan.technology) {
            case Fai.TECHNOLOGIES.ADSL:
                // eslint-disable-next-line max-len
                return isWebshop ? faiCart.eligibility.webshopData.resultat.adsl.reseau : faiCart?.eligibility?.rawApiData?.adsl?.collectionNetwork;
            case Fai.TECHNOLOGIES.VDSL:
                // eslint-disable-next-line max-len
                return isWebshop ? faiCart.eligibility.webshopData.resultat.vdsl.reseau : faiCart?.eligibility?.rawApiData?.vdsl?.collectionNetwork;
            case Fai.TECHNOLOGIES.FTTH:
                return isWebshop ? null : faiCart?.eligibility?.rawApiData?.adsl?.collectionNetwork;
            default:
                return null;
        }
    }

    private _addAppointmentParams(faiCart: FaiCartModel): {
        groupeCategorieRaccoLogement: string;
        typeEmplacementPM: string;
    } {
        return {
            groupeCategorieRaccoLogement: faiCart?.eligibility?.rawApiData?.ftth?.groupeCategorieRaccoLogement,
            typeEmplacementPM: faiCart?.eligibility?.rawApiData?.ftth?.pmLocationType
        };
    }

    private _getAddressFromElig(faiCart: FaiCartModel): IFaiAppointmentAddressParams {
        return {
            batiment: faiCart.selectedLogement?.batiment ?? faiCart?.selectedVerticalStructure?.batiment,
            codeInsee: faiCart.searchAddress?.city?.code,
            codePostal: faiCart.searchAddress?.postal,
            codeRivoli: faiCart.searchAddress?.street?.code,
            escalier: faiCart.selectedLogement?.escalier ?? faiCart?.selectedVerticalStructure?.escalier,
            etage: faiCart.selectedLogement?.etage ?? faiCart?.selectedVerticalStructure?.etage,
            extension: faiCart.searchAddress?.complementLibelle,
            logoFT: faiCart.selectedLogement?.logo,
            nomVoie: faiCart.searchAddress?.street?.libelle,
            numero: faiCart.searchAddress?.street_number,
            pays: 'France', // No info as of now
            porte: faiCart?.selectedLogement?.porte,
            residence: faiCart.selectedLogement?.residence,
            typeVoie: null,
            ville: faiCart?.searchAddress?.city?.libelle,
        };
    }

    @bind
    private _mapToSlot(data: ISlotResponse): SlotModel[] {
        return data.items.map((appointment) => new SlotModel({
            id: appointment.idCreneau,
            start: parseISO(appointment.debutCreneau),
            end: parseISO(appointment.finCreneau),
            providerId: appointment.idPrestataire,
            calendarType: data.typeCalendrier,
            EligibilitySpecificSlots: data.eligibiliteCreneauxSpecifiques || false
        }));
    }

    @bind
    private _mapDeliveryToSlot(data: IDeliverySlotsResponse): DeliveryAppointmentModel[] {
        return data.creneauxDisponibles.map((deliverySlots: IDeliverySlots) => new DeliveryAppointmentModel({
            id: deliverySlots.detailsCreneau.find(keyValue => keyValue.cle === 'deliverySlotCode')?.valeur,
            start: parse(`${deliverySlots.jourCreneau} ${deliverySlots.heureDebut}`, 'dd/MM/yyyy HH:mm:ss.SSS', new Date()),
            end: parse(`${deliverySlots.jourCreneau} ${deliverySlots.heureFin}`, 'dd/MM/yyyy HH:mm:ss.SSS', new Date()),
            dataToUpdate: deliverySlots
        })).sort((a: DeliveryAppointmentModel, b: DeliveryAppointmentModel) => Math.sign(a.start.getTime() - b.start.getTime()));
    }

    private _getIdAcces(plan: Fai, faiCart: FaiCartModel): string {
        const isWebshop: boolean = !this.configService?.data_refconf?.fai?.widget?.active;
        switch (this._getTechno(plan)) {
            case Fai.TECHNOLOGIES.THD:
                return faiCart.selectedVerticalStructure.ptoSelected;
            case Fai.TECHNOLOGIES.XDSL:
                return isWebshop ? faiCart.eligibility.webshopData.resultat.adresseInstallation.ndi :
                    faiCart?.eligibility?.rawApiData?.housing?.NDRouting;
            default:
                return null;
        }
    }

    private _getTechnicalcharacteristic(eligibility: FaiEligibilityModel): IFaiTechnicalcharacteristic[] {
        const dataRequestBody: IFaiTechnicalcharacteristic[] = [];
        const ftthObject = eligibility?.webshopData?.resultat?.ftth;
        if (ftthObject) {
            const eligKeyToBody: string[] = [
                'groupeCategorieRaccoLogement',
                'type_logement',
                'positionnement_PB',
                'typeEmplacementPM',
                'nbPaires'
            ];
            for (const key of Object.keys(ftthObject)) {
                if (eligKeyToBody.includes(key)) {
                    dataRequestBody.push({type: `${key.charAt(0).toUpperCase()}${key.slice(1)}`, valeur: ftthObject[key]});
                }
            }
        }
        return dataRequestBody;
    }

    private _getPtoStatus(verticalStructure: FaiVerticalStructureModel): string {
        if (verticalStructure?.presencePto && verticalStructure?.ptoSelected){
            return 'PTO_AVEC_REFERENCE';
        }
        if (verticalStructure?.presencePto){
            return 'PTO_SANS_REFERENCE';
        }
        return 'PTO_INEXISTANTE';
    }

    private _getTechno(plan: Fai): string {
        const technology = plan.technology;
        switch (technology) {
            case Fai.TECHNOLOGIES.FTTLA:
                return Fai.TECHNOLOGIES.THD;
            case Fai.TECHNOLOGIES.ADSL:
            case Fai.TECHNOLOGIES.VDSL:
                return Fai.TECHNOLOGIES.XDSL;
            default:
                return technology;
        }
    }


}
