import { Injectable } from '@angular/core';
import { ICmsBlock } from '@interfaces/cms.interface';
import { CmsRepository } from '@repositories/cms.repository';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class CmsService {

    constructor(private cmsRepository: CmsRepository) {}

    public getMessageFaiPremium(): Observable<string> {
        return this.cmsRepository
            .getBlock('tlv_message_pour_FAI_premium')
            .pipe(
                map((cmsBlock: ICmsBlock) => cmsBlock?.html)
            );
    }
}
