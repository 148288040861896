import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, Observable, of } from 'rxjs';
import { SalesRiskControlRepositoryRepository } from '@repositories/sales-risk-control.repository';
import { ControleRisqueVente } from '@bytel/pt-ihm-api-egide-controle-risque-vente-demander-autorisation-vente';
import { RechercherVerificationBancaireOut }
    from '@bytel/pt-ihm-api-r-banque-verification-bancaire-rechercher-verification-bancaire';
import { DemanderVerificationBancaireIn, DemanderVerificationBancaireOut }
    from '@bytel/pt-ihm-api-r-banque-verification-bancaire-demander-verification-bancaire';
import { ModifierStatutVerificationBancaireIn }
    from '@bytel/pt-ihm-api-r-banque-verification-bancaire-modifier-statut-verification-bancaire/dist/models';

const openBankingStatus: { [key: string]: string } = {
    CREE: 'Le parcours d\'Open Banking n\'a pas été commencé. Récupération de l\'IBAN impossible',
    EN_COURS: 'Le parcours d\'Open Banking n\'a pas été commencé. Récupération de l\'IBAN impossible',
    ABANDONNE: 'Le parcours d\'Open Banking a été abandonné. Récupération de l\'IBAN impossible',
    ERREUR: 'Une erreur est survenue. Récupération de l\'IBAN impossible',
    EXPIRE: 'La session d\'Open Banking a expiré. Veuillez recommencer le parcours d\'Open Banking',
    REFUSE: 'Le parcours d\'Open Banking a été refusé par le client. Récupération de l\'IBAN impossible',
    HTTP_ERREUR: 'Le service de récupération de l\'IBAN a rencontré un problème. Veuillez réessayer ou saisir l\'IBAN manuellement'
};

export const HTTP_ERROR = 'HTTP_ERREUR';

const STATUS_WITH_ENABLE_MODIFY_IBAN = [
    'ABANDONNE', 'ERREUR', 'EXPIRE', 'REFUSE', HTTP_ERROR
];

@Injectable({
    providedIn: 'root'
})
export class OpenBankingService {
    public ibanFromOpenBankingSubject: BehaviorSubject<string | null> = new BehaviorSubject(null);
    public statusMessageSubject: BehaviorSubject<string | null> = new BehaviorSubject(null);
    public enableModifyIbanSubject: BehaviorSubject<string | null> = new BehaviorSubject(null);

    constructor(
        private salesRiskControlRepositoryRepository: SalesRiskControlRepositoryRepository,
    ) { }

    public bankCheck(idPerson: string): Observable<RechercherVerificationBancaireOut | null> {
        return this.salesRiskControlRepositoryRepository
            .bankCheck(idPerson)
            .pipe(catchError(() => of(null)));
    }

    public getOpenBankingScoring(idPanier: string, isRegistryCommande = true): Observable<ControleRisqueVente | null> {
        return this.salesRiskControlRepositoryRepository
            .autorisationVente(idPanier, isRegistryCommande)
            .pipe(catchError(() => of(null)));
    }

    public requestOpenBanking(body: DemanderVerificationBancaireIn): Observable<DemanderVerificationBancaireOut> {
        return this.salesRiskControlRepositoryRepository.requestOpenBanking(body);
    }


    public getOpenBankingStatusMessage(status: string): string | null {
        return openBankingStatus[status];
    }

    public mustEnableModifyIban(status: string): boolean {
        return STATUS_WITH_ENABLE_MODIFY_IBAN.includes(status);
    }

    public cancelOpenBanking(idPerson: string, body: ModifierStatutVerificationBancaireIn): Observable<void> {
        return this.salesRiskControlRepositoryRepository
            .cancelOpenBanking(idPerson, body);
    }
}
