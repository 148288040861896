<div class="box" *ngIf="suggestions.length">
    <div class="box-content">
        <div class="columns is-multiline">
            <div class="column is-narrow is-paddingless has-text-right is-pos-sticky is-12">
                <span class="icon is-small has-cursor" (click)="suggestions = []"><i class="tri-times-circle"></i></span>
            </div>
            <div class="column suggestion-items">
                <ng-container *ngFor="let suggestion of suggestions">
                    <ng-container *ngIf="wordsSuggestions[suggestion.toLowerCase()] else forbiddenWord">
                        <p><span class="has-text-weight-semibold is-size-5 is-uppercase">{{suggestion}}</span> comme <span class="is-capitalized has-text-weight-semibold">{{wordsSuggestions[suggestion][0]}}</span>{{wordsSuggestions[suggestion].substr(1)}}</p>
                    </ng-container>
                    <ng-template #forbiddenWord>
                        <p class="has-text-weight-semibold is-size-5">{{suggestion}} est un caractère interdit</p>
                    </ng-template>
                </ng-container>
            </div>
        </div>
    </div>
</div>