<div class="modal-representant-legal is-tri" data-cy="siren-invalid" data-cy="appointment-modal-skip">
    <div class="columns is-multiline is-marginless is-centered has-text-centered">
        <div class="column is-narrow">
            <span class="icon has-text-info is-huge">
                <i class="tri-exclamation-circle"></i>
            </span>
            <p class="has-text-weight-semibold is-size-5">Prise de rendez-vous !</p>
        </div>

        <div class="column is-12">
            <div>
                <p>Souhaitez-vous consulter le calendrier ?</p>
                <p><span class="has-text-weight-semibold">Etape Rendez-vous</span> : visualisation du calendrier et choix d'un RDV <br>(/!\ En appel sortant, le calendrier s'affichera en consultation uniquement)</p>
                <p><span class="has-text-weight-semibold">Etape Paiement</span> : pas de visualisation du calendrier et passage direct au paiement en mode hybride</p>
            </div>
        </div>

         <div class="column is-12">
            <div class="buttons is-multiline">
                <button class="button is-small is-primary"  data-cy="bypass-appointment-false" (click)="continue()">
                    <span class="icon-and-text">
                        <span>Etape Rendez-vous</span>
                      </span>
                </button>
                <button class="button is-small is-tertiary" data-cy="bypass-appointment-true" (click)="skip()">
                    <span class="icon-and-text">
                        <span>Etape Paiement</span>
                      </span>
                </button>
            </div>
        </div>

    </div>
</div>
