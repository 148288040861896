import { IDeliverySlots } from '@interfaces/api/appointment/appointmentResponse';
import { SlotModel } from './slot.model';

export class DeliveryAppointmentModel extends SlotModel{

    public dataToUpdate?: IDeliverySlots;

    constructor(data: Partial<DeliveryAppointmentModel>) {
        super(data);
    }

}
