import { Injectable } from '@angular/core';
import { IFundingResponse } from '@interfaces/funding.interface';
import { MainCartModel } from '@models/cart/main-cart.model';
import { FundingRepository } from '@repositories/funding.repository';
import { Observable } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class FundingService {

    constructor(
        private fundingRepository: FundingRepository
    ) { }

    public getPaymentMethods(cartModel: MainCartModel): Observable<IFundingResponse> {
        return this.fundingRepository.getFundingMethod(cartModel);
    }

    public postFundingMethod(cartModel: MainCartModel, paymentType: string): Observable<IFundingResponse> {
        return this.fundingRepository.postFundingMethod(cartModel, paymentType);
    }
}
