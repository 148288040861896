import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Fai, QUOTE_CONTEXTS, QuoteContextFaiModel } from '@bytel/bytel-sales';
import { PortabilityInfoStep } from '@components/checkout/step/portability-info/portability-info.step';
import { WetoLoginComponent } from '@components/fai-widget/eligibility/widget/weto-login/weto-login.component';
import {
    WetoPortabilityComponent
} from '@components/fai-widget/eligibility/widget/weto-portability/weto-portability.component';
import { WetoVoipComponent } from '@components/fai-widget/eligibility/widget/weto-voip/weto-voip.component';
import { PortabilityModel } from '@models/cart/portability.model';
import { OrderModel } from '@models/order/order.model';
import { PortaValidationModel } from '@models/validators/porta-validation.model';
import { CartTeleSalesService } from '@services/checkout/cart-telesales.service';
import { PortabilityService } from '@services/checkout/portability.service';
import { SalesService } from '@services/checkout/sales.service';
import { StepperService } from '@services/checkout/stepper.service';
import { FaiEligibilityService } from '@services/fai/fai-eligibility.service';
import { SalesForceService } from '@services/salesforce.service';
import { Subscription } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

@Component({
    selector: 'tlv-portability-weto',
    templateUrl: './portability-weto.component.html',
    styleUrls: ['./portability-weto.component.scss']
})
export class PortabilityWetoComponent implements OnDestroy, OnInit, AfterViewInit {
    @ViewChild('wetoLogin') wetoLoginComponent: WetoLoginComponent;
    @ViewChild('wetoVoip') wetoVoipComponent: WetoVoipComponent;
    @ViewChild('wetoPortability') wetoPortabilityComponent: WetoPortabilityComponent;

    public isLoading = false;
    public isPortabilityLoaded: boolean = false;
    public isLoginLoaded: boolean = false;
    public isVoipLoaded: boolean = false;
    public formPortabilityValid: boolean = false;
    public formLoginValid: boolean = false;
    public portabilityForm: FormGroup = new FormGroup({
        keepNumber: new FormControl(true, [Validators.required])
    });
    public logins: string[] = [];
    public toggleRioCodes = false;
    public rioOperatorInfos: {name: string; info: string}[] = [
        {name: 'ACN Communications', info: '0800 71 3179'},
        {name: 'Afone', info: '0805 16 3179'},
        {name: 'Akeo Telecom', info: '0800 71 3179'},
        {name: 'Budget Telecom', info: '0800 71 3179'},
        {name: 'Ciel Telecom', info: '0800 71 3179'},
        {name: 'Coriolis', info: '0800 71 3179'},
        {name: 'France Telephony', info: '0800 71 3179'},
        {name: 'Free', info: '0805 92 3179'},
        {name: 'IP Directions', info: '0805 28 3179'},
        {name: 'Legos', info: '0805 08 4198'},
        {name: 'Numericable', info: '0805 85 8985'},
        {name: 'Orange', info: '0800 00 3179'},
        {name: 'OVH', info: '0805 69 3179'},
        {name: 'Prixtel', info: '0800 71 3179'},
        {name: 'Réseau Santé Social', info: '0800 71 3179'},
        {name: 'SFR', info: '0800 97 3179'},
        {name: 'Sosh', info: '0800 00 3179'},
        {name: 'UKtelecom', info: '0800 71 3179'},
        {name: 'Virgin', info: '0800 71 3179'}
    ];
    public portabilityData: PortaValidationModel;
    public orderRecovery: OrderModel;
    public reservedLogin: string;
    public isCustomLogin: boolean;
    public reservedVoip: string;
    public portability: PortabilityModel;
    private _subscriptions$: Subscription[] = [];

    constructor(
        public step: PortabilityInfoStep,
        private faiEligibilityService: FaiEligibilityService,
        private salesService: SalesService,
        private portabilityService: PortabilityService,
        private stepperService: StepperService,
        private cartService: CartTeleSalesService,
        private salesForceService: SalesForceService,
    ) { }

    public ngOnInit(): void {
        const indexQuote = this.cartService.getQuoteIndexByContext(QuoteContextFaiModel);
        this.orderRecovery = this.salesForceService.prefilledInfo.order;
        if (this.orderRecovery?.cart?.portability) {
            this._restoreDataFromRecovery();
        } else {
            this.reservedLogin = this.faiEligibilityService?.currentCart?.selectedLogin;
            this.isCustomLogin = this.faiEligibilityService?.currentCart?.isCustomLogin;
            this.reservedVoip = this.faiEligibilityService?.currentCart?.selectedVoip;
            this.portability = this.portabilityService.getPortabilityFromCartModel(indexQuote);
            if (this.portability?.phoneNumber && !this.portability?.rioCode) {
                this.portabilityForm.get('keepNumber').setValue(false);
            }
        }
    }

    public ngAfterViewInit(): void {
        this.wetoPortabilityComponent?.endToLoad.subscribe(
            (data) => { this.isPortabilityLoaded = !!data;}
        );
        this.wetoPortabilityComponent?.formValid.subscribe(
            (data) => { this.formPortabilityValid = !!data;}
        );
    }

    public setComponantVoipLoaded(isLoaded: boolean): void {
        if (isLoaded && this.isLoginLoaded) {
            this.wetoVoipComponent.endToLoad.subscribe(
                (voipEndToload) => { this.isVoipLoaded = !!voipEndToload;}
            );
        }
    }

    public setComponantLoginLoaded(isLoaded: boolean): void {
        if (isLoaded) {
            this.wetoLoginComponent?.endToLoad.subscribe(
                (data) => {
                    this.isLoginLoaded = !!data;
                    this.formLoginValid = true;
                }
            );
            this.wetoLoginComponent?.formValid.subscribe(
                (data) => {
                    this.formLoginValid = data;
                }
            );
        }
    }

    public ngOnDestroy(): void {
        this._subscriptions$.forEach((sub: Subscription) => sub.unsubscribe());
    }

    public canDisabled(): boolean {
        if (this.portabilityForm.get('keepNumber').value ===  true ) {
            return this.formPortabilityValid && this.formLoginValid;
        } else {
            return this.isLoginLoaded  && this.isVoipLoaded && this.formLoginValid;
        }
    }

    public submit(): void {
        this.isLoading = true;
        // @ts-ignore
        if (this.portabilityForm.get('keepNumber').value === false) {
            this.wetoVoipComponent.submit()
                .pipe(
                    mergeMap(
                        (voip) => {
                            this.faiEligibilityService.selectVoip(voip);
                            this.portabilityService.update(new PortabilityModel({
                                rioCode : null,
                                phoneNumber: voip,
                                lineType: QUOTE_CONTEXTS.ACQUISITIONFIX
                            }));
                            return this.wetoLoginComponent
                                .submit()
                                .pipe(
                                    map((loginWeto) => {
                                        this.faiEligibilityService.selectLogin(loginWeto.login, loginWeto.selected);
                                        this.updatePortabilityProduct();
                                    }));
                        }
                    ),
                ).subscribe();
        } else {
            // @ts-ignore
            this.wetoPortabilityComponent
                .submit()
                .pipe(
                    mergeMap(
                        (portability) => {
                            this.faiEligibilityService.selectVoip(undefined);
                            this.portabilityService.update(new PortabilityModel({
                                rioCode: portability?.codeRio,
                                phoneNumber: portability?.noTelephoneAPorter,
                                lineType: QUOTE_CONTEXTS.ACQUISITIONFIX
                            }));
                            return this.wetoLoginComponent
                                .submit()
                                .pipe(
                                    map((loginWeto) => {
                                        this.faiEligibilityService.selectLogin(loginWeto.login, loginWeto.selected);
                                        this.updatePortabilityProduct();
                                    }));
                        }
                    )
                ).subscribe();
        }
    }

    private updatePortabilityProduct(): void {
        const indexQuote = this.cartService.getQuoteIndexByContext(QuoteContextFaiModel);
        this.salesService.updateProduct(this.cartService.cartModel.getQuote(indexQuote)?.getProductByType(Fai), indexQuote)
            .subscribe({
                next: () => {
                    this.faiEligibilityService.currentCart.updated = true;
                    this.faiEligibilityService.reserveLogin();
                    this.stepperService.goToNextStep();
                },
                error: () => {
                    this.isLoading = false;
                }
            });
    }

    private _restoreDataFromRecovery(): void {
        const { rioCode, phoneNumber } = this.orderRecovery.cart.portability;
        if (phoneNumber && rioCode) {
            this.portability = {
                phoneNumber,
                rioCode,
                lineType: QUOTE_CONTEXTS.ACQUISITIONFIX,
                login: null,
                expirationDate: null,
                status: null
            };
        }
    }


}
