import { Component, Input, OnInit } from '@angular/core';
import { BasicObject } from '@common-modules';
import { Accessory, EdpFms, Option, Product, QuoteModel, ProductFactory } from '@bytel/bytel-sales';
import { MainCartModel } from '@models/cart/main-cart.model';
import { CartTeleSalesService } from '@services/checkout/cart-telesales.service';
import bind from 'src/app/helper/decorators/bind';
import { Distinc } from 'src/app/helper/utils';
import { ProductRepository } from '@repositories/product.repository';

@Component({
    selector: 'tlv-basic-crosssells',
    templateUrl: './basic-crosssells.component.html',
    styleUrls: ['./basic-crosssells.component.scss']
})
export class BasicCrosssellsComponent implements OnInit {


    public static CROSSSELL_TYPES: BasicObject = {
        OPTION: 'option',
        ACCESSORY: 'accessory'
    };

    @Input() public type: string;

    public config: BasicObject;
    public products: Product[] = [];
    @Input()
    public showProducts: boolean = true;
    public quote: QuoteModel;

    constructor(
        private cartService: CartTeleSalesService
    ) {
    }

    public ngOnInit(): void {
        this.config = {
            [BasicCrosssellsComponent.CROSSSELL_TYPES.OPTION]: {
                title: 'Mes Options',
                homeTitle: 'Ajouter une option'
            },
            [BasicCrosssellsComponent.CROSSSELL_TYPES.ACCESSORY]: {
                title: 'Mes accessoires choisis',
                homeTitle: 'Ajouter un accessoire'
            }
        };
        this.cartService.refreshObs.subscribe(this._updateProducts);
    }

    public isAutoAdd(product: Product): boolean {
        return !!this.quote.products.find((products) => products.autoAdd.includes(product.gencode)) && !ProductFactory.Is(product, EdpFms);
    }

    @bind
    private _updateProducts(cart: MainCartModel): void {
        this.quote = cart.getQuote(cart.currentQuoteIndex);
        switch (this.type) {
            case BasicCrosssellsComponent.CROSSSELL_TYPES.OPTION:
                this.products = Distinc(this.quote.getProductsByType(Option), 'gencode')
                    .filter(p=>p.gencode !== ProductRepository.OPTION_PRO_GENCODE) // remove oc pro
                    .sort((a: Product, b: Product) => !!a.name && a.name.localeCompare(b.name));
                break;

            case BasicCrosssellsComponent.CROSSSELL_TYPES.ACCESSORY:
                this.products = Distinc(this.quote.getProductsByType(Accessory), 'gencode')
                    .sort((a: Product, b: Product) => !!a.name && a.name.localeCompare(b.name));
                break;
            default:
                console.warn('unknown type of product');
        }
    }
}
