export interface IEnvEph {
    data: IData;
}

export interface IData {
    project: IProject;
}

export interface IProject {
    environments: IEnvironments;
}

export interface IEnvironments {
    nodes: INode[];
}

export interface INode {
    id:          string;
    name:        string;
    state:       INodeState;
    externalUrl: string;
    createdAt:   Date;
    updatedAt:   Date;
}

export enum INodeState {
    Available = 'available',
    Stopped = 'stopped',
    Stopping = 'stopping',
}
