import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
    Box,
    Equipment,
    Faim,
    FaimPremium,
    FaimUnlimited,
    Plan,
    ProductFactory } from '@bytel/bytel-sales';
import { CompatibleEsimTerminalsModalComponent } from
    '@components/modal/compatible-esim-terminals-modal/compatible-esim-terminals-modal.component';
import { DialogService } from '@ngneat/dialog';
import { CartTeleSalesService } from '@services/checkout/cart-telesales.service';
import { FaiEligibilityService } from '@services/fai/fai-eligibility.service';
import { TELESALES_URLS } from 'src/app/constants/urls';
import { QuoteMixedModalComponent } from '../quote-mixed-modal/quote-mixed-modal.component';
import { Subscription } from 'rxjs';


@Component({
    selector: 'tlv-empty-cart-item',
    templateUrl: './empty-cart-item.component.html',
    styleUrls: ['./empty-cart-item.component.scss']
})
export class EmptyCartItemComponent implements OnInit, OnDestroy {

    @Input() public itemColor: string;
    @Input() public itemIcon: string;
    @Input() public itemLabel: string;
    @Input() public wallType: string;
    @Input() public isCartEmpty: boolean;
    @Input() public showCmsModalLink = true;

    public textColor: string;
    public wallUrl: string;

    private _subscriptions: Subscription[] = [];

    constructor(
        private cartTeleService: CartTeleSalesService,
        private faiEligibilityService: FaiEligibilityService,
        public router: Router,
        private dialogService: DialogService,
    ) { }


    public ngOnInit(): void {
        const principalSubscription = this.cartTeleService.cartModel.getQuote()?.getPrincipalProduct<Plan>('Plan');
        const principalEquipment = this.cartTeleService.cartModel.getQuote()?.getPrincipalProduct<Equipment>('Equipment');
        this.textColor = `has-text-${this.itemColor}`;
        if (ProductFactory.Is(principalSubscription, Faim) ||
            ProductFactory.Is(principalSubscription, FaimUnlimited)) {
            this.itemLabel = 'Ajouter une Box';
            this.showCmsModalLink = false;
        }
        this.wallUrl = '/' + this._getWallUrl(principalSubscription,principalEquipment);
    }

    public goToWall(): void {
        if (this.cartTeleService.isQuoteMixed() && this.wallUrl === `/${TELESALES_URLS.phone_simple}`) {
            const refDialog = this.dialogService.open(
                QuoteMixedModalComponent, {
                    data: {
                        isSimo: false,
                        accessory: false,
                        phone: true
                    },
                    closeButton: false,
                    enableClose: false
                }
            );
            this._subscriptions.push(refDialog.afterClosed$.subscribe((skip) => {
                if (skip) {
                    this.cartTeleService.clearQuoteFai();
                    this.router.navigateByUrl(this.wallUrl);
                }
            }));
        } else {
            this.router.navigateByUrl(this.wallUrl);
        }
    }

    public compatibleTerminalCms(event: Event): void {
        event.stopPropagation();
        this.dialogService.open(CompatibleEsimTerminalsModalComponent);
    }

    public ngOnDestroy(): void {
        this._subscriptions.forEach(sub => sub?.unsubscribe());
    }

    protected _getWallUrl(principalSubscription: Plan, principalEquipment: Equipment): string {
        let wallUrl: string;
        switch (this.wallType) {
            case 'equipment':
                wallUrl = TELESALES_URLS.phone_simple;
                if (ProductFactory.Is(principalSubscription, Faim) ||
                ProductFactory.Is(principalSubscription, FaimPremium)) {
                    wallUrl = TELESALES_URLS.box;
                }
                break;
            case 'subscription':
                wallUrl = TELESALES_URLS.plan_simo;
                if (ProductFactory.Is(principalEquipment, Box)) {
                    wallUrl = TELESALES_URLS.faim;
                }
                break;
            case 'fai':
                wallUrl = TELESALES_URLS.fai;
                if (this.faiEligibilityService?.currentCart?.eligibility){
                    wallUrl = TELESALES_URLS.fai_result;
                }
                break;
            default:
                wallUrl = TELESALES_URLS[this.wallType];
                break;
        }
        return wallUrl;
    }
}

