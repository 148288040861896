import { Injectable } from '@angular/core';
import { IStep, STEP_STATUS } from '@interfaces/step.interface';
import { CartTeleSalesService } from '@services/checkout/cart-telesales.service';
import { Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CustomerService } from '@services/customer/customer.service';
import { FaiEligibilityService } from '@services/fai/fai-eligibility.service';
import { Fai } from '@bytel/bytel-sales';

@Injectable({
    providedIn: 'root'
})
export class DeliveryStep implements IStep{

    public icon: string = 'tri-marker';
    public name: string = 'Livraison';
    public label: string = 'Livraison';
    public route: string[] = [
        'panier',
        'livraisons'
    ];
    public status: STEP_STATUS = STEP_STATUS.WAITING;
    public canBeValidate: boolean = false;

    constructor(private cartTeleSalesService: CartTeleSalesService,
                private router: Router,
                private faiEligibilityService: FaiEligibilityService,
                private customerService: CustomerService) {}

    public validateStep(): boolean {
        return true;
    }

    public canAccess(): boolean {
        const fai: Fai[] = this.cartTeleSalesService.cartModel.getQuote().getProductsByType(Fai, true);
        if (fai?.length && !this.faiEligibilityService.currentCart?.updated) {
            return false;
        }
        return this.customerService.customer?.isUpdated && this.canDisplay();
    }

    public canActivate(): Observable<boolean | UrlTree>{
        return this.cartTeleSalesService.resolve().pipe(map(()=>this.canAccess() ?? this.router.parseUrl('/panier')));
    }

    public canDisplay(): boolean {
        return !this.cartTeleSalesService.cartModel.isCartEmpty();
    }
}
