<div class="tabs is-centered" role="tablist">
    <ul>
        <li role="presentation" *ngFor="let tab of tabs | keyvalue: keepOrder">
            <a (click)="changeTab(tab.key)" [data-cy]="'proposal-'+tab.value.label" role="tab"  class="input-has-badge" [ngClass]="{'is-active': tab.value.label === currentTab.label}" aria-selected="true">
                {{tab.value.title}}
                <span class="badge" *ngIf="tab.value.count || tab.value?.data?.length">{{ tab.value?.data?.length || tab.value.count }}</span>
            </a>
        </li>
    </ul>
</div>
<div  *ngIf="tabs.line && currentTab.title === tabs.line.title">
    <tlv-line [isLoading]="isLoading" [customer]="customer" (savedEvent)="close($event)"></tlv-line>
</div>

<!--div *ngIf="currentTab.title === tabs.commercialProposal.title">
    <div *ngIf="!isLoading && tabs.commercialProposal.data.length === 0">
        <span>Propositions commerciales non trouvées</span>
    </div>
    <tlv-cart-list [isLoading]="isLoading" [isClientLockByScoring]="isClientLockByScoring" (savedEvent)="close($event)" [recoveryCarts]="tabs.commercialProposal.data"></tlv-cart-list>
</div-->

<div *ngIf="currentTab.title === tabs.abandonedCart.title">
    <div *ngIf="!isLoading && tabs.abandonedCart.data.length === 0">
        <span>Paniers non trouvés</span>
    </div>
    <tlv-cart-list [isLoading]="isLoading" [isClientLockByScoring]="isClientLockByScoring" (savedEvent)="close($event)" [recoveryCarts]="tabs.abandonedCart.data"></tlv-cart-list>
</div>

<div *ngIf="currentTab.title === tabs.order.title">
    <div *ngIf="!isLoading && tabs.order.data.length === 0">
        <span>Commandes non trouvées</span>
    </div>
    <tlv-cart-list [isLoading]="isLoading"   [isClientLockByScoring]="isClientLockByScoring"(savedEvent)="close($event)" [recoveryCarts]="tabs.order.data"></tlv-cart-list>
</div>
