import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Equipment, ProductFactory } from '@bytel/bytel-sales';
import { MainCartModel } from '@models/cart/main-cart.model';
import { CartTeleSalesService } from '@services/checkout/cart-telesales.service';
import { SimService } from '@services/checkout/sim.service';

@Component({
    selector: 'tlv-esim-delivery-infos',
    templateUrl: './esim-delivery-infos.component.html',
    styleUrls: ['./esim-delivery-infos.component.scss']
})
export class EsimDeliveryInfosComponent implements OnInit {

    public hasEquipment: boolean = false;
    public hasESim: boolean = false;
    @Output() shouldSkipShippingAddress: EventEmitter<boolean> = new EventEmitter<false>();

    constructor(
        public cartTeleSalesService: CartTeleSalesService,
        private simService: SimService
    ) {
        const cartModel: MainCartModel = this.cartTeleSalesService.cartModel;
        this.hasEquipment = !!cartModel.getAllProducts().find(p=>ProductFactory.Is(p, Equipment));
        this.hasESim = this.simService.hasESimInCart(cartModel);
    }

    public ngOnInit(): void {
        // Known trick to avoid ExpressionChangedAfterItHasBeenCheckedError error
        // since this value will be changed after being check by the parent
        Promise.resolve().then(()=>{
            this.shouldSkipShippingAddress.emit(!this.hasEquipment && this.hasESim);
        });
    }
}
