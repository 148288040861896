import { BasicObject } from '@common-modules';


export const get = (data: BasicObject, path: string): any => {
    const pathArray = path.split('.');
    let currentData = data;
    pathArray.forEach((key: string) => {
        currentData = currentData && currentData[key];
    });
    return currentData;
};

export const fillTemplate = (template: string, values: string[]): string => {
    if (template) {
        return template.replace(/{(\d+)}/g, (match: string, index: number) => values[index] || match); // NOSONAR
    }
    return '';
};

export const modulo = (n: number, m: number): number => {
    const remain = n % m;
    return remain >= 0 ? remain : Math.floor(remain + m);
};
