import { Component, Input, OnInit } from '@angular/core';
import {
    Activation,
    Equipment,
    Fai,
    FaimUnlimited,
    Installation,
    ProductFactory,
    QuoteModel,
    QuoteContextFaiModel
} from '@bytel/bytel-sales';
import { CartTeleSalesService } from '@services/checkout/cart-telesales.service';
import { CatalogService } from '@services/catalog.service';

@Component({
    selector: 'tlv-fai-box',
    templateUrl: './fai-box.component.html',
    styleUrls: ['./fai-box.component.scss']
})
export class FaiBoxComponent implements OnInit {

    @Input() product: Equipment;
    public productAssociated: Equipment;
    public activation: Activation;
    public productImages: string;
    public installation: Installation;
    public plan: Fai;
    public isFai: boolean;
    public isLoading;



    constructor(
        protected cartService: CartTeleSalesService,
        private catalogService: CatalogService) {}

    public ngOnInit(): void {
        this.isLoading = true;
        const index = this.getQuoteIndex();
        const quote: QuoteModel = this.cartService.cartModel.getQuote(index);
        this.plan = quote?.getPrincipalProduct<Fai>(Fai);
        this.isFai = ProductFactory.Is(this.plan, Fai, true);
        const activations = quote?.getProductsByType<Activation>('Activation');
        this.activation = activations.length ? activations[0] : null;

        const installation = quote?.getProductsByType<Installation>('Installation');
        this.installation = installation.length ? installation[0] : null;

        if (ProductFactory.Is(this.plan, FaimUnlimited) && this.plan?.data?.produit_prioritaire) {
            this.catalogService.getProductByGencode<Equipment>(this.plan.data.produit_prioritaire).subscribe((p: Equipment) => {
                this.productAssociated = p;
                this.productImages = p.imageHD ?? p.smallImage + ',' + p.image;
                this.isLoading = false;
            });
        } else {
            this.productImages = this.plan.imageHD ?? this.plan.smallImage + ',' + this.plan.image;
            this.isLoading = false;
        }
    }

    public getQuoteIndex(): number {
        return this.cartService.getQuoteIndexByContext(QuoteContextFaiModel);
    }
}
