<div class="columns is-centered is-marginless">
    <div class="column">
        <div class="columns is-multiline is-vcentered" data-cy="accessory-modal">
            <div class="column is-4">
                <div class="columns is-mobile is-vcentered">
                    <span class="column is-narrow icon is-medium" *ngIf="product.medias?.length > 1" (click)="switchMedia(-1)">
                        <i class="tri-arrow-left"></i>
                    </span>
                    <div class="column card-image">
                        <figure>
                            <img tlvImageLoading [btlSrc]="product.medias[currentMediaIndex]" [alt]="product.data?.name" class="accessory-image"/>
                        </figure>
                    </div>
                    <span class="column is-narrow icon is-medium" *ngIf="product.medias?.length > 1" (click)="switchMedia(1)">
                        <i class="tri-arrow-right"></i>
                    </span>
                </div>
            </div>
            <div class="column is-4">
                <p class="is-title is-size-3">{{product.data?.name}}</p>
                <p class="icon-and-text">
                    <span *ngIf="product.data?.color" class="is-title is-size-7 has-text-weight-semibold">{{product.data?.color}}</span>
                    <span class="icon is-small is-circled" *ngIf="isWhite" [ngStyle]="{'border': '1px solid #b5b5b5' }"></span>
                    <span class="icon is-small is-circled" *ngIf="!isWhite" [ngStyle]="{'background-color': product.data?.color_code }"></span>
                </p>
                <div *ngIf="product.data?.width || product.data?.length || product.data?.depth">
                    <div class="is-title is-size-7 has-text-weight-semibold" bytelTranslate="panier.recapitulatif.accessoire.modale.dimensions">Dimensions : </div>
                    <span *ngIf="product.data?.width">{{product.data?.width | number}}&nbsp;cm&nbsp;/ </span>
                    <span *ngIf="product.data?.length">{{product.data?.length | number}}&nbsp;cm&nbsp;/ </span>
                    <span *ngIf="product.data?.depth">{{product.data?.depth | number}}&nbsp;cm</span>
                </div>
                <div *ngIf="product.data?.weight"><div class="is-title is-size-7 has-text-weight-semibold" bytelTranslate="panier.recapitulatif.accessoire.modale.dimensions">Poids net : </div>{{product.data?.weight | number}} g</div>
                <div *ngIf="product.data?.material"><div class="is-title is-size-7 has-text-weight-semibold" bytelTranslate="panier.recapitulatif.accessoire.modale.matiere">Matière : </div>{{product.data?.material}}</div>
                <div *ngIf="product.data?.pack_content"><div class="is-title is-size-7 has-text-weight-semibold is-marginless" bytelTranslate="panier.recapitulatif.accessoire.modale.contenu_pack">Contenu du pack : </div><div [innerHtml]="product.data?.pack_content"></div></div>
            </div>
            <div class="column is-4 has-text-centered">
                <tlv-item-block-btn [product]="product"></tlv-item-block-btn>
            </div>
            <div class="is-divider"></div>
            <div class="column is-12 has-text-left-tablet">
                <p class="title is-size-3" bytelTranslate="panier.recapitulatif.accessoire.modale.description">Description</p>
                <p class="text is-1" *ngIf="product.data?.features" [innerHTML]="product.data?.features"></p>
            </div>
        </div>
    </div>
</div>
