import {Component, OnInit} from '@angular/core';
import {Product} from '@bytel/bytel-sales';
import {DialogRef} from '@ngneat/dialog';
import {CatalogService} from '@services/catalog.service';
import {CartTeleSalesService} from '@services/checkout/cart-telesales.service';
import {catchError, finalize} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import {HotToastService} from '@ngneat/hot-toast';
import { OptionsAvailableService } from '@services/checkout/options-available.service';

@Component({
    selector: 'tlv-option-modal',
    templateUrl: './option-modal.component.html',
    styleUrls: ['./option-modal.component.scss']
})
export class OptionsModalComponent implements OnInit {

    public buttonLabel: string;
    public description: string;
    public isLoading: boolean = true;

    public BUTTON_LABELS: {[key: string]: string} = {
        add: 'Ajouter',
        remove: 'Supprimer'
    };

    constructor(
        protected cartTeleSalesService: CartTeleSalesService,
        protected catalogService: CatalogService,
        private optionsAvailableService: OptionsAvailableService,
        public ref: DialogRef,
        private toastService: HotToastService
    ) { }

    public addOrRemoveOption(): void {
        this.isLoading = true;
        const quoteIndex = this.ref.data.quoteIndex === undefined ?
            this.cartTeleSalesService.cartModel.currentQuoteIndex :
            this.ref.data.quoteIndex;
        this.optionsAvailableService.subjectCrossSellLoader.next(true);
        let obs: Observable<Product | Product[]>;
        if (this.cartTeleSalesService.getProductQty(this.ref.data.product, quoteIndex) >= this.ref.data.product.data.max_qty) {
            obs = this.cartTeleSalesService.removeProductsByGencode(this.ref.data.product.gencode, quoteIndex);
        } else {
            obs = this.cartTeleSalesService.addProduct(this.ref.data.product, quoteIndex);
        }

        obs.subscribe({next: () => {
            this._updateLabel();
            this.cartTeleSalesService.refresh();
            this.isLoading = false;
        }, error: () => this.isLoading = false});
    }

    public ngOnInit(): void {
        this.catalogService.getProductDetails(this.ref.data.product.gencode).pipe(
            finalize(()=>this.isLoading = false),
            catchError(()=>{
                if (this.ref.data.product.data?.short_description) {
                    this.toastService.warning('Impossible de récupérer la description de l\'option');
                }
                return throwError(null);
            })
        ).subscribe((opt: Product)=>{
            this.description = opt?.data?.description || this.ref.data.product.data.short_description;
            this._updateLabel();
        });
    }

    private _updateLabel(): void {
        this.buttonLabel = this.BUTTON_LABELS.add;
        const quoteIndex = this.ref.data.quoteIndex === undefined ?
            this.cartTeleSalesService.cartModel.currentQuoteIndex :
            this.ref.data.quoteIndex;
        if (this.cartTeleSalesService.getProductQty(this.ref.data.product, quoteIndex) >= this.ref.data.product.data.max_qty) {
            this.buttonLabel = this.BUTTON_LABELS.remove;
        }
    }
}
