import { Component } from '@angular/core';
import { AppointmentModel } from '@models/cart/appointment.model';
import { DialogRef } from '@ngneat/dialog';
import { AppointmentService } from '@services/checkout/appointment.service';
import { StepperService } from '@services/checkout/stepper.service';


@Component({
    selector: 'tlv-calendar-skip',
    templateUrl: './calendar-skip.component.html',
    styleUrls: ['./calendar-skip.component.scss']
})
export class CalendarSkipComponent {

    constructor(
        public ref: DialogRef,
        private readonly stepperService: StepperService,
        private appointmentService: AppointmentService
    ) {}

    public skip(): void {
        this.appointmentService.currentAppointment = new AppointmentModel({});
        this.appointmentService.skipStep = true;
        this.stepperService.goToNextStep();
        this.ref.close();
    }

    public continue(): void{
        this.appointmentService.skipStep = false;
        this.ref.close();
    }
}

