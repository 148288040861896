import {DOCUMENT} from '@angular/common';
import {Component, Inject, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ConfigurationService, Oauth2Service} from '@common-modules';
import {CustomerModel} from '@models/customer/customer.model';
import {SalesUser} from '@models/sales-user';
import {ScoringService} from '@services/checkout/scoring.service';
import {CustomerService} from '@services/customer/customer.service';
import {QualificationService} from '@services/qualification.service';
import {SalesUserService} from '@services/sales-user.service';
import {RecommendationFeedBackModel} from '@models/recommendation';
import {RecommendationService} from '@services/recommendation.service';
import {Observable} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {NotificationsService} from '@services/notification.service';
import {NotificationModel} from '@models/notification.model';
import {FAI_ROUTES} from 'src/app/constants/fai';
import {WetoStorage} from '@repositories/storages/weto.storage';
import {OrderService} from '@services/order.service';
import {OpportunityService} from '@services/opportunity.service';
import {CustomerProDetailsModel} from '@models/customer/customer-pro-details.model';
import {SalesForceService} from '@services/salesforce.service';
import {NOTIFICATIONS_TYPES_LABEL} from 'src/app/constants/notifications';
import {CUSTOMER_CATEGORY} from '@bytel/bytel-sales';
import {CUSTOMER_TYPE} from '@interfaces/api/create/panier';
import {DialogRef} from '@ngneat/dialog';
import {CartTeleSalesService} from '@services/checkout/cart-telesales.service';

const MENU_ID_SIMULATEUR = 'simulateur';

interface IMenuLink {
    idMenu?: string;
    link: string;
    label: string;
    icon: string;
    urlFragment?: string;
    rule?: string;
    dataCy?: string;
    isExternal?: boolean;
    disabled?: boolean;
}

@Component({
    selector: 'tlv-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

    public displayNotificationsPanel: boolean = false;
    public salesUser: SalesUser;
    public customer: CustomerModel;
    public menuLinks: IMenuLink[] = [
        {
            dataCy: 'products',
            label: 'Mobile',
            link: '/products',
            icon: 'tri-mobile',
            urlFragment: 'catalog-products'
        },
        {
            dataCy: 'fai',
            label: 'Fai',
            link: '/fai/address',
            icon: 'tri-box',
            rule: 'fai'
        },
    ];
    public privateMenuLinks: IMenuLink[] = [
        {
            dataCy: 'settings',
            label: 'Settings',
            link: '/settings',
            icon: 'tri-tools',
        }
    ];
    public showMenuLinks: boolean = false;
    public notificationsList: NotificationModel[] = [];
    public unreadNotifications: NotificationModel[] = [];
    public notificationClass: string = 'has-background-primary';
    public readonly NOTIFICATIONS_TYPE = new Map<NOTIFICATIONS_TYPES_LABEL, string>([
        [NOTIFICATIONS_TYPES_LABEL.INCIDENT, 'primary'],
        [NOTIFICATIONS_TYPES_LABEL.COMMERCIAL_OFFER, 'success'],
        [NOTIFICATIONS_TYPES_LABEL.INFORMATION, 'info'],
    ]);

    public IsEnvProd = false;

    constructor(
        private salesUserService: SalesUserService,
        private oauth2Service: Oauth2Service,
        private router: Router,
        private route: ActivatedRoute,
        private customerService: CustomerService,
        private configService: ConfigurationService,
        public qualificationService: QualificationService,
        public scoringService: ScoringService,
        private recommendationService: RecommendationService,
        private notificationService: NotificationsService,
        private orderService: OrderService,
        private opportunityService: OpportunityService,
        private configurationService: ConfigurationService,
        private salesforceService: SalesForceService,
        private cartService: CartTeleSalesService,
        @Inject(DOCUMENT) private document: Document
    ) {
        this.customer = this.customerService.customer;
        this.customerService.customer$.subscribe(
            (customer) => {
                this.customer = customer;
                this.updateMenu();
            }
        );
        this.notificationService.notifications$.subscribe({
            next: (notifications: NotificationModel[]) => {

                if (!notifications.length) {
                    return;
                }

                this.notificationsList = notifications;
                this.unreadNotifications = this.notificationsList.filter(n => !n.isRead);

                if (!this.unreadNotifications.length) {
                    return;
                }

                const firstUnreadNotificationType = this.unreadNotifications[0].notificationType;
                const notificationLabel = NOTIFICATIONS_TYPES_LABEL[firstUnreadNotificationType];
                const notificationColor = this.NOTIFICATIONS_TYPE.get(notificationLabel);
                this.notificationClass = `has-background-${notificationColor}`;
            }
        });
    }

    public updateMenu(): void {
        [...this.menuLinks].forEach((link, index: number) => {
            if (link.rule && !this.salesUser?.permissions[link.rule]) {
                this.menuLinks.splice(index, 1);
            }
        });

        const simulateurIndex = this.menuLinks.findIndex(link => link.idMenu === MENU_ID_SIMULATEUR);
        if (simulateurIndex !== -1) {
            this.menuLinks.splice(simulateurIndex, 1);
        }
        this.menuLinks.push(
            {
                idMenu: MENU_ID_SIMULATEUR,
                dataCy: MENU_ID_SIMULATEUR + '-url',
                label: 'Simulateur',
                link: this.configService.data_refconf.proposition_commerciale?.url + this.getParamsSimulation(),
                isExternal: true,
                icon: 'tri-advisor'
            },
        );
        this.menuLinks.forEach((link) => {
            if (
                (!this.cartService.cartModel.isCartEmpty() && this.qualificationService.isDadCampaign()) &&
                (link.label.toLowerCase() === 'fai' || link.label.toLowerCase() === 'mobile')
            ) {
                link.disabled = true;
            } else {
                link.disabled = false;
            }
        });
    }

    public ngOnInit(): void {
        this.salesUser = this.salesUserService.user;
        this.updateMenu();
        this.orderService.orderSuccessObs$.subscribe(orderSuccessStatus => {
            if (orderSuccessStatus) {
                this.showMenuLinks = false;
            }
        });
        this.qualificationService.$statusObs.subscribe(
            (status) => {
                this.showMenuLinks = (status === this.qualificationService.STATUS.COMPLETED);
            }
        );
        this.cartService.refreshObs.subscribe(() => {
            this.updateMenu();
        });
        this.IsEnvProd = this.configurationService.data_refconf.api.banc.toUpperCase() === 'PROD';
    }

    public closeCall(): void{
        this.sendFeedBack().pipe(
            finalize(() => {
                this.salesUserService.closeCall();
                this.scoringService.clear();
            })
        ).subscribe();
    }

    public checkRoute(): boolean {
        return this.router.routerState.snapshot.url.substring(1).includes('qualification');
    }

    public logout(): void {
        this.closeCall();
        this.oauth2Service.logout();
        // A déplacer dans oauth2 un jour
        this.document.defaultView.location.href
            = `${this.configService.data_refconf.oauth2.logout_url}?service=${window.document.location.origin}`;
    }

    public onMenuClick(menu: IMenuLink): void {
        if (!menu.disabled) {
            if (menu.label === 'Mobile') {
                WetoStorage.clear();
            }
            if (menu.label === 'Fai') {
                if (this.qualificationService.isDadCampaign() && this.cartService.hasSimoInCart() && this.cartService.canAddFaiInQuote()) {
                    const dialogRef: DialogRef<any> = this.opportunityService.openOpportunityDadModal();
                    dialogRef.afterClosed$.subscribe((results: { [key: string]: any }) => {
                        if (!results?.continue) {
                            this.salesUserService.closeCall();
                        }
                    });
                }
            }
        }
    }

    public isActive(link: IMenuLink): boolean {
        const routeUrl: string =  [
            FAI_ROUTES.ADDRESS,
            FAI_ROUTES.NUMBER,
            FAI_ROUTES.HOUSING,
            FAI_ROUTES.OFFERS,
            FAI_ROUTES.PTO,
            FAI_ROUTES.TECHNO,
            FAI_ROUTES.OFFERS
        ].find(r => this.router.routerState.snapshot.url.substring(1).includes(r))
            ? FAI_ROUTES.ADDRESS : this.router.routerState.snapshot.url.split('/')[1];
        const isActive: boolean = link.link.substring(1) === routeUrl;
        return isActive && (this.route.snapshot.fragment ? link.urlFragment === this.route.snapshot.fragment : true);
    }

    public toggleNotificationsPanel(): void {
        this.displayNotificationsPanel = !this.displayNotificationsPanel;
    }

    public getParamsSimulation(): string {
        const customer = this.customerService.customer;
        if (!customer) { return '';}
        const { idPerson, gender, lastname, firstname, email, phone, isClient } = customer;
        const typeClient = this.customerService.isPro() ?  CUSTOMER_CATEGORY.PRO : CUSTOMER_CATEGORY.GP;

        let params: { [p: string]: string } = {
            IdPu: idPerson,
            Civilite: gender,
            Nom: lastname,
            Prenom: firstname,
            Email: email,
            Siren: customer instanceof CustomerProDetailsModel ? customer.company.siren : null,
            RaisonSociale: customer instanceof CustomerProDetailsModel ? customer.company.socialReason : null,
            Telephone: phone,
            TypeVisiteur: isClient ? CUSTOMER_TYPE.CLIENT : CUSTOMER_TYPE.PROSPECT,
            TypeClient: typeClient,
            IdProspect: this.salesforceService.prefilledInfo.idTemporaireProspect,
            IdPrm: this.salesforceService.prefilledInfo.idProspect,
        };

        params = Object.fromEntries(
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            Object.entries(params).filter(([, value]) => value != null)
        );
        return '?' + new URLSearchParams(params).toString();
    }

    protected sendFeedBack(): Observable<void> {
        return this.recommendationService.sendFeedBack(
            new RecommendationFeedBackModel({
                clientId: this.customerService.customer?.idPerson, commandeId: null, clickEvent: 'Terminate', panier:[]
            })
        );
    };

}
