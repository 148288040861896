<div class="grid-container">
    <div class="columns is-centered">
        <div class="colum is-narrow">
            <div class="box">
                <div class="box-content">
                    <p class="is-size-4">Login invalide, merci de réessayer.</p>
                    <div class="buttons is-centered">
                        <button class="is-primary button" [routerLink]="['/']">Retenter la connexion</button>
                    </div>
                </div>  
            </div>
        </div>
    </div>
</div> 