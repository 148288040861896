<header [ngClass]="{'is-invisible': !product.data.label}"
        class="is-uppercase promo-label has-text-white has-text-centered has-background-info has-text-weight-semibold">
    <p>{{product.data?.label}}</p>
</header>
<div class="box">
    <div class="box-content has-text-centered">
        <div class="visual">
            <picture>
                <img tlvImageLoading [btlSrc]="product.image" [btlSrcSet]="productImages"
                    alt="{{product.name}}" [btlSize]="'200w,400w'" class="product-item-image">
            </picture>
        </div>

        <h4 class="is-size-5 has-text-weight-semibold is-title-clipped" data-cy="accessory-search-name">{{product.name}}</h4>
        <div *ngIf="listRatingSkeepers">
            <p [ngClass]="{'is-invisible': !listRatingSkeepers[product.gencode]}">
                <tlv-rating *ngIf="listRatingSkeepers[product.gencode] else hiddenTrgRating"
                    [rating]="listRatingSkeepers[product.gencode]?.length ? +listRatingSkeepers[product.gencode][0]?.rate : 1"
                    marginless max="5" size="5"></tlv-rating>
                <ng-template #hiddenTrgRating>
                    <tlv-rating [rating]="1" marginless max="100" size="5"></tlv-rating>
                </ng-template>
            </p>
        </div>
        <tlv-item-block-btn [maxAllowedQty]="maxAllowedQty" [product]="product"></tlv-item-block-btn>
        <div class="view-more">
            <a (click)="showDetails(product)" class="link has-text-tertiary is-size-7 " [data-cy]="'acc-show-modal-btn-'+product.gencode"
        bytelTranslate="panier.recapitulatif.produits.element.actions.en_savoir_plus">En savoir plus</a>
        </div>
    </div>
</div>
