import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { WetoComponent } from '../weto/weto.component';

export interface LoginWetoInterface {
    login: string;
    selected: boolean;
}

@Component({
    selector: 'tlv-weto-login',
    templateUrl: './weto-login.component.html',
    styleUrls: ['./weto-login.component.scss']
})
export class WetoLoginComponent extends WetoComponent {
    @ViewChild('widgetWetoLogin') widget?: { nativeElement: any };
    @Output() loaded = new EventEmitter<boolean>();
    @Output() onError = new EventEmitter<boolean>();
    @Input() reservedLogin: string;
    @Input() isCustomLogin: string;

    public endToLoad = new Subject<boolean>();
    public formValid = new Subject<boolean>();
    private submit$ = new ReplaySubject<LoginWetoInterface>(1);

    public render(): void {
        this.widget?.nativeElement.render(
            {
                hiddenController: true,
                typeVente: 'VA',
                api: {
                    baseUrl: this.configService.baseConfig.resourceUrl,
                    token: this.oauth2Service.access_token ?? null,
                    xSource: 'portailvente_tlv'
                }
            },
            this.eligibilityService?.currentCart?.id,
            3,
            this.eligibilityService.getCustomerService().customer.firstname,
            this.eligibilityService.getCustomerService().customer.lastname,
            [],
            this.reservedLogin,
            this.isCustomLogin
        );

        this._ngZone.runOutsideAngular(() => {
            this.widget?.nativeElement.onEvent(this.onEvent.bind(this));
        });
    }

    public submit(): Observable<LoginWetoInterface> {
        this.submit$ = new ReplaySubject<LoginWetoInterface>(1);
        this.widget?.nativeElement?.next();
        return this.submit$;
    }

    public onEvent(e: any): void {
        this._ngZone.run(() => {

            if (e.type === 'STEP_STATUS') {
                if (e.event.status === 'READY') {
                    this.loaded.emit(true);
                    this.endToLoad.next(true);
                }
                if (e.event.status === 'VALID') {
                    this.formValid.next(true);
                }
                if (e.event.status === 'INVALID') {
                    this.formValid.next(false);
                }
            }

            if (e.event?.type === 'TECHNICAL') {
                this.onError.emit(true);
                this.toastService.error(
                    'Erreur: Un problème technique sur la réservation LOGIN est survenu, merci de réessayer',
                    {duration: 3000}
                );
            }
            if (e.event?.type === 'FUNCTIONAL') {
                this.onError.emit(true);
                this.toastService.error('Erreur: ' + e.event?.description, {duration: 3000});
            }

            if (e.type === 'END') {
                this.submit$.next({
                    login: e.event.result.login,
                    selected: e.event.result.selected
                });
                this.submit$.complete();
            }

        });
    }

}
