<section [ngClass]="isLoading && 'is-loading'">
    <div class="box is-flat is-borderless is-radiusless">
        <div class="box-content has-text-centered">
            <tlv-words-suggestion limiter="@" [close]="isSubmitted" [source]="searchForm.get('searchItem')">
            </tlv-words-suggestion>
            <div class="is-flex is-centered">
                <div id="search-form" class="is-11 is-paddingless column">
                    <form [formGroup]="searchForm" (submit)="onSearch()" [ngClass]="{'is-vcentered': !showIdProspectInput && (!searchForm.get('searchItem').errors?.badEmail)}">
                        <div class="columns is-centered is-vcentered">
                            <div class="column is-5">
                                <div class="columns" *ngIf="showIdProspectInput">
                                    <div class="column is-narrow">
                                        <span
                                            class="icon is-circled is-small has-background-grey-light has-text-weight-bold">1</span>
                                    </div>
                                    <div class="column is-narrow">
                                        <span class="is-size-5">Adresse email ou MSISDN ou BBOX</span>
                                    </div>
                                </div>
                                <input data-cy="input-search" tlvFormClass
                                    (paste)="onPaste($event)"
                                    (keyup)="onKeyUp($event)"
                                    [tlvFormClassErrorControl]="searchForm.get('searchItem')" class="input"
                                    formControlName="searchItem" id="email" maxlength="100"
                                    placeholder="Veuillez saisir l'adresse email ou MSISDN ou BBOX" type="text">
                                <ul *ngIf="searchForm.get('searchItem').invalid && searchForm.get('searchItem').touched"
                                    class="help is-danger">
                                    <li *ngIf="searchForm.get('searchItem').errors?.badEmail" data-cy="bad-email">
                                        {{searchForm.get('searchItem').errors?.badEmail}}</li>
                                </ul>
                            </div>
                        </div>
                        <div class="columns is-centered is-vcentered">
                            <div *ngIf="showIdProspectInput" class="column is-5">
                                <div class="columns">
                                    <div class="column is-narrow">
                                        <span
                                            class="icon is-circled is-small has-background-grey-light has-text-weight-bold">2</span>
                                    </div>
                                    <div class="column is-narrow">
                                        <span class="is-size-5">Id PRM* du prospect</span>
                                    </div>
                                </div>
                                <input data-cy="input-id-prm"
                                    [ngClass]="{'is-success': searchForm.get('idPrm')?.value?.length >= 12 && searchForm.get('idPrm')?.value?.length <= 13}"
                                    class="input" formControlName="idPrm" maxlength="13"
                                    placeholder="Veuillez saisir l'id PRM du prospect" type="text" InputOnlyNumber>
                                <p class="help has-text-left">
                                    * Un IDPRM doit être compris entre 12 et 13 caractères numériques
                                </p>
                            </div>
                        </div>
                        <div class="columns is-multiline is-centered">
                            <div class="column is-12 has-text-grey" *ngIf="searchForm.valid && !isCompleted">
                                <p data-cy="desc-client">Vous allez lancer une recherche sur <span *ngIf="!searchForm.get('searchItem')?.value.includes('@')">le
                                        numéro de téléphone </span><span *ngIf="searchForm.get('searchItem')?.value.includes('@')">l'adresse email </span>
                                    <span *ngIf="searchForm.get('searchItem').valid" class="has-text-weight-semibold"> {{
                                        searchForm.get('searchItem').value }}</span></p>
                            </div>
                            <div class="column is-narrow" [ngClass]="{'btn-prospect': showIdProspectInput}">
                                <br />
                                <button data-cy="btn-search" type="submit" class="button"
                                    [disabled]="searchForm.invalid || isLoading || isCompleted || searchForm.disabled"
                                    [ngClass]="{
                                        'is-primary': searchForm.valid && !isCompleted,
                                        'is-danger': searchForm.invalid || isLoading || isCompleted,
                                        'is-tertiary': searchForm.disabled
                                    }">Rechercher</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="columns">
                <div class="column is-12">
                    <tlv-partial-scoring />
                </div>
            </div>
            <div class="columns is-8 is-marginless is-centered is-multiline">
                <div class="column is-12 has-text-centered"
                    *ngIf="(searchObject?.infoMessage || searchObject?.descriptionError) && isCompleted">
                    <div class="is-inline-block">
                        <div class="notification is-small has-body"
                            [class]="searchObject?.isError ? 'is-danger' : 'is-success'">
                            <div class="icon">
                                <i
                                    [class]="searchObject?.isError ? 'tri-exclamation-circle' : 'tri-check-circle'"></i>
                            </div>
                            <div class="body">
                                <h5 class="title" data-cy="msg-error">{{ searchObject.infoMessage || searchObject.descriptionError }}</h5>
                            </div>
                        </div>
                    </div>
                    <br>
                    <button [class.is-loading]="isLoading" [disabled]="isSearchLocked || isLoading" *ngIf="searchObject.isError" (click)="continueProspect()"
                        data-cy="continue-prospect" type="button" class="button is-secondary">Continuer en tant que
                        prospect</button>
                </div>
            </div>
            <tlv-customer-info *ngIf="isCompleted && searchObject.isError !== true" [customer]="customer" [searchObject]="searchObject" (savedEvent)="onClose($event)" />
            <tlv-proposals data-cy="proposal-block" *ngIf="isCompleted && customer" [customer]="customer" (savedEvent)="onClose($event)" />
        </div>
    </div>
</section>
