import { Injectable } from '@angular/core';
import { CallRecordRepository } from '@repositories/call-record.repository';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class CallRecordService {

    constructor(private callRecordRepository: CallRecordRepository) {}

    public start(): void{
        this.callRecordRepository.start()
            .pipe(catchError(()=>of(null)))
            .subscribe();
    }

    public resume(): void{
        this.callRecordRepository.resume()
            .pipe(catchError(()=>of(null)))
            .subscribe();
    }

    public pause(): Observable<void>{
        return this.callRecordRepository.pause()
            .pipe(catchError(()=>of(null)));
    }

}
