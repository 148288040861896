<ng-template #loading>
    <tlv-loader loadingText="Chargement de l'équipement en cours..."></tlv-loader>
</ng-template>
<ng-container *ngIf="!isLoading; else loading;">
    <div *ngIf="product" class="has-text-centered has-text-left-desktop">
        <p class="has-text-tertiary is-size-1-mobile is-size-2-tablet has-text-weight-semibold">
            <span class="is-capitalized" bytelTranslate="panier.box_fai.mon_equipement">Mon équipement</span>
        </p>
        <div class="columns is-marginless is-vcentered is-desktop">
           <div class="column is-narrow">
                <picture>
                    <img tlvImageLoading [btlSrc]="productAssociated?.image || product.image" [btlSrcSet]="productImages"
                    [btlSize]="'200w,400w'"
                            alt={{product.data.title}} class="fai-image">
                </picture>
           </div>
           <div class="column">
               <p class="is-size-5">{{ productAssociated?.data?.description || (plan.equipment | equipmentLabel) }}</p>
           </div>
        </div>
        <div *ngIf="product.prices.final" class="prices-block">
            <tlv-price [hasPeriod]="true" [price]="product.prices.final" class="is-margin" size-2></tlv-price>
            <div *ngIf="activation">
                +{{activation.data.price}}<span bytelTranslate="panier.box_fai.frais.mise_en_service">€ de frais de mise en service</span>
            </div>
            <div *ngIf="installation">
                +{{installation.data.price}}<span bytelTranslate="panier.box_fai.frais.mise_a_disposition">€ de frais de mise à disposition</span>
            </div>
        </div>
    </div>
</ng-container>



