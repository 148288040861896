<form [formGroup]="productForm">
    
    <div class="wording-promo">
        <p *ngIf="!isOfferPartner" [class.is-invisible]="!product.data.wording_promo" class="has-text-info has-text-weight-semibold is-marginless"> {{ product.data.wording_promo || 'pas de libellé promo' }}</p>
    </div>
    <div class="is-block">
        <span *ngIf="product.data.wording_promo && !isOfferPartner">puis &nbsp;</span>
        <ng-container *ngIf="product.data.price > product.prices.final else showBasicPrice">
            <tlv-price *ngIf="product.promotions?.automatic?.length" [isDiscountPrice]="true" [price]="product.data.price" class="is-margin" size-3></tlv-price>
            <tlv-price [hasPeriod]="hasPeriod" [price]="product.prices.final" size-3></tlv-price>
        </ng-container>
        <ng-template #showBasicPrice>
            <tlv-price [hasPeriod]="hasPeriod" [price]="product.prices.final" class="is-margin" size-3></tlv-price>
        </ng-template>
    </div>
    <div class="wording-promo promo-bottom">
        <p *ngIf="isOfferPartner" [class.is-invisible]="!product.data.wording_promo" class="has-text-info has-text-weight-semibold"> {{ product.data.wording_promo || 'pas de libellé promo' }}</p>
    </div>

    <p [ngClass]="{'is-invisible': !product.data.ecotax}" class="is-size-8 ecotax" bytelTranslate="panier.recapitulatif.produits.element.ecotax.dont">dont
        <tlv-price [isMainPrice]="false" [price]="product?.data?.ecotax || 0" size-5></tlv-price>
        <span bytelTranslate="panier.recapitulatif.produits.element.ecotax.label">d'éco-participation</span>
    </p>
    <div *ngIf="product.data.max_qty > 1" class="columns is-centered">
        <div class="column is-narrow">
            <div class="field">
                <div class="control">
                    <div class="select is-tertiary">
                        <select (change)="updateProduct()"
                                formControlName="qty">
                            <option [data-cy]="'qty-'+product.data.gencode+'-0'" value="0">0</option>
                            <option [data-cy]="'qty-'+product.data.gencode+'-'+(i+1)" *ngFor="let qty of productMaxQty; let i = index" [value]="i + 1">
                                {{i + 1}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="buttons is-centered">
        <ng-container *ngIf="product.data.max_qty === 1">
            <ng-container *ngIf="!qty">
                <button class="button is-primary" (click)="addProduct()" [data-cy]="'item-btn-add-'+product.gencode">
                    <span bytelTranslate="panier.recapitulatif.produits.element.actions.ajouter">Ajouter</span>
                </button>
            </ng-container>
            <ng-container *ngIf="qty">
                <button class="is-tertiary button" (click)="removeProduct()" [data-cy]="'item-btn-remove-'+product.gencode">
                    <span bytelTranslate="panier.recapitulatif.produits.element.actions.supprimer">Supprimer</span>
                </button>
            </ng-container>
        </ng-container>
    </div>
</form>
