import { Component, EventEmitter, Input, Output, ElementRef, OnDestroy, AfterViewInit } from '@angular/core';
import { NOTIFICATIONS_TYPES_CLASS, NOTIFICATIONS_TYPES_LABEL } from 'src/app/constants/notifications';
import { NotificationModel } from '@models/notification.model';

@Component({
    selector: 'tlv-notifications-item',
    templateUrl: './notifications-item.component.html',
    styleUrls: ['./notifications-item.component.scss']
})
export class NotificationsItemComponent implements AfterViewInit, OnDestroy {

    @Input() public notification: NotificationModel;
    @Output() openNotifImageModal: EventEmitter<any> = new EventEmitter();
    @Output() onNotificationRead: EventEmitter<string> = new EventEmitter();
    public removeEventListener: () => void;
    public anchors;

    public notificationTypesClassMap: typeof NOTIFICATIONS_TYPES_CLASS = NOTIFICATIONS_TYPES_CLASS;
    public notificationTypesLabelMap: typeof NOTIFICATIONS_TYPES_LABEL = NOTIFICATIONS_TYPES_LABEL;

    public showDescription: boolean;
    public showModalNotificationsImages: boolean = false;

    constructor(private elementRef: ElementRef) {}

    public toggleDescription(): void {
        this.showDescription = !this.showDescription;
        this.onNotificationRead.emit(this.notification.id);
    }

    public ngAfterViewInit(): void {
        this.anchors = this.elementRef.nativeElement.querySelectorAll('img');
        this.anchors.forEach((anchor: HTMLAnchorElement) => {
            anchor.addEventListener('click', this.handleAnchorClick);
        });
    }

    public ngOnDestroy(): void {
        this.anchors.forEach((anchor: HTMLAnchorElement) => {
            anchor.removeEventListener('click', this.handleAnchorClick);
        });
    }

    public handleAnchorClick = (event: Event): void => {
        event.preventDefault();
        const anchor = event.target as HTMLImageElement;
        this.openNotifImageModal.emit({
            title: this.notification.title,
            img: anchor.src
        });
    };
}

