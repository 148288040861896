<div class="columns is-marginless is-multiline">
    <div class="column">
        <p class="title is-3 has-text-centered">
            Incompatibilité SIM/eSIM
        </p>
    </div>
    <div class="column is-12">
        <div class="box is-flat is-borderless">
            <div class="box-content">
                <div class="columns is-multiline">
                    <div class="column">
                        <div class="columns is-vcentered">
                            <div class="column is-narrow">
                                <div class="icon is-medium">
                                    <i class="tri-esim"></i>
                                </div>
                            </div>
                            <div class="column">
                                <div class="is-size-5">
                                    <span>Le téléphone et la SIM/eSIM ne sont pas compatibles, voulez-vous continuer ?</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="column is-12">
                        <div class="buttons is-centered">
                            <button (click)="close()" [class.is-loading]="isLoading" class="button is-info is-outlined">Annuler</button>
                            <button (click)="close(true)" [class.is-loading]="isLoading" class="button is-primary">{{isLoading ? 'Traitement en cours...' : 'Continuer'}}</button>
                        </div>  
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>