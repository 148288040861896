<div class="box" [data-cy]="'basic-crosssell-item-'+product.gencode">
    <p><a [data-cy]="'basic-crosssell-item-remove-btn'+data.gencode" *ngIf="!isAutoAdd" (click)="removeItem()" class="link is-pulled-right">Supprimer</a></p>
    <div class="is-flex is-centered is-vcentered is-vertical has-text-centered">
        <div class="column is-narrow">
            <picture [ngClass]="[type+'-visual']">
                <img tlvImageLoading [btlSrc]="product.image" [btlSrcSet]="productImages" alt="{{product.name}}" class="product-item-image">
            </picture>
        </div>
        <div class="column">
            <p [data-cy]="'basic-crosssell-item-name-'+data.gencode" class="has-text-weight-semibold is-marginless is-size-4">{{product.name}}</p>
            <p [data-cy]="'basic-crosssell-item-color-'+data.gencode" *ngIf="data?.color" class="is-size-6">{{data?.color}}</p>
        </div>
        <div *ngIf="type === TYPES.option_salable && product.data.short_description" class="column is-12 has-text-centered">
            <p [data-cy]="'basic-crosssell-item-description-'+data.gencode" class="is-size-6">{{product.data?.short_description}}</p>
        </div>
        <div *ngIf="type === TYPES.accessory && product.maxQty > 1"
             class="column is-12 has-text-centered is-flex is-size-2">
            <div class="qty-btns is-flex is-unselectable" [data-cy]="'basic-crosssell-item-qty-'+data.gencode">
                <span (click)="updateItem(UPDATE_ACTION.REMOVE)" [ngClass]="{'is-disabled': currentQty < 2 }"
                      class="qty-btn is-flex is-rounded has-cursor">-</span>
                <span class="is-flex">{{currentQty}}</span>
                <span (click)="updateItem(UPDATE_ACTION.ADD)"
                      [ngClass]="{'is-disabled': currentQty >= product.maxQty }"
                      class="qty-btn is-flex is-rounded has-cursor">+</span>
            </div>
        </div>
    </div>
</div>
