<section id="address-step" data-cy="delivery">
    <div class="columns is-marginless is-multiline">
        <div class="column is-12">
            <div class="is-flex is-justified-center" *ngIf="orderRecovery" data-cy="order-recovery-delivery">
                <div class="notification is-small is-info has-body">
                    <div class="icon">
                        <i class="tri-exclamation-circle"></i>
                    </div>
                    <div class="body">
                        <h5 class="title">Informations sur l'adresse de la commande préceédente</h5>
                        <p><span class="has-text-weight-bold">Facturation :</span> {{recoveredBillingAddress}}</p>
                        <p><span class="has-text-weight-bold">Livraison :</span> {{recoveredShippingAddress}}</p>
                        <p><span class="has-text-weight-bold">Type de livraison choisie :</span> {{orderRecovery.cart.delivery?.deliveryInfos.libelle}}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="column">
            <div class="box">
                <div class="columns is-marginless">
                    <div class="column is-flex is-vcentered is-paddingless">
                        <span class="icon is-medium has-text-info">
                            <i class="tri-marker"></i>
                        </span>
                        <span class="is-size-3-tablet is-size-4 is-flex is-vcentered" bytelTranslate="panier.livraison.adresses">
                            Adresse de&nbsp;<span [hidden]="shouldSkipShippingAddress">livraison et de</span>&nbsp;facturation
                        </span>
                    </div>
                </div>
                <div class="is-divider is-stretched"></div>
                <div class="columns is-multiline is-marginless has-text-centered has-text-left-tablet">
                    <tlv-address [data-cy]="'billing'" [deliveryAddress]="initialBillingAddress" [wording]="{
                    add : 'Ajouter une adresse de facturation :',
                    confirm : 'Votre adresse de facturation actuelle :'
                    }" [editable]="shouldSkipShippingAddress || addressService.billingEditable" (onSubmit)="submitBilling($event)"></tlv-address>
                    <ng-container *ngIf="!shouldSkipShippingAddress">
                        <div class="column is-12" *ngIf="this.addressService.currentBillingAddress?.confirmed || this.addressService.currentBillingAddress?.valid">
                            <div class="columns is-marginless">
                                <div class="column is-narrow">
                                    <p class="is-marginless is-size-6" bytelTranslate="panier.livraison.adresse_facturation.livraison_identique.question">Souhaitez-vous utiliser cette adresse comme adresse de livraison ?</p>
                                </div>
                                <div class="column is-paddingless is-flex is-vcentered is-centered-mobile is-narrow-tablet is-12 is-offset-1-desktop">
                                    <div class="field is-horizontal is-mobile">
                                        <div class="field-body">
                                            <div class="field is-narrow">
                                                <div class="control">
                                                    <input data-cy="same-yes" [(ngModel)]="sameAddress" (ngModelChange)="onChangeSameInput($event)" type="radio" class="input is-checkradio is-hidden" [value]="true" id="same_shipping_address_yes" >
                                                    <label for="same_shipping_address_yes">Oui</label>
                                                </div>
                                            </div>
                                            <div class="field is-narrow">
                                                <div class="control">
                                                    <input data-cy="same-no" [(ngModel)]="sameAddress" (ngModelChange)="onChangeSameInput($event)" type="radio" class="input is-checkradio is-hidden" [value]="false" id="same_shipping_address_no" >
                                                    <label for="same_shipping_address_no">Non</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <tlv-address #shippingAddressComponent [data-cy]="'shipping'" *ngIf="!sameAddress" [wording]="{
                    add : 'Ajouter une adresse de livraison :',
                    confirm : 'Votre adresse de livraison actuelle :'
                    }" [editable]="true" [deliveryAddress]="initialShippingAddress" (onSubmit)="submitShipping($event)"></tlv-address>
                </div>
            </div>
        </div>
    </div>

    <div [class.columns]="shouldSkipShippingAddress" class="is-marginless is-multiline">
        <div class="column is-12">
            <tlv-esim-delivery-infos (shouldSkipShippingAddress)="shouldSkipShippingAddress = $event"></tlv-esim-delivery-infos>
        </div>
        <div class="column is-flex is-centered" *ngIf="shouldSkipShippingAddress">
            <button class="is-primary button" [disabled]="!showDeliveryMethod || isLoading"
                (click)="submit()">{{buttonText}}</button>
        </div>
    </div>

    <div class="columns is-marginless" *ngIf="!shouldSkipShippingAddress">
        <div *ngIf="deliveryLoading">Chargement ...</div>
        <div *ngIf="showDeliveryMethod && !deliveryLoading" class="column is-full">
            <tlv-delivery-methods [data-cy]="'delivery-methods'" (editAddress)="editAddress()" (submitEvent)="submit()" [address]="shippingAddress" [isLoading]="isLoading"></tlv-delivery-methods>
        </div>
    </div>
</section>
