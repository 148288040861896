import { Component, OnInit } from '@angular/core';
import { DialogRef } from '@ngneat/dialog';

@Component({
    selector: 'tlv-customer-scoring-modal',
    templateUrl: './scoring-modal.component.html',
    styleUrls: ['./scoring-modal.component.scss']
})
export class ScoringModalComponent implements OnInit {

    public firstLine = 'Votre demande de forfait mobile ne peut pas être prise en compte.';
    public secondLine = 'Nous ne pouvons vous proposer qu’une offre Bbox';

    constructor(public ref: DialogRef) {}

    public skip(): void {
        this.ref.close();
    }

    public continue(): void{
        this.ref.close('skip');
    }

    public ngOnInit(): void {
        if (this.ref.data.isFaiKo) {
            if (this.ref.data.isSimoKo) {
                this.firstLine = 'Votre commande ne peut être prise en compte.';
                this.secondLine = 'Nous vous invitons à vous rendre en Boutique Bouygues Telecom. Merci';
            } else {
                this.firstLine = 'Votre demande d’offre Bbox ne peut pas être prise en compte.';
                this.secondLine = 'Nous ne pouvons vous proposer qu’un forfait mobile.';
            }
        }
    }
}

