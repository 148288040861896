<div data-cy="payment-failure" class="columns is-centered is-multiline" [class.is-loading]="isLoading">

    <div class="column">
        <section class="hero is-instit">
            <div class="hero-body">
                <div class="container">
                    <div class="columns is-multiline is-centered">
                        <div class="column is-12">
                            <p class="suptitle has-text-centered">Rattrapage de commande</p>
                            <h1 class="title has-text-centered">
                                <span class="icon-and-text">
                                    <span class="icon is-huge has-text-white">
                                        <i class="tri-times-circle-r"></i>
                                    </span>
                                    <span *ngIf="order">Votre paiement a eu un problème pour la cause suivante:</span>
                                    <span *ngIf="!order">Aucune comande trouvée, merci de terminer la session</span>
                                </span>
                                <span class="is-block is-size-4" data-cy="payment-failure-feedback">
                                    <span class="icon-and-text">
                                        <span class="icon is-huge has-text-white is-hidden">
                                            <i class="tri-check-circle-s"></i>
                                        </span>
                                        - {{errorMessage ? errorMessage : 'Commande non trouvée'}}
                                    </span>
                                </span>
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>

    <div class="column is-5 has-text-centered" *ngIf="actionError">
        <div class="has-body is-small is-danger notification is-marginless">
            <div class="icon">
                <i class="tri-exclamation-circle"></i>
            </div>
            <div class="body">
                <h5 class="title">{{actionError}}</h5>
            </div>
        </div>
    </div>

    <div class="column is-12 has-text-centered">
        <div class="buttons">
            <button data-cy="payment-failure-btn-end-call" [disabled]="isPerformingAction" class="is-primary button" (click)="endCall()">Terminer session</button>
            <button [disabled]="isPerformingAction" *ngIf="paymentActions?.transformToHybrid" class="is-primary button" (click)="convertOrderToHybrid()">Transformation de commande en hybride</button>
            <button [disabled]="isPerformingAction" *ngIf="paymentActions?.paymentRetrival" class="is-primary button" (click)="retryPayment()">Nouvelle tentative de paiement</button>
        </div>
    </div>
</div>
<form ngNoForm
    [method]="paymentData.method"
    [action]="paymentData.url"
    #paymentDataFormElement>

    <ng-container *ngFor="let input of paymentData.inputs">
        <input type="hidden" [name]="input.name" [value]="input.value">
    </ng-container>
</form>