import { Injectable } from '@angular/core';
import { SettingsModel } from '@models/settings';
import { SettingsRepository } from '@repositories/settings.repository';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SettingsService {

    private readonly apiUrl = 'portailvente/ecommerce/telesales';

    constructor(protected settingsRepository: SettingsRepository) { }

    public getEnvironmentsEph(): Observable<SettingsModel> {
        return this.settingsRepository.getEnvironmentsEph();
    }
}
