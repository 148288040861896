import { Component } from '@angular/core';
import { DialogRef } from '@ngneat/dialog';

@Component({
    selector: 'tlv-appointment-cancellation-warning-modal',
    templateUrl: './appointment-cancellation-warning-modal.component.html',
    styleUrls: ['./appointment-cancellation-warning-modal.component.scss']
})
export class AppointmentCancellationWarningModalComponent {
    constructor(public ref: DialogRef){}
}

