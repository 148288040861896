<div data-cy="modal-exclusive-pro-offer" class="columns is-multiline is-centered has-text-centered is-marginless">
    <div class="column is-12">
        <span class="icon is-large"><i class="tri-user-tie"></i></span>
    </div>
    <div class="column is-12">
        <p class="is-size-4 has-text-weight-semibold">Un numéro de SIREN est requis</p>
    </div>
    <div class="column is-12">
        <p class="is-size-6">Si votre client est professionnel, demandez lui les identifiants de son compte PRO</p>
    </div>
    <div class="column is-12">
        <div class="buttons is-centered">
            <button data-cy="modal-exclusive-pro-offer-endCall" class="button is-primary" (click)="endCall()">Terminer session</button>
        </div>
    </div>
    <div class="column is-12">
        <hr class="is-divider" data-content="Ou">
    </div>
    <div class="column is-12">
        <p>Revenez à la page des offres et optez pour une offre GP</p>
    </div>
    <div class="column is-12">
        <div class="buttons is-centered">
            <button data-cy="modal-exclusive-pro-offer-gotoOffers" class="button is-info" (click)="gotoOffers()">Retour aux offres</button>
        </div>
    </div>
</div>