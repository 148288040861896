<div class="columns is-centered" *ngIf="!accessories.length && !hasLoaded">
    <div class="column is-narrow is-size-3">
        <tlv-loader iconSize="medium" isAnimated="true" loadingText="Chargement des accessoires..."></tlv-loader>
    </div>
</div>
<div data-cy="essential-accessory-block" *ngIf="essentialAccessories.length">
    <div class="columns is-fullwidth is-marginless">
        <div class="column is-6 is-flex">
            <span class="is-size-3 has-text-weight-semibold">Non inclus dans le coffret</span>
        </div>
    </div>
    <form>
        <div class="columns is-multiline is-marginless">
            <ng-container  *ngFor="let product of essentialAccessories | slice: 0:essentialLength">
                <div class="column is-full-mobile is-one-third-tablet is-fullwidth-mobile">
                    <tlv-accessory-block [product]="product"></tlv-accessory-block>
                </div>
            </ng-container>
            <div class="field column is-12" *ngIf="essentialAccessories.length > essentialDefaultMaxLength">
                <div class="has-text-centered">
                    <a (click)="showMoreEssential()" *ngIf="essentialLength !== essentialAccessories.length" data-cy="essential-accessory-more-btn"
                        class="link has-text-tertiary is-size-7">Voir plus d'accessoires</a>
                    <a (click)="showLessEssential()" *ngIf="essentialLength === essentialAccessories.length" data-cy="essential-accessory-less-btn"
                        class="link has-text-tertiary is-size-7">Voir moins d'accessoires</a>
                </div>
            </div>
        </div>
    </form>
</div>
<div data-cy="accessory-block" *ngIf="accessories.length">
    <div data-cy="order-recovery-accessories" class="notification is-small is-info has-body" *ngIf="orderRecovery && orderRecoveryAccList.length">
        <div class="icon">
            <i class="tri-exclamation-circle"></i>
        </div>
        <div class="body">
            <h5 class="title">Accessoires issus de la commande précedente</h5>
            <p class="has-text-weight-semibold">Rappel: ci dessous nous n'avons affiché que les accessoires qui étaient disponibles dans la commande précédente.</p>
            <ul>
                <li *ngFor="let acc of orderRecoveryAccList">- {{acc}}</li>
            </ul>
        </div>
    </div>
    <div class="columns is-fullwidth is-marginless">
        <div class="column is-6 is-flex">
            <span class="is-size-3 has-text-weight-semibold">Je choisis mes accessoires</span>
        </div>
    </div>
    <form>
        <div class="columns is-multiline is-marginless">
            <div class="column is-12">
                <div class="field product-search">
                    <div class="control has-dynamic-placeholder has-icons-right">
                        <input [(ngModel)]="searchText" class="input is-focused" name="search" placeholder="Rechercher"
                               type="text" data-cy="accessory-search"/>
                        <label bytelTranslate="panier.recapitulatif.produits.rechercher">Rechercher</label>
                        <span class="icon is-small">
                            <i class="tri-search"></i>
                        </span>
                    </div>
                </div>
            </div>
            <ng-container  *ngFor="let product of accessories | searchBy: searchText : 'name' | slice: 0:length">
                <div class="column is-full-mobile is-one-third-tablet is-fullwidth-mobile">
                    <tlv-accessory-block [product]="product"></tlv-accessory-block>
                </div>
            </ng-container>
            <div class="field column is-12" *ngIf="accessories.length > defaultMaxLength">
                <div class="has-text-centered">
                    <a (click)="showMore()" *ngIf="length !== accessories.length" data-cy="accessory-more-btn"
                        class="link has-text-tertiary is-size-7">Voir plus d'accessoires</a>
                    <a (click)="showLess()" *ngIf="length === accessories.length" data-cy="accessory-less-btn"
                        class="link has-text-tertiary is-size-7">Voir moins d'accessoires</a>
                </div>
            </div>
        </div>
    </form>
</div>
