import { CalendarDateFormatter, DateFormatterParams } from 'angular-calendar';
import { Injectable } from '@angular/core';
import { fr } from 'date-fns/locale';
import { format, parseISO } from 'date-fns';

@Injectable()
export class CalendarProvider extends CalendarDateFormatter {
    // you can override any of the methods defined in the parent class

    public monthViewColumnHeader({ date }: DateFormatterParams): string {
        return format(parseISO(date.toJSON()), 'EEE', { locale: fr });
    }

    public monthViewTitle({ date }: DateFormatterParams): string {
        return format(parseISO(date.toJSON()), 'MMM y', { locale: fr });
    }

    public weekViewColumnHeader({ date }: DateFormatterParams): string {
        return format(parseISO(date.toJSON()), 'EEE', { locale: fr });
    }

    public dayViewHour({ date }: DateFormatterParams): string {
        return format(parseISO(date.toJSON()), 'HH:mm', { locale: fr });
    }
}
