import { Component, OnDestroy, OnInit } from '@angular/core';
import { DialogRef } from '@ngneat/dialog';
import { OrderService } from '@services/order.service';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'tlv-summary-order',
    templateUrl: './summary-order-modal.html',
    styleUrls: ['./summary-order-modal.component.scss']
})
export class SummaryOrderModalComponent implements OnInit, OnDestroy {

    public isLoading: boolean = true;
    public summaryOrderModalData: {cartId: number; paymentType: string; isPaymentFmsDiffered?: boolean};
    public orderSummaryMessage: string;

    public error: boolean = false;
    private _subscriptions: Subscription[] = [];
    constructor(public dialogRef: DialogRef, private _orderService: OrderService) {
        this.summaryOrderModalData = dialogRef.data;
    }

    public ngOnInit(): void {
        if (this.summaryOrderModalData.cartId && this.summaryOrderModalData.paymentType) {
            this._subscriptions.push(this._orderService
                .getOrderSummary(
                    this.summaryOrderModalData.cartId,
                    this.summaryOrderModalData.paymentType,
                    this.summaryOrderModalData.isPaymentFmsDiffered)
                .pipe(finalize(() => {
                    this.isLoading = false;
                }))
                .subscribe({next: (summary) => {
                    this.error = false;
                    this.orderSummaryMessage = summary?.message || 'Aucune donnée disponible';
                }, error: () => this.error = true}));
        }
    }

    public createOrder(): void {
        this.dialogRef.close({ ACTION: 'CREATE_ORDER' });
    }

    public ngOnDestroy(): void {
        this._subscriptions.forEach((subscription) => {
            subscription.unsubscribe();
        });
    }
}

