import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import {
    ORDER_STATUS,
    PRINCIPAL_OFFER_TYPE,
    CHANNEL,
    ACTOR_CREATOR_LABEL,
    CHANNEL_LABEL
} from '../../../constants/order';
import { OrderModel } from '@models/order/order.model';
import { ProposalModel } from '@models/proposal.model';
import { CartTeleSalesService } from '@services/checkout/cart-telesales.service';
import { CatalogService } from '@services/catalog.service';
import { map, mergeMap } from 'rxjs/operators';
import { Sim } from '@bytel/bytel-sales';
import { AbandonedCartModel } from '@models/abandoned-cart.model';

@Component({
    selector: 'tlv-cart-list',
    templateUrl: './cart-list.component.html',
    styleUrls: ['./cart-list.component.scss']
})
export class CartListComponent implements OnChanges {

    @Input()
    public recoveryCarts: (OrderModel|ProposalModel)[];
    @Input()
    public isLoading: boolean;
    @Input()
    public isClientLockByScoring: boolean;
    @Output()
    public savedEvent: EventEmitter<string> = new EventEmitter<string>();

    public selectedRecoveryCarts: OrderModel;
    public ORDER_STATUS: typeof ORDER_STATUS = ORDER_STATUS;
    public PRINCIPAL_OFFER_TYPE: typeof PRINCIPAL_OFFER_TYPE = PRINCIPAL_OFFER_TYPE;
    public ACTOR_CREATOR_LABEL: typeof ACTOR_CREATOR_LABEL = ACTOR_CREATOR_LABEL;
    public CHANNEL_LABEL: typeof CHANNEL_LABEL = CHANNEL_LABEL;
    public CHANNEL: typeof CHANNEL = CHANNEL;
    public isProposal: boolean = false;
    public isOrder: boolean = false;
    public isCart: boolean = false;
    public offerTypes: string[];

    constructor(
        private cartTeleSalesService: CartTeleSalesService,
        private catalogService: CatalogService,
    ) {

    }

    public ngOnChanges(): void {
        this.isProposal = this.recoveryCarts[0] instanceof ProposalModel;
        this.isCart = this.recoveryCarts[0] instanceof AbandonedCartModel;
        this.isOrder = !this.isProposal && !this.isCart;
        this.loadOfferTypes();
    }

    public toggleProduct(recoveryCart: OrderModel): void {
        if (this.selectedRecoveryCarts?.id === recoveryCart.id) {
            this.selectedRecoveryCarts = null;
        } else {
            this.selectedRecoveryCarts = recoveryCart;
        }
    }

    public loadRecoveryCart(recoveryCart: OrderModel): void{
        this.cartTeleSalesService.clear();
        if (recoveryCart.mainOfferType === PRINCIPAL_OFFER_TYPE.ACQUISITION_FIXE ||
            recoveryCart.mainOfferType === PRINCIPAL_OFFER_TYPE.ACQUISITION_FIXE_TECHNO_MOBILE ||
            recoveryCart.mainOfferType === PRINCIPAL_OFFER_TYPE.MIXED) {
            this.savedEvent.emit('redirect.fai');
        } else {
            this.catalogService.getProductsByGencodes(recoveryCart.products.map((p) => p.gencode))
                .pipe(
                    map(products => products.filter(p => !(p instanceof Sim))),
                    mergeMap(products => this.cartTeleSalesService.addProducts(products))
                ).subscribe(() => {
                    this.savedEvent.emit('redirect.panier');
                });
        }
    }

    public loadOfferTypes(): void {
        this.offerTypes = [];
        this.recoveryCarts.forEach(recoveryCart => {
            this.offerTypes.push(recoveryCart.mainOfferType);
        });
    }
}
