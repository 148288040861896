import { Pipe, PipeTransform } from '@angular/core';
import { Fai } from '@bytel/bytel-sales';
import { CLASSIFICATION } from '../constants/fai';

@Pipe({
    name: 'playsLabel'
})

export class PlaysLabelPipe implements PipeTransform {

    public transform(subscription: Fai): string {

        let plays: string = 'Internet';

        if (subscription.play === '3P' || subscription.data?.classification === CLASSIFICATION.MEDIUM_TV_XGBOX) {
            plays += ' / TV';
        }

        if (subscription.play === '2P' || subscription.play === '3P') {
            plays += ' / Téléphonie';
        }

        if ([
            Fai.RANGES.BBOX_SMART_TV,
            Fai.RANGES.BBOX_SMART_TV_LARGE,
            Fai.RANGES.SL_LARGE,
            Fai.RANGES.SL_LARGE_SMART_TV
        ].includes(subscription.play)) {
            plays = 'Internet / TV / Téléphonie + Smart TV';
        }

        return plays;
    }
}
