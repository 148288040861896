<div class="columns is-center is-marginless has-text-centered" data-cy="click-and-collect-modal">
    <div class="column is-12">
        <span class="icon is-huge is-circled has-background-grey-light has-text-secondary truck-image"><i
                class="tri-truck"></i></span>
        <p class="title is-size-4" bytelTranslate="panier.livraison.methodes_livraison.modal.titre">Comprendre les modes
            de livraison</p>
        <p class="has-text-weight-semibold" bytelTranslate="panier.livraison.methodes_livraison.modal.question">Vous
            vous demandez quel mode de livraison choisir ?</p>
        <p class="has-text-weight-semibold">{{ selectedMethod && selectedMethod.libelle}}</p>
        <section class="accordions">
            <article class="accordion" *ngFor="let availableShippingMethod of availableShippingMethods"
                     (click)="toggleAccordion(availableShippingMethod)"
                     [ngClass]="selectedMethod && (selectedMethod.gencode === availableShippingMethod.gencode) && 'is-active'">
                <div [data-cy]="'shipping-method-'+availableShippingMethod.gencode" class="accordion-header is-uppercase">
                    <p>{{ availableShippingMethod.libelle }}</p>
                    <button class="toggle" aria-label="toggle"></button>
                </div>
                <div class="accordion-body has-text-left">
                    <div class="accordion-content"
                         *ngIf="availableShippingMethod.gencode === 'CLICK_COLLECT'">
                        <p class="has-text-weight-semibold"
                           bytelTranslate="panier.livraison.methodes_livraison.modal.click_collect.disponibilite">
                            Disponible dès 2h* -
                            <tlv-price *ngIf="availableShippingMethod.price > 0" [price]="availableShippingMethod.price"
                                       size-5></tlv-price>
                            <span *ngIf="availableShippingMethod.price <= 0"
                                  bytelTranslate="panier.livraison.methodes_livraison.modal.click_collect.gratuit">Gratuit</span>
                        </p>
                        <p bytelTranslate="panier.livraison.methodes_livraison.modal.click_collect.explications.partie_1">
                            Commandez votre produit en ligne et retirez-le gratuitement dans les 2 heures** dans la
                            boutique de votre choix.</p>
                        <p bytelTranslate="panier.livraison.methodes_livraison.modal.click_collect.explications.partie_2">
                            Vous serez notifié par SMS et email*** de la mise à disposition de votre commande.</p>
                        <p bytelTranslate="panier.livraison.methodes_livraison.modal.click_collect.explications.partie_3">
                            A l'issue de cette notification, le mobile est à retirer sous 2 jours (hors dimanche).</p>
                        <p bytelTranslate="panier.livraison.methodes_livraison.modal.click_collect.explications.partie_4">
                            Important : Seule la personne dont le nom est mentionné sur le bon de commande ou la facture
                            sera autorisée à retirer la commande, sur présentation de sa pièce d'identité.</p>

                        <p bytelTranslate="panier.livraison.methodes_livraison.modal.click_collect.explications.partie_5">
                            ** Pour toute commande passée avant 17 heures aux heures d'ouverture de la boutique du lundi
                            au samedi et avant 15h30 en cas d'ouverture exceptionnelle des boutiques le dimanche ou les
                            jours fériés.</p>
                        <p bytelTranslate="panier.livraison.methodes_livraison.modal.click_collect.explications.partie_6">
                            *** L'envoi de ces communications peut prendre jusqu'à 24 heures, délai maximum de la part
                            des équipes Bouygues Telecom pour valider votre commande.</p>
                    </div>
                    <div class="accordion-content"
                         *ngIf="availableShippingMethod.gencode === 'LIVRAISON_DIRECTE'">
                        <p class="has-text-weight-semibold"
                           bytelTranslate="panier.livraison.methodes_livraison.modal.livraison_directe.disponibilite">2
                            à 3 jours ouvrables* -
                            <tlv-price *ngIf="availableShippingMethod.price > 0" [price]="availableShippingMethod.price"
                                       size-5></tlv-price>
                            <span *ngIf="availableShippingMethod.price <= 0"
                                  bytelTranslate="panier.livraison.methodes_livraison.modal.livraison_directe.gratuit">Gratuit</span>
                        </p>
                        <p bytelTranslate="panier.livraison.methodes_livraison.modal.livraison_directe.explications.partie_1">
                            Commandez votre produit et recevez-le à domicile ou à l’adresse de votre choix.</p>
                        <p><i
                                bytelTranslate="panier.livraison.methodes_livraison.modal.livraison_directe.explications.partie_2">Via
                            Colissimo</i></p>
                    </div>
                    <div class="accordion-content"
                         *ngIf="availableShippingMethod.gencode === 'CHRONOPOST'">
                        <p class="has-text-weight-semibold"
                           bytelTranslate="panier.livraison.methodes_livraison.modal.chronopost.disponibilite">1 à 2
                            jours ouvrables* -
                            <tlv-price *ngIf="availableShippingMethod.price > 0" [price]="availableShippingMethod.price"
                                       size-5></tlv-price>
                            <span *ngIf="availableShippingMethod.price <= 0"
                                  bytelTranslate="panier.livraison.methodes_livraison.modal.chronopost.gratuit">Gratuit</span>
                        </p>
                        <p bytelTranslate="panier.livraison.methodes_livraison.modal.chronopost.explications.partie_1">
                            Commandez votre produit et recevez-le à domicile ou à l’adresse de votre choix en
                            express.</p>
                        <p><i
                                bytelTranslate="panier.livraison.methodes_livraison.modal.chronopost.explications.partie_2">Via
                            Chronopost</i></p>
                    </div>
                    <div class="accordion-content"
                         *ngIf="availableShippingMethod.gencode === 'POINT_RELAIS'">
                        <p class="has-text-weight-semibold"
                           bytelTranslate="panier.livraison.methodes_livraison.modal.point_relais.disponibilite">De 3 à
                            5 jours ouvrables* -
                            <tlv-price *ngIf="availableShippingMethod.price > 0" [price]="availableShippingMethod.price"
                                       size-5></tlv-price>
                            <span *ngIf="availableShippingMethod.price <= 0"
                                  bytelTranslate="panier.livraison.methodes_livraison.modal.point_relais.gratuit">Gratuit</span>
                        </p>
                        <p bytelTranslate="panier.livraison.methodes_livraison.modal.point_relais.explications.partie_1">
                            Commandez votre produit et recevez-le dans le point Relais Colis de votre choix.</p>
                        <p bytelTranslate="panier.livraison.methodes_livraison.modal.point_relais.explications.partie_2">
                            Une fois votre colis disponible en point relais, vous disposez d’un délai de 14 jours pour
                            le récupérer.</p>
                        <p><i
                                bytelTranslate="panier.livraison.methodes_livraison.modal.point_relais.explications.partie_3">Via
                            Relais Colis</i></p>
                    </div>
                    <div class="accordion-content"
                         *ngIf="availableShippingMethod.gencode === 'POINT_RELAIS_EXPRESS'">
                        <p class="has-text-weight-semibold"
                           bytelTranslate="panier.livraison.methodes_livraison.modal.point_relais_express.disponibilite">
                            1 à 2 jours ouvrables* -
                            <tlv-price *ngIf="availableShippingMethod.price > 0" [price]="availableShippingMethod.price"
                                       size-5></tlv-price>
                            <span *ngIf="availableShippingMethod.price <= 0"
                                  bytelTranslate="panier.livraison.methodes_livraison.modal.point_relais_express.gratuit">Gratuit</span>
                        </p>
                        <p bytelTranslate="panier.livraison.methodes_livraison.modal.point_relais_express.explications.partie_1">
                            Commandez votre produit et récupérez-le dans le Point Relais Express de votre choix.</p>
                        <p bytelTranslate="panier.livraison.methodes_livraison.modal.point_relais_express.explications.partie_2">
                        <div class="store-type-item">
                            <span><span bytelSvg class="shape-icon-relais" name="shape-icon-deposit"
                                        title></span></span>
                            <p><b
                                    bytelTranslate="panier.livraison.methodes_livraison.modal.point_relais_express.explications.partie_2.relais">Relais
                                Pickup</b><span
                                    bytelTranslate="panier.livraison.methodes_livraison.modal.point_relais_express.explications.partie_2.relais.description"> qui correspondent à un relais commerçants (tabac, presse, fleuriste, alimentation…)</span>
                            </p>
                        </div>
                        <p bytelTranslate="panier.livraison.methodes_livraison.modal.point_relais_express.explications.partie_3">
                            Une fois votre colis disponible en point Relais, vous disposez d'un délai de 7 jours pour
                            le récupérer.
                        </p>
                        <p><i bytelTranslate="panier.livraison.methodes_livraison.modal.point_relais_express.explications.partie_4">Via Chronopost</i></p>
                    </div>
                    <div class="accordion-content"
                         *ngIf="availableShippingMethod.gencode === 'LIVRAISON_RDV'">
                        <p class="has-text-weight-semibold"
                           bytelTranslate="panier.livraison.methodes_livraison.modal.livraison_rdv.disponibilite">
                            Créneau de 2h au choix -
                            <tlv-price *ngIf="availableShippingMethod.price > 0" [price]="availableShippingMethod.price"
                                       size-5></tlv-price>
                            <span *ngIf="availableShippingMethod.price <= 0"
                                  bytelTranslate="panier.livraison.methodes_livraison.modal.livraison_rdv.gratuit">Gratuit</span>
                        </p>
                        <p bytelTranslate="panier.livraison.methodes_livraison.modal.livraison_rdv.explications.partie_1">
                            Commandez votre produit et choisissez le créneau qui vous convient pour vous faire livrer à l’adresse de votre choix.</p>
                        <p bytelTranslate="panier.livraison.methodes_livraison.modal.livraison_rdv.explications.partie_2">
                            Sélectionnez dans un premier temps la date souhaitée de livraison puis le créneau horaire de 2 heures qui vous convient (créneau unique de 4 heures le dimanche).</p>
                        <p bytelTranslate="panier.livraison.methodes_livraison.modal.livraison_rdv.explications.partie_3">
                            Si vous souhaitez modifier votre créneau par la suite, vous pourrez le faire directement via le lien communiqué par email par le transporteur une fois votre colis pris en charge.</p>
                        <p><i
                                bytelTranslate="panier.livraison.methodes_livraison.modal.point_relais_express.explications.partie_4">Via
                            Chronopost</i></p>
                    </div>
                    <div class="accordion-content"
                         *ngIf="availableShippingMethod.gencode === 'LA_POSTE'">
                        <div class="accordion-content">
                            <p class="has-text-weight-semibold"
                               bytelTranslate="panier.livraison.methodes_livraison.modal.la_poste.disponibilite">3 à 5
                                jours ouvrables* -
                                <tlv-price *ngIf="availableShippingMethod.price > 0"
                                           [price]="availableShippingMethod.price" size-5></tlv-price>
                                <span *ngIf="availableShippingMethod.price <= 0"
                                      bytelTranslate="panier.livraison.methodes_livraison.modal.la_poste.gratuit">Gratuit</span>
                            </p>
                            <p bytelTranslate="panier.livraison.methodes_livraison.modal.la_poste.explications.partie_1">
                                Commandez votre carte sim seule et recevez-là à domicile ou à l’adresse de votre
                                choix.</p>
                            <p><i
                                    bytelTranslate="panier.livraison.methodes_livraison.modal.la_poste.explications.partie_2">Via
                                la Poste en lettre simple</i></p>
                        </div>
                    </div>
                </div>
            </article>
        </section>
        <p class="working-days is-size-7" data-cy="delivery-opening-days"
           bytelTranslate="panier.livraison.methodes_livraison.modal.explications.jours_ouvrables">*Jours ouvrables : du
            lundi au samedi hors jours fériés</p>
    </div>
</div>


