export interface INotificationData {
    id: string;
    titre: string;
    description: string;
    creeLe?: Date;
    dateCreation: Date;
    dateDebut: Date;
    dateFin: Date;
    identifiant: string;
    miseAJourPar: string;
    estResolu: boolean;
    porteeDeLaNotification?: string[];
    priorite?: number;
    typeNotification?: string;
    statutLecture: NOTIFICATION_READ_STATUS;
}

export enum NOTIFICATION_READ_STATUS {
    LU = 'LU',
    NON_LU = 'NON_LU'
}

export enum CDCStatusList {
    TLV_ENTRANT = 'TLV_ENTRANT',
    TLV_POLE_ACTIVATION = 'TLV_POLE_ACTIVATION',
    TLV_PARTENAIRE_WEB = 'TLV_PARTENAIRE_WEB',
    TLV_SORTANT = 'TLV_SORTANT'
};
