<a class="box" [ngClass]="{'box': isCartEmpty}" (click)="goToWall()">
    <div class="has-text-centered empty-cart-item is-flex">

        <span class="icon is-main-icon" [ngClass]="textColor">
            <i [ngClass]="itemIcon"></i>
        </span>
        <p class="is-size-3 info-line">
            <span class="icon is-large has-text-grey-dark"><i class="tri-plus-circle"></i></span>
        </p>
        <p class="is-size-5 info-line">{{itemLabel}}</p>
        <a *ngIf="showCmsModalLink && !isCartEmpty" class="has-text-centered is-absolute is-bottom-2" (click)="compatibleTerminalCms($event)">Consulter les mobiles compatibles eSIM</a>
    </div>
</a>
