<div class="box" id="ticket" *ngIf="cartModel" bytelScrollTo [enableScroll]="isMobileMQ && scrollToTicket" domId="checkout-ticket" data-cy="ticket">

    <div class="box-content">
        <p class="has-text-left is-size-4 has-text-weight-bold" bytelTranslate="panier.ticket.titre">
            Mon panier
        </p>
        <div *ngIf="!isCartEmpty && !!productsSummary.daily" class="is-divider is-unboxed"></div>
        <ng-container *ngIf="isCartEmpty">
            <div class="has-text-centered is-size-6">
                <span class="icon is-huge"><i class="tri-shopping-bag"></i></span>
                <div class="notification is-small has-text-centered is-shadowless">
                    <div class="body">
                        <p class="is-size-7" bytelTranslate="panier.ticket.vide">Votre panier est vide</p>
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="!isCartEmpty">
            <div class="equipement-prices" *ngIf="!!productsSummary.daily" data-cy="ticket-daily-payment-infos">
                <div class="columns is-vcentered is-mobile">
                    <span (click)="expandDailyPrices = !expandDailyPrices"
                          class="icon-and-text has-text-left has-cursor column is-flex">
                        <span [ngClass]="{'up': !expandDailyPrices, 'down': expandDailyPrices}" class="icon is-small icon-rotate">
                            <span class="tri-arrow-down"></span>
                        </span>
                        <span class="is-size-5 has-text-weight-semibold" bytelTranslate="panier.ticket.aujourd_hui">Aujourd'hui</span>
                    </span>
                    <div class="is-narrow column has-text-right">
                        <tlv-price [price]="totals.daily.final" class="is-margin" size-4></tlv-price>
                    </div>
                </div>
                <div [ngClass]="{'is-collapsed': !expandDailyPrices}" class="price-details">
                    <!-- Price detail line -->
                    <ng-container *ngFor="let product of products | priceType: priceType.Today">
                        <div class="columns is-vcentered is-mobile" [data-cy]="'product-'+product.gencode">
                           <span class="column is-8 is-size-6 has-text-left">
                                {{product.label}}
                            </span>
                            <div class="column has-text-right">
                                <span class="has-text-weight-semibold is-size-6" *ngIf="priceWithoutCoupon(product, product.prices.base) === 0" bytelTranslate="panier.ticket.gratuit">Gratuit</span>
                                <tlv-price *ngIf="priceWithoutCoupon(product, product.prices.base) > 0" [price]="priceWithoutCoupon(product, product.prices.base)" class="is-margin" size-5></tlv-price>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngFor="let product of products | priceType: priceType.Reported">
                        <div class="columns is-vcentered is-mobile" *ngIf="product.prices.final === 0" [data-cy]="'product-'+product.gencode">
                           <span class="column is-8 is-size-6 has-text-left">
                                {{product.label}}
                            </span>
                            <div class="column has-text-right">
                                <span class="has-text-weight-semibold is-size-6" *ngIf="priceWithoutCoupon(product, product.prices.base) === 0" bytelTranslate="panier.ticket.gratuit">Gratuit</span>
                                <tlv-price *ngIf="priceWithoutCoupon(product, product.prices.base) > 0" [price]="priceWithoutCoupon(product, product.prices.base)" class="is-margin" size-5></tlv-price>
                            </div>
                        </div>
                    </ng-container>

                    <ng-container  *ngFor="let product of products | priceType: priceType.Today">
                        <div class="columns is-vcentered is-mobile" *ngIf="product.promotions.coupon" data-cy="ticket-coupon">
                            <span class="column is-8 is-size-6 has-text-left" data-cy="ticket-coupon-name">
                                Remise sur {{product.label}}
                            </span>
                            <div class="column has-text-right">
                                <tlv-price  data-cy="ticket-coupon-amount" [price]="(priceWithoutCoupon(product, product.prices.base) - product.prices.final)*-1" class="is-margin" size-5></tlv-price>
                            </div>
                        </div>
                    </ng-container>
                    <div *ngIf="refundAmount" class="notification is-small has-text-centered is-shadowless" data-cy="coupon-refund-amount">
                        <div class="body">
                            <h5 class="is-size-7"><span
                                    class="has-text-weight-semibold">{{refundAmount}}<span bytelTranslate="panier.ticket.odr.montant">€ remboursés</span></span>
                                <span bytelTranslate="panier.ticket.odr.apres_achat"> après achat</span></h5>
                        </div>
                    </div>
                    <tlv-mobile-recovery-sticky *ngIf="hasMobileRecovery" [noButtons]="noButtons"></tlv-mobile-recovery-sticky>

                </div>
            </div>
            <ng-container *ngIf="!!productsSummary.monthly">
                <div class="is-divider has-background-gery-light"></div>
                <div class="subscription-prices" data-cy="ticket-monthly-payment-infos">
                    <div class="columns is-vcentered is-mobile">
                        <div (click)="expandMonthlyPrices = !expandMonthlyPrices" class="column">
                            <span [ngClass]="{'up': !expandMonthlyPrices, 'down': expandMonthlyPrices}"
                                class="icon is-small icon-rotate">
                                <span class="tri-arrow-down"></span>
                            </span>
                            <span class="is-size-5 has-text-weight-semibold">Par mois</span>
                        </div>
                        <div class="column has-text-right">
                            <tlv-price [price]="totals.monthly.final" class="is-margin" size-4></tlv-price>
                            <div *ngIf="totals?.monthly?.duration" class="is-size-7">
                                <span>Pendant {{totals.monthly.duration}} mois</span>
                                puis <tlv-price [price]="totals.monthly.forever" class="is-margin" size-7></tlv-price> par mois
                            </div>
                        </div>
                    </div>
                    <div class="columns is-vcentered is-mobile">
                        <ng-container *ngFor="let product of products | priceType: priceType.EveryMonth">
                            <div class="column has-text-right"  *ngFor="let odr of product.promotions.automatic | odrPromotions">
                                <div class="is-size-8">
                                    <span *ngIf="odr?.proprietesSupplementaires?.etiquette">{{odr?.proprietesSupplementaires?.etiquette}}</span>
                                    <span *ngIf="!odr?.proprietesSupplementaires?.etiquette">-{{odr.amount}}/mois pendant {{odr.duration}} mois remboursés après achat</span>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    <div [ngClass]="{'is-collapsed': !expandMonthlyPrices}" class="price-details">
                        <ng-template #promolabel let-product="product">
                            <br>
                            <span class="is-block has-text-info is-size-7">{{product.data.wording_promo}}</span>
                        </ng-template>
                        <!-- Price detail line -->
                        <ng-container *ngFor="let product of products | priceType: priceType.EveryMonth">
                            <div class="columns is-vcentered is-mobile" [data-cy]="'ticket-monthly-product-'+product.gencode">
                                <span class="column is-8 is-size-6 has-text-left" *ngIf="isPro && product.proLabel">{{product.proLabel}}
                                    <ng-container *ngTemplateOutlet="promolabel; context: {product: product}"></ng-container>
                                </span>
                                <span class="column is-8 is-size-6 has-text-left" *ngIf="!(isPro && product.proLabel)">
                                   {{product?.technology ? product.name : product.label}}
                                    <ng-container *ngTemplateOutlet="promolabel; context: {product: product}"></ng-container>
                                </span>
                                <div class="column has-text-right">
                                    <tlv-price [price]="product?.prices.final" class="is-margin" size-5></tlv-price>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="!!productsSummary.reported && totals.reported.final">
                <div class="is-divider has-background-gery-light"></div>
                <div class="subscription-prices" data-cy="ticket-reported-payment-infos">
                    <div class="columns is-vcentered is-mobile">
                        <div (click)="expandMonthlyPrices = !expandMonthlyPrices"
                            class="column">
                            <span [ngClass]="{'up': !expandMonthlyPrices, 'down': expandMonthlyPrices}"
                                class="icon is-small icon-rotate">
                                <span class="tri-arrow-down"></span>
                            </span>
                            <span class="is-size-5 has-text-weight-semibold">A payer sur la 1<sup>ère</sup> facture</span>
                        </div>
                        <div class="column has-text-right">
                            <tlv-price [price]="totals.reported.final" class="is-margin" size-4></tlv-price>
                        </div>
                    </div>
                    <ng-container *ngFor="let product of products | priceType: priceType.Reported">
                        <div class="columns is-vcentered is-mobile" [data-cy]="'product-'+product.gencode">
                           <span class="column is-9 is-size-6 has-text-left">
                                {{product.label}}
                            </span>
                            <div class="column has-text-right">
                                <span class="has-text-weight-semibold is-size-6" *ngIf="priceWithoutCoupon(product, product.prices.base) === 0" bytelTranslate="panier.ticket.gratuit">Gratuit</span>
                                <tlv-price *ngIf="priceWithoutCoupon(product, product.prices.base) > 0" [price]="priceWithoutCoupon(product, product.prices.base)" class="is-margin" size-5></tlv-price>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </ng-container>

            <tlv-coupon *ngIf="!noButtons"></tlv-coupon>
            <div class="is-divider" *ngIf="!noButtons || differedPaymentProduct"></div>
            <div class="buttons is-centered" *ngIf="!noButtons">
                <tlv-button primary (click)="validate()" [loading]="isLoading" [ngClass]="{'has-actions-disabled': !isValidForm || isLoading}">
                    <span bytelTranslate="panier.ticket.valider" *ngIf="!isPreorder">Valider le panier</span>
                    <span bytelTranslate="panier.ticket.precommander" *ngIf="isPreorder">Pré-commander</span>
                </tlv-button>
            </div>
        </ng-container>

        <div *ngIf="hasMobileRecovery">
            <div class="is-divider"></div>
            <p *ngIf="hasMobileRecovery" class="is-size-7 is-italic has-text-grey" bytelTranslate="panier.ticket.reprise_mobile.condition">** Sous réserve de validation.</p>
        </div>
    </div>
</div>
