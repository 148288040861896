import { Component, OnInit, } from '@angular/core';
import { ConfigurationService } from '@common-modules';


@Component({
    selector: 'tlv-portability-container',
    templateUrl: './portability-container.component.html',
    styleUrls: ['./portability-container.component.scss']
})
export class PortabilityContainerComponent implements OnInit {

    public corbisActive: boolean = false;

    constructor(private configService: ConfigurationService) { }

    public ngOnInit(): void {
        this.corbisActive = this.configService.data_refconf?.fai?.widget?.active;
    }

}

