<div class="modal-representant-legal is-tri" data-cy="siren-invalid" data-cy="appointment-modal-skip">
    <div class="columns is-multiline is-marginless is-centered has-text-centered">
        <div class="column is-narrow">
            <span class="icon has-text-warning is-huge">
                <i class="tri-exclamation-circle"></i>
            </span>
        </div>

        <div class="column is-12">
            <div>
                <p>{{firstLine}},</p>
                <p>{{secondLine}} </p>
            </div>
        </div>

        <div class="column is-12">
            <div class="buttons is-multiline">
                <button class="button is-small is-primary"  data-cy="bypass-appointment-true" (click)="continue()">
                    <span class="icon-and-text">
                        <span>Poursuivre</span>
                      </span>
                </button>
                <button class="button is-small is-tertiary" data-cy="bypass-appointment-false" (click)="skip()">
                    <span class="icon-and-text">
                        <span>Abandonner</span>
                      </span>
                </button>
            </div>
        </div>

    </div>
</div>
