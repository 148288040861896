import { ChangeDetectorRef, Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { Insurance } from '@bytel/bytel-sales';
import { ACTIONS } from '@directives/prisme-logger.directive';
import { CartTeleSalesService } from '@services/checkout/cart-telesales.service';
import { ReplaySubject, Subscription } from 'rxjs';
import bind from '../../../../../helper/decorators/bind';

@Component({
    selector: 'tlv-insurance',
    templateUrl: './insurance.component.html',
    styleUrls: ['./insurance.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(()=> InsuranceComponent),
            multi: true
        }
    ]
})
export class InsuranceComponent implements ControlValueAccessor, OnDestroy, OnInit  {

    @Input() public insurance: Insurance;

    public LOG_ACTIONS: typeof ACTIONS = ACTIONS;
    public insuranceControlForm: FormGroup = new FormGroup({
        choiceInsurance : new FormControl<boolean | null>(null, [Validators.required]),
    });
    private _subscriptions: Subscription[] = [];
    private _onChangeSubject: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
    constructor(
        private cdr: ChangeDetectorRef,
        private cartService: CartTeleSalesService,
    ) {
        this._subscriptions.push(
            this.insuranceControlForm.statusChanges.subscribe(this._setValueFromStatus),
            this.insuranceControlForm.get('choiceInsurance').valueChanges.subscribe(this._setValueFromStatus)
        );
    }

    public writeValue(): void {
        return null;
    }

    public ngOnInit(): void {
        const insuranceInQuote = this.cartService.cartModel.getQuote()?.getProductsByType(Insurance);

        this.insuranceControlForm.setValue({
            choiceInsurance:insuranceInQuote.length > 0,
        });
    }

    public ngOnDestroy(): void {
        this._subscriptions.forEach((subscription: Subscription) => {
            subscription.unsubscribe();
        });
    }

    public removeInsurance(): void {
        this.cartService.removeProductsByGencode(this.insurance.gencode).subscribe();
        this.insuranceControlForm.get('choiceInsurance').enable({emitEvent:false});
    }

    public addInsurance(): void {
        this.cartService.addProduct(this.insurance).subscribe();
        this.insuranceControlForm.get('choiceInsurance').enable({emitEvent:true});
    }

    public registerOnChange(fn: any): void {
        this._onChangeSubject.subscribe(fn);
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    private onTouched: () => void = (): void => null;

    @bind
    private _setValueFromStatus(): void {
        this._onChangeSubject.next(this.insuranceControlForm.valid ? this.insuranceControlForm.get('choiceInsurance').value : null);
    }

}
