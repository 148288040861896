import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Fai, Option, Plan, Product, ProductFactory, QuoteModel } from '@bytel/bytel-sales';
import { DisplayOptionModel } from '@models/cart/display-option.model';
import { CartTeleSalesService } from '@services/checkout/cart-telesales.service';
import { CartService } from '@services/checkout/cart.service';
import { OptionsAvailableService } from '@services/checkout/options-available.service';
import { PromotionsService } from '@services/promotions.service';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { finalize, map, mergeMap, tap } from 'rxjs/operators';
import bind from '../../../../../../helper/decorators/bind';
import { OrderModel } from '@models/order/order.model';
import { SalesForceService } from '@services/salesforce.service';
import { OptionCategory, OptionGencode } from 'src/app/constants/options';

interface IOptionCategory {
    code: string;
    name: string;
    visible: boolean;
}
@Component({
    selector: 'tlv-options',
    templateUrl: './options.component.html',
    styleUrls: ['./options.component.scss']
})
export class OptionsComponent implements OnInit, OnDestroy {

    @Input() public quote: QuoteModel;
    @Input() public quoteIndex: number;

    public options: DisplayOptionModel[] = [];
    public loading: boolean = false;
    public optionsCategories: DisplayOptionModel[] = [];
    public mainOption: DisplayOptionModel[];
    public readonly defaultMaxLength = 3;
    public length: number = this.defaultMaxLength;
    public isCategoriesViewActive = false;
    public tabsCategories: IOptionCategory[] = [
        { code: 'all', name: 'Toutes', visible: true},
        { code: 'security', name: 'Sécurité et assistance', visible: false},
        { code: 'tv', name: 'TV', visible: false},
        { code: 'communication', name: 'Communication', visible: false},
        { code: 'big-trust', name: 'Big-trust', visible: false}
    ];
    public selectedCategory: IOptionCategory = {code: 'all', name: 'Toutes', visible: true};
    public planTypeName: string;
    public orderRecovery: OrderModel;
    public isMultiQuote: boolean = false;
    private _subscriptions: Subscription[] = [];

    constructor(private optionsAvailableService: OptionsAvailableService,
                private cartTeleSalesService: CartTeleSalesService,
                private cartService: CartService,
                private salesForceService: SalesForceService,
                private promotionsService: PromotionsService
    ) {
        this.isMultiQuote = this.cartTeleSalesService.isQuoteMixed();
    }

    public ngOnInit(): void {
        this.quote = this.quote ?? this.cartTeleSalesService.cartModel.getQuote();
        this.planTypeName = this.cartTeleSalesService.isQuoteMixed() &&
            this.quote.getProductByType(Plan).data.type_id.split('_').pop() === 'fai' ? 'fixe' : 'mobile';
        this.orderRecovery = this.salesForceService.prefilledInfo.order;
        this._subscriptions.push(
            this.optionsAvailableService.update$.subscribe(this._getAvailableOptions)
        );
        if (this.orderRecovery?.cart?.options.length) {
            this._autoAddOptions();
        }
    }

    public ngOnDestroy(): void {
        this._subscriptions.forEach((s)=>s.unsubscribe());
    }

    public add(option: DisplayOptionModel): void{
        this.cartTeleSalesService.addProduct(option).subscribe();
    }

    public remove(option: DisplayOptionModel): void{
        this.cartTeleSalesService.removeProduct(option).subscribe();
    }

    public showLess(): void {
        this.length = this.defaultMaxLength;
    }

    public showMore(): void {
        this.length = this.options.length;
    }

    public selectOptionsCategory(optCat: IOptionCategory): void {
        this.selectedCategory = optCat;
        if (optCat.code !== 'all') {
            this.optionsCategories = this.options.filter((opt) => opt.category === optCat.code);
        } else {
            this.optionsCategories = this.options;
        }
    }

    private _autoAddOptions(): void {
        const planUpsells: string[] = this.quote.getProductByType('Plan')?.upSells;
        const productsToAdd: string[] = this.orderRecovery.cart.options
            .filter((option: {name: string; gencode: string}) => planUpsells.includes(option.gencode))
            .map(op => op.gencode)
            .filter(
                gencode => !this.quote.products.map(p=>p.gencode).includes(gencode)
            )
            .filter(gencode => this.orderRecovery.cart.canRestoreProduct(gencode));
        if (!productsToAdd) {return;}

        this._subscriptions.push(
            this.cartTeleSalesService.addProducts(productsToAdd).subscribe()
        );
    }

    private _initOptionsCategories(): void {
        const plan: Plan = this.quote.getPrincipalProduct<Plan>('Plan');
        this.isCategoriesViewActive = plan && ProductFactory.Is(plan, Fai);
        if (this.isCategoriesViewActive) {
            this.optionsCategories = this.options;
            this.tabsCategories = this.tabsCategories.map((tabCat) => {
                if (tabCat.code !== 'all') {
                    tabCat.visible = this.options.some((opt) => opt.category === tabCat.code);
                }
                return tabCat;
            });
        }
        this.optionsAvailableService.subjectCrossSellLoader.next(false);
    }

    @bind
    private _getAvailableOptions(options: Record<string, DisplayOptionModel[]>): void {
        this.loading = true;
        this._subscriptions.push(
            of(options).pipe(
                map(this._cleanOptions),
                map(this._cleanEdpOption),
                mergeMap(this._preloadPromotionsOptions),
                mergeMap(this._getContextualizedOptions),
                map(this._sortOptions),
                tap(() => this.options = []),
                finalize(() => {
                    this.loading = false;
                })
            ).subscribe({
                next: (optionsContexted: DisplayOptionModel[]) => {
                    const orderedOptionsContexted: string[] = this.options?.map(op => op.gencode) ?? [];
                    this.mainOption = optionsContexted.filter(
                        (opt) => !!opt.data.firstPage
                    ).slice(0, 2);

                    let optionsToDisplay: DisplayOptionModel[] = this.mainOption
                        ? optionsContexted.filter((opt) =>
                            !this.mainOption.map((o) => o.gencode).includes(opt.gencode)
                        )
                        : optionsContexted;
                    if (orderedOptionsContexted.length) {
                        optionsToDisplay = orderedOptionsContexted.map(gencode => this.options.find(o => o.gencode === gencode));
                    }
                    this.options = optionsToDisplay;
                    this._initOptionsCategories();
                },
                error: () => this.optionsAvailableService.subjectCrossSellLoader.next(false)
            })
        );
    }


    @bind
    private _getContextualizedOptions(options: DisplayOptionModel[]): Observable<DisplayOptionModel[]>{
        return forkJoin(options.map(opt => {
            const products: Product[] = this.cartTeleSalesService.cartModel.getAllProducts().map((p)=>p.clone());
            const optInCart = products.find(p => p.gencode === opt.gencode);
            if (optInCart){
                return of(optInCart as DisplayOptionModel);
            }
            return this.cartService.cloneAndAdd(this.cartTeleSalesService.cartModel,[opt])
                .pipe(map(cart=>cart.getQuote().getProductByGencode(opt.gencode) as DisplayOptionModel));
        }));
    }

    @bind
    private _sortOptions(options: DisplayOptionModel[]): DisplayOptionModel[]{
        return options.sort((a,b)=>a.position - b.position);
    }

    @bind
    private _cleanOptions(options: Record<string, DisplayOptionModel[]>): DisplayOptionModel[]{
        const plan: Plan = this.quote.getProductByType(Plan);
        return options[plan.gencode].filter((opt)=> ![
            ...this.optionsAvailableService.getParentalControlGencode('basic'),
            ...this.optionsAvailableService.getParentalControlGencode('premium')
        ].includes(opt.gencode));
    }

    @bind
    private _cleanEdpOption(options: DisplayOptionModel[]): DisplayOptionModel[]{
        const productIG: Product =
            this.cartTeleSalesService.cartModel.getAllProducts().filter(p => (p as Option).category === OptionCategory.internetGarantie)[0];
        if (productIG) {
            return options.filter((opt)=> opt.gencode !== OptionGencode.edpFms);
        }
        return options;
    }

    @bind
    private _preloadPromotionsOptions(options: DisplayOptionModel[]): Observable<DisplayOptionModel[]>{
        return this.promotionsService.preloadPromotions(this.cartTeleSalesService.cartModel,options.map(p=>p.gencode))
            .pipe(map(()=>options));
    }

}
