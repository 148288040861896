import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfigurationService, REGEXS } from '@common-modules';
import { FaiBox } from '@bytel/bytel-sales';
import { CalendarUnavailableComponent } from '@components/modal/calendar-unavailable/calendar-unavailable.component';
import { CalendarSkipComponent} from '@components/checkout/step/appointment/calendar-skip/calendar-skip.component';
import { DISPONIBILITY_SLOTS } from '@interfaces/appointment.interface';
import { AppointmentModel } from '@models/cart/appointment.model';
import { SlotModel } from '@models/cart/slot.model';
import { OrderModel } from '@models/order/order.model';
import { DialogService } from '@ngneat/dialog';
import { HotToastService } from '@ngneat/hot-toast';
import { AppointmentService } from '@services/checkout/appointment.service';
import { CartTeleSalesService } from '@services/checkout/cart-telesales.service';
import { SalesService } from '@services/checkout/sales.service';
import { StepperService } from '@services/checkout/stepper.service';
import { CustomerService } from '@services/customer/customer.service';
import { FaiEligibilityService } from '@services/fai/fai-eligibility.service';
import { QualificationService } from '@services/qualification.service';
import { SalesForceService } from '@services/salesforce.service';
import { SharedService } from '@services/shared.service';
import { CalendarDateFormatter, CalendarEvent, CalendarView } from 'angular-calendar';
import { plainToClass } from 'class-transformer';
import { format, isFuture, isValid, parseISO } from 'date-fns';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, mergeMap, retry, share, tap } from 'rxjs/operators';
import { CalendarProvider } from './calendar.provider';

@Component({
    selector: 'tlv-checkout-appointment',
    templateUrl: './appointment.component.html',
    styleUrls: ['./appointment.component.scss'],
    providers: [
        {
            provide: CalendarDateFormatter,
            useClass: CalendarProvider
        }
    ]
})
export class AppointmentComponent implements OnInit {
    public static readonly PREMIUM_SALE_GROUP_ID: string = '1034';
    public view: CalendarView | string = CalendarView.Month;
    public events: any = [];
    public appointmentForm: FormGroup = new FormGroup({
        contactNumber: new FormControl('', [
            Validators.required,
            Validators.pattern(REGEXS.PHONE_REGEXP)
        ]),
        digicode: new FormControl(''),
        interphone: new FormControl(''),
        comment: new FormControl(''),
        appointment: new FormControl('', Validators.required),
        disponibilitySlot: new FormControl('', Validators.required)
    });
    public obsSlots: Observable<CalendarEvent<SlotModel>[]>;
    public currentSlot: SlotModel;
    public installationAddress: string;
    public isSubmitted = false;
    public isLoading = false;
    public disponibilitySlots = DISPONIBILITY_SLOTS;
    public prefillCalendar = new Subject<boolean>();
    public preselectedDate: Date;
    public preorderFTTH: string;
    public orderRecovery: OrderModel;
    public mock: boolean;
    public callOut: boolean = false;
    public appointments: CalendarEvent[];

    constructor(
        private appointmentService: AppointmentService,
        private customerService: CustomerService,
        private salesService: SalesService,
        private cartTeleSalesService: CartTeleSalesService,
        private faiEligibilityService: FaiEligibilityService,
        private stepperService: StepperService,
        private toastService: HotToastService,
        private sharedService: SharedService,
        private configService: ConfigurationService,
        private dialogService: DialogService,
        private salesForceService: SalesForceService,
        private qualificationService: QualificationService) {
        const dateOpeningCommercial = this.faiEligibilityService.currentCart.selectedVerticalStructure?.dateOuvertureCommerciale
            ? parseISO(this.faiEligibilityService.currentCart.selectedVerticalStructure?.dateOuvertureCommerciale) : undefined;

        this.preorderFTTH = isValid(dateOpeningCommercial) && isFuture(dateOpeningCommercial) ?
            format(parseISO(dateOpeningCommercial.toJSON()), 'dd/MM/yyyy') : null;
        this.sharedService.getEventLoadCalendar().subscribe(() => {
            this._loadAppointments();
        });
        this.orderRecovery = this.salesForceService.prefilledInfo.order;
        if (this.orderRecovery?.cart?.appointment) {
            this.preselectedDate = new Date(this.orderRecovery.cart.appointment.start);
        }
        this.mock = !!document.defaultView.window.ConfigInitial.mock;
    }

    public ngOnInit(): void {
        this.callOut = this.qualificationService.qualification?.calltype === 'out';
        if (this.configService.data_refconf?.checkout?.bypass_step_appointment === true) {
            const refDialog = this.dialogService.open(CalendarSkipComponent);
            refDialog.afterClosed$.subscribe((skip) => {
                if (skip){
                    this.stepperService.goToNextStep();
                } else {
                    this.initAppointment();
                }
            });
        } else {
            this.initAppointment();
        }
    }

    public initAppointment(): void {
        const storedAppointment: AppointmentModel = this.appointmentService.currentAppointment;

        if (this.faiEligibilityService.currentCart.eligibility.isCDL){
            this.installationAddress = this.faiEligibilityService.currentCart.searchAddress.inline;
        } else {
            this.installationAddress = this.faiEligibilityService.currentCart.eligibility.installationAddress.inline;
        }
        this._loadAppointments();
        if (storedAppointment && !this.orderRecovery) {
            this.appointmentForm.patchValue(storedAppointment);
            if (storedAppointment.start && storedAppointment.end){
                this._setSlot(storedAppointment);
            }
        }
        if (!this.appointmentForm.get('contactNumber').value && this.customerService.customer) {
            this.appointmentForm.get('contactNumber').setValue(
                this.orderRecovery?.cart?.appointment?.contactNumber ?? this.customerService.customer.phone
            );
        }
    }

    public prefillFields(): void {
        this.prefillCalendar.next(true);
        this.appointmentForm.patchValue(
            {
                contactNumber: '0612345678',
                digicode: '12345',
                interphone: '6789',
                comment: 'Attention au chien',
                disponibilitySlot: this.disponibilitySlots.morning
            }
        );
    }

    public handleSelectedAppointment(event: CalendarEvent<SlotModel>): void {
        this._setSlot(event?.meta || this.currentSlot);
        this.appointmentForm.get('appointment').setValue(this.currentSlot);
    }

    public submit(): any {
        if (this.callOut) {
            this.appointmentService.skipStep = true;
            this.stepperService.goToNextStep();
        }
        this.isSubmitted = true;
        this.isLoading = true;
        const faiBox = this.cartTeleSalesService.cartModel.getQuote().getPrincipalProduct<FaiBox>('FaiBox');
        if (this.currentSlot && this.appointmentForm.valid) {
            this.appointmentService.updateAppointment(this.appointmentForm.getRawValue()).pipe(
                mergeMap(() => this.salesService.updateProduct(faiBox))
            ).subscribe({
                next: () => {
                    this.stepperService.goToNextStep();
                },
                error: (error) => {
                    this.isLoading = false;
                    this._loadAppointments();
                    this.toastService.error('Le rendez-vous que vous avez choisi n\'est plus disponible, veuillez en choisir un nouveau.');
                    return throwError(() => new Error(error));
                }
            });
        }
    }

    private _loadAppointments(): void {
        let modalAlreadyOpened = false;
        this.obsSlots = this.appointmentService.getAvailableAppointements().pipe(
            retry(3),
            tap((appointments) => {
                this.appointments = appointments;
                if (this.orderRecovery?.cart?.appointment && !this.currentSlot
                    && appointments.find(app => app.id === this.orderRecovery.cart.appointment.id)) {
                    this._setSlot(this.orderRecovery.cart.appointment);
                }
            }),
            share(),
            catchError((err) => {
                if (!modalAlreadyOpened) {
                    this.dialogService.open(CalendarUnavailableComponent, {
                        data: {
                            errorCode: err.status
                        }
                    });
                    modalAlreadyOpened = true;
                }
                return throwError(() => new Error(err));
            })
        );
    }

    private _setSlot(value: AppointmentModel | SlotModel): void {
        if (value instanceof AppointmentModel) {
            this.currentSlot = plainToClass(SlotModel,value);
        } else {
            this.currentSlot = value;
        }
    }

}
