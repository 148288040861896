<form id="form" [formGroup]="ibanForm" autocomplete="chrome-off">
    <!-- HACK: DISABLE CHROME AUTOCOMPLETE -->
    <input style="opacity: 0;position: absolute;">
    <input type="password" style="opacity: 0;position: absolute;">
    <!-- -->
    <ng-container *ngIf="ibans.length">
        <div class="control" >
            <div class="select is-info is-fullwidth">
                <select data-cy="customer-iban-select" formControlName="customerIban">
                        <option selected value="0">Sélectionnez un Iban</option>
                    <ng-container *ngFor="let iban of ibans; let i = index">
                        <option [value]="i+1">{{iban.masked}}</option>
                    </ng-container>
                </select>
            </div>
        </div>
        <div class="field" *ngIf="ibans.length">
            <div class="control">
                <input
                    data-cy="use-customer-iban-for-billing"
                    class="is-checkradio"
                    id="use-for-purchase"
                    type="checkbox"
                    formControlName="useIbanForPurchase">
                <label data-cy="use-customer-iban-for-billing-label" for="use-for-purchase">Utiliser cet IBAN pour mon achat</label>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="!ibanForm.get('useIbanForPurchase').value || !ibans.length || (!ibanForm.get('useIbanForPurchase').value && cartHasMobileRecovery)">
        <div class="field">
            <p class="has-text-weight-semibold" bytelTranslate="panier.paiement.iban.titre">IBAN</p>
            <p class="has-text-grey is-size-6" data-cy="recovery-masked-iban" *ngIf="maskedOrderRecoveryIban">{{maskedOrderRecoveryIban}}</p>
            <div class="control has-icons-right" [class.is-hidden]="maskedOrderRecoveryIban">
                <input
                    data-cy="custom-iban-input"
                    autocomplete="none"
                    class="input"
                    type="text"
                    formControlName="iban"
                    tlvFormClass
                    [tlvFormClassErrorControl]="ibanForm.get('iban')"
                    placeholder="Saisissez votre IBAN" id="iban">
                <span class="icon is-small" *ngIf="ibanForm.get('iban').touched">
                    <i
                        [ngClass]="{'tri-check-circle has-text-success': ibanForm.get('iban').valid, 'tri-exclamation-circle has-text-danger': ibanForm.get('iban').invalid && ibanForm.get('iban').value}"></i>
                </span>
            </div>
            <p *ngIf="cartHasMobileRecovery">Cet iban sera utilisé pour le versement de la reprise mobile.</p>
            <p *ngIf="orderRecovery">Cet iban sera utilisé pour la reprise commande.</p>
        </div>
        <ul *ngIf="shouldShowIbanError"
            data-cy="iban-input-errors" class="help is-danger">
            <li *ngIf="checkOpenBankingStatusMessage?.length > 0"><i
                    class="tri-infos-circle"></i> {{checkOpenBankingStatusMessage}}</li>
            <li *ngIf="ibanForm.get('iban').errors?.required" bytelTranslate="panier.paiement.iban.erreur.obligatoire"><i class="tri-infos-circle"></i> La saisie de l'IBAN est obligatoire</li>
            <li *ngIf="ibanForm.get('iban').errors?.pattern" bytelTranslate="panier.paiement.iban.erreur.invalide"><i class="tri-infos-circle"></i> Veuillez saisir un IBAN correct</li>
            <li *ngIf="ibanForm.get('iban').errors?.ibanApi"><i class="tri-infos-circle"></i> {{ibanForm.get('iban').errors?.ibanApi.message}}</li>
            <li *ngIf="ibanForm.get('iban').errors?.minlength"><i class="tri-infos-circle"></i> Merci de fournir un Iban avec au moins {{ibanForm.get('iban').errors?.minlength.requiredLength}} caractères pour un iban de type <span class="is-uppercase">{{currentCountryCode}}</span></li>
        </ul>
        <div class="notification is-small has-body is-info" *ngIf="ibanForm.get('iban').valid && canalPlusElig">
            <div class="icon">
                <i class="tri-exclamation-circle"></i>
            </div>
            <div class="body">
                <h5 bytelTranslate="panier.paiement.iban.canalPlus.notif1" class="title">Vous pouvez continuez votre commande.</h5>
                <p bytelTranslate="panier.paiement.iban.canalPlus.notif2">Cependant nous ne sommes pas en mesure de donner suite à votre demande de souscription à CANAL +
                    Veuillez contacter le Service Client CANAL au 0 892 39 39 10 (0,35 €/min + prix d'appel)
                </p>
            </div>
        </div>
    </ng-container>
</form>
