export const regions = [
    {
        region_id: 182,
        country_id: 'FR',
        code: '01',
        label: 'Ain'
    },
    {
        region_id: 183,
        country_id: 'FR',
        code: '02',
        label: 'Aisne'
    },
    {
        region_id: 184,
        country_id: 'FR',
        code: '03',
        label: 'Allier'
    },
    {
        region_id: 185,
        country_id: 'FR',
        code: '04',
        label: 'Alpes-de-Haute-Provence'
    },
    {
        region_id: 186,
        country_id: 'FR',
        code: '05',
        label: 'Hautes-Alpes'
    },
    {
        region_id: 187,
        country_id: 'FR',
        code: '06',
        label: 'Alpes-Maritimes'
    },
    {
        region_id: 188,
        country_id: 'FR',
        code: '07',
        label: 'Ardèche'
    },
    {
        region_id: 189,
        country_id: 'FR',
        code: '08',
        label: 'Ardennes'
    },
    {
        region_id: 190,
        country_id: 'FR',
        code: '09',
        label: 'Ariège'
    },
    {
        region_id: 201,
        country_id: 'FR',
        code: '2A',
        label: 'Corse-du-Sud'
    },
    {
        region_id: 202,
        country_id: 'FR',
        code: '2B',
        label: 'Haute-Corse'
    },
    {
        region_id: 191,
        country_id: 'FR',
        code: '10',
        label: 'Aube'
    },
    {
        region_id: 192,
        country_id: 'FR',
        code: '11',
        label: 'Aude'
    },
    {
        region_id: 193,
        country_id: 'FR',
        code: '12',
        label: 'Aveyron'
    },
    {
        region_id: 194,
        country_id: 'FR',
        code: '13',
        label: 'Bouches-du-Rhône'
    },
    {
        region_id: 195,
        country_id: 'FR',
        code: '14',
        label: 'Calvados'
    },
    {
        region_id: 196,
        country_id: 'FR',
        code: '15',
        label: 'Cantal'
    },
    {
        region_id: 197,
        country_id: 'FR',
        code: '16',
        label: 'Charente'
    },
    {
        region_id: 198,
        country_id: 'FR',
        code: '17',
        label: 'Charente-Maritime'
    },
    {
        region_id: 199,
        country_id: 'FR',
        code: '18',
        label: 'Cher'
    },
    {
        region_id: 200,
        country_id: 'FR',
        code: '19',
        label: 'Corrèze'
    },
    {
        region_id: 203,
        country_id: 'FR',
        code: '21',
        label: 'Côte-d\'Or'
    },
    {
        region_id: 204,
        country_id: 'FR',
        code: '22',
        label: 'Côtes-d\'Armor'
    },
    {
        region_id: 205,
        country_id: 'FR',
        code: '23',
        label: 'Creuse'
    },
    {
        region_id: 206,
        country_id: 'FR',
        code: '24',
        label: 'Dordogne'
    },
    {
        region_id: 207,
        country_id: 'FR',
        code: '25',
        label: 'Doubs'
    },
    {
        region_id: 208,
        country_id: 'FR',
        code: '26',
        label: 'Drôme'
    },
    {
        region_id: 209,
        country_id: 'FR',
        code: '27',
        label: 'Eure'
    },
    {
        region_id: 210,
        country_id: 'FR',
        code: '28',
        label: 'Eure-et-Loir'
    },
    {
        region_id: 211,
        country_id: 'FR',
        code: '29',
        label: 'Finistère'
    },
    {
        region_id: 212,
        country_id: 'FR',
        code: '30',
        label: 'Gard'
    },
    {
        region_id: 213,
        country_id: 'FR',
        code: '31',
        label: 'Haute-Garonne'
    },
    {
        region_id: 214,
        country_id: 'FR',
        code: '32',
        label: 'Gers'
    },
    {
        region_id: 215,
        country_id: 'FR',
        code: '33',
        label: 'Gironde'
    },
    {
        region_id: 216,
        country_id: 'FR',
        code: '34',
        label: 'Hérault'
    },
    {
        region_id: 217,
        country_id: 'FR',
        code: '35',
        label: 'Ille-et-Vilaine'
    },
    {
        region_id: 218,
        country_id: 'FR',
        code: '36',
        label: 'Indre'
    },
    {
        region_id: 219,
        country_id: 'FR',
        code: '37',
        label: 'Indre-et-Loire'
    },
    {
        region_id: 220,
        country_id: 'FR',
        code: '38',
        label: 'Isère'
    },
    {
        region_id: 221,
        country_id: 'FR',
        code: '39',
        label: 'Jura'
    },
    {
        region_id: 222,
        country_id: 'FR',
        code: '40',
        label: 'Landes'
    },
    {
        region_id: 223,
        country_id: 'FR',
        code: '41',
        label: 'Loir-et-Cher'
    },
    {
        region_id: 224,
        country_id: 'FR',
        code: '42',
        label: 'Loire'
    },
    {
        region_id: 225,
        country_id: 'FR',
        code: '43',
        label: 'Haute-Loire'
    },
    {
        region_id: 226,
        country_id: 'FR',
        code: '44',
        label: 'Loire-Atlantique'
    },
    {
        region_id: 227,
        country_id: 'FR',
        code: '45',
        label: 'Loiret'
    },
    {
        region_id: 228,
        country_id: 'FR',
        code: '46',
        label: 'Lot'
    },
    {
        region_id: 229,
        country_id: 'FR',
        code: '47',
        label: 'Lot-et-Garonne'
    },
    {
        region_id: 230,
        country_id: 'FR',
        code: '48',
        label: 'Lozère'
    },
    {
        region_id: 231,
        country_id: 'FR',
        code: '49',
        label: 'Maine-et-Loire'
    },
    {
        region_id: 232,
        country_id: 'FR',
        code: '50',
        label: 'Manche'
    },
    {
        region_id: 233,
        country_id: 'FR',
        code: '51',
        label: 'Marne'
    },
    {
        region_id: 234,
        country_id: 'FR',
        code: '52',
        label: 'Haute-Marne'
    },
    {
        region_id: 235,
        country_id: 'FR',
        code: '53',
        label: 'Mayenne'
    },
    {
        region_id: 236,
        country_id: 'FR',
        code: '54',
        label: 'Meurthe-et-Moselle'
    },
    {
        region_id: 237,
        country_id: 'FR',
        code: '55',
        label: 'Meuse'
    },
    {
        region_id: 238,
        country_id: 'FR',
        code: '56',
        label: 'Morbihan'
    },
    {
        region_id: 239,
        country_id: 'FR',
        code: '57',
        label: 'Moselle'
    },
    {
        region_id: 240,
        country_id: 'FR',
        code: '58',
        label: 'Nièvre'
    },
    {
        region_id: 241,
        country_id: 'FR',
        code: '59',
        label: 'Nord'
    },
    {
        region_id: 242,
        country_id: 'FR',
        code: '60',
        label: 'Oise'
    },
    {
        region_id: 243,
        country_id: 'FR',
        code: '61',
        label: 'Orne'
    },
    {
        region_id: 244,
        country_id: 'FR',
        code: '62',
        label: 'Pas-de-Calais'
    },
    {
        region_id: 245,
        country_id: 'FR',
        code: '63',
        label: 'Puy-de-Dôme'
    },
    {
        region_id: 246,
        country_id: 'FR',
        code: '64',
        label: 'Pyrénées-Atlantiques'
    },
    {
        region_id: 247,
        country_id: 'FR',
        code: '65',
        label: 'Hautes-Pyrénées'
    },
    {
        region_id: 248,
        country_id: 'FR',
        code: '66',
        label: 'Pyrénées-Orientales'
    },
    {
        region_id: 249,
        country_id: 'FR',
        code: '67',
        label: 'Bas-Rhin'
    },
    {
        region_id: 250,
        country_id: 'FR',
        code: '68',
        label: 'Haut-Rhin'
    },
    {
        region_id: 251,
        country_id: 'FR',
        code: '69',
        label: 'Rhône'
    },
    {
        region_id: 252,
        country_id: 'FR',
        code: '70',
        label: 'Haute-Saône'
    },
    {
        region_id: 253,
        country_id: 'FR',
        code: '71',
        label: 'Saône-et-Loire'
    },
    {
        region_id: 254,
        country_id: 'FR',
        code: '72',
        label: 'Sarthe'
    },
    {
        region_id: 255,
        country_id: 'FR',
        code: '73',
        label: 'Savoie'
    },
    {
        region_id: 256,
        country_id: 'FR',
        code: '74',
        label: 'Haute-Savoie'
    },
    {
        region_id: 257,
        country_id: 'FR',
        code: '75',
        label: 'Paris'
    },
    {
        region_id: 258,
        country_id: 'FR',
        code: '76',
        label: 'Seine-Maritime'
    },
    {
        region_id: 259,
        country_id: 'FR',
        code: '77',
        label: 'Seine-et-Marne'
    },
    {
        region_id: 260,
        country_id: 'FR',
        code: '78',
        label: 'Yvelines'
    },
    {
        region_id: 261,
        country_id: 'FR',
        code: '79',
        label: 'Deux-Sèvres'
    },
    {
        region_id: 262,
        country_id: 'FR',
        code: '80',
        label: 'Somme'
    },
    {
        region_id: 263,
        country_id: 'FR',
        code: '81',
        label: 'Tarn'
    },
    {
        region_id: 264,
        country_id: 'FR',
        code: '82',
        label: 'Tarn-et-Garonne'
    },
    {
        region_id: 265,
        country_id: 'FR',
        code: '83',
        label: 'Var'
    },
    {
        region_id: 266,
        country_id: 'FR',
        code: '84',
        label: 'Vaucluse'
    },
    {
        region_id: 267,
        country_id: 'FR',
        code: '85',
        label: 'Vendée'
    },
    {
        region_id: 268,
        country_id: 'FR',
        code: '86',
        label: 'Vienne'
    },
    {
        region_id: 269,
        country_id: 'FR',
        code: '87',
        label: 'Haute-Vienne'
    },
    {
        region_id: 270,
        country_id: 'FR',
        code: '88',
        label: 'Vosges'
    },
    {
        region_id: 271,
        country_id: 'FR',
        code: '89',
        label: 'Yonne'
    },
    {
        region_id: 272,
        country_id: 'FR',
        code: '90',
        label: 'Territoire-de-Belfort'
    },
    {
        region_id: 273,
        country_id: 'FR',
        code: '91',
        label: 'Essonne'
    },
    {
        region_id: 274,
        country_id: 'FR',
        code: '92',
        label: 'Hauts-de-Seine'
    },
    {
        region_id: 275,
        country_id: 'FR',
        code: '93',
        label: 'Seine-Saint-Denis'
    },
    {
        region_id: 276,
        country_id: 'FR',
        code: '94',
        label: 'Val-de-Marne'
    },
    {
        region_id: 277,
        country_id: 'FR',
        code: '95',
        label: 'Val-d\'Oise'
    },
    {
        region_id: 494,
        country_id: 'FR',
        code: '99',
        label: 'Etranger\n'
    },
    {
        region_id: 489,
        country_id: 'FR',
        code: '971',
        label: 'GUADELOUPE'
    },
    {
        region_id: 490,
        country_id: 'FR',
        code: '972',
        label: 'MARTINIQUE'
    },
    {
        region_id: 491,
        country_id: 'FR',
        code: '973',
        label: 'GUYANE'
    },
    {
        region_id: 492,
        country_id: 'FR',
        code: '974',
        label: 'LA REUNION'
    },
    {
        region_id: 493,
        country_id: 'FR',
        code: '976',
        label: 'MAYOTTE'
    }
];
