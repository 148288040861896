<div class="box" data-cy="cart-summary-items">
    <div class="columns">
        <ng-container *ngIf="isFai">
            <div class="column has-text-centered">
                <div class="columns is-mobile">
                    <div class="column is-6 is-offset-3">
                        <p class="has-text-centered has-text-weight-semibold is-size-5" bytelTranslate="panier.resume.global.fai.offre">Mon offre</p>
                    </div>
                    <div class="column is-3"><a data-cy="edit-summary-btn" (click)="editProducts()" class="link is-tertiary is-pulled-right is-hidden-tablet" bytelTranslate="panier.resume.global.fai.modifier">Modifier</a></div>
                </div>
                <div class="v-centered" [ngClass]="{'has-separator': hasPortability}">
                    <tlv-subscriptions-summary [showSmallerView]="true"></tlv-subscriptions-summary>
                </div>
            </div>
            <div class="column is-narrow is-hidden-mobile" *ngIf="!hasPortability">
                <a class="link is-tertiary is-pulled-right" (click)="editProducts()" bytelTranslate="panier.resume.global.fai.sans_portabilite.modifier">Modifier</a>
            </div>
            <div class="column has-text-centered" *ngIf="hasPortability">
                <div class="is-divider is-hidden-tablet"></div>
                <p class="has-text-right is-hidden-mobile">
                    <a class="link is-tertiary" (click)="editProducts()" bytelTranslate="panier.resume.global.fai.portabilite.modifier">Modifier</a>
                </p>
                <div class="v-centered">
                    <span class="icon is-large has-text-info"><i class="tri-phone"></i></span>
                    <p class="is-size-5">{{ hasPortability ? 'Je conserve mon numéro' : 'Je souscris avec un nouveau numéro' }}</p>
                    <p class="is-size-6 has-text-weight-semibold" *ngIf="phoneNumber">({{phoneNumber}})</p>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="!isFai">
            <div class="column is-spaced" *ngIf="plan">
                <div class="columns is-mobile">
                    <div class="column is-6 is-offset-3">
                        <p class="has-text-centered has-text-weight-semibold is-size-5" bytelTranslate="panier.resume.global.mobile.offre">
                            Mon offre 
                        </p>
                    </div>
                    <div class="column is-3">
                        <a (click)="editProducts()" class="link is-tertiary is-pulled-right is-hidden-tablet" bytelTranslate="panier.resume.global.mobile.affichage_mobile.modifier">Modifier</a>
                    </div>
                </div>
                
                <div [ngClass]="{'has-separator': equipment}">
                    <tlv-subscriptions-summary></tlv-subscriptions-summary>
                </div>
                <div class="is-divider is-hidden-tablet"></div>
            </div>
            <div class="column is-spaced" *ngIf="equipment">
                <p class="has-text-centered has-text-weight-semibold is-size-5" bytelTranslate="panier.resume.global.mobile.equipement">Mon équipement</p>
                <tlv-equipments-summary></tlv-equipments-summary>
            </div>
            <div class="column is-narrow is-hidden-mobile">
                <a data-cy="edit-summary-btn" class="link is-tertiary is-pulled-right" (click)="editProducts()" bytelTranslate="panier.resume.global.mobile.affichage_desktop.offre">Modifier</a>
            </div>
        </ng-container>
    </div>
</div>


