<router-outlet (activate)="disableLoader($event)">
    <div *ngIf="!!isLoading" class="placeholder-container">
        <div class="columns is-centered is-multiline" data-cy="loader-feedback">
            <div class="column is-narrow">
                <span class="icon is-huge is-rotating"><i class="tri-logo" aria-hidden='true'></i></span>
            </div>
            <div class="column is-12">
                 <p class="is-size-5 has-text-centered">Chargement {{isLoadingOrder ? 'de la commande' : 'en cours'}}...</p>
            </div>
        </div>
    </div>
</router-outlet>
