<ng-template #loading>
    <tlv-loader loadingText="Chargement des offres en cours..."></tlv-loader>
</ng-template>
<ng-container *ngIf="!isLoading; else loading;">
    <table class="table is-fullwidth" *ngIf="recoveryCarts?.length > 0" data-cy="cart-list">
        <thead>
        <tr>
            <ng-container *ngIf="isOrder; else notOrderHead">
                <th>N° de commande</th>
                <th>Date de commande</th>
                <th>Statut de la commande</th>
                <th>Motif d'annulation</th>
                <th>Canal</th>
                <th>Créateur</th>
                <th>Type de l'offre</th>
            </ng-container>
            <ng-template #notOrderHead>
                <th>ID</th>
                <th>Date de création</th>
                <th *ngIf="isProposal">Date de validité</th>
                <th *ngIf="!isProposal">Canal</th>
                <th *ngIf="!isProposal">Créateur</th>
                <th *ngIf="!isProposal">Statut</th>
                <th>Produits</th>
                <th>Type d'offre</th>
                <th *ngIf="isProposal">Montant mensuel</th>
                <th *ngIf="isProposal">Montant comptant</th>
                <th>Opérations associées</th>
            </ng-template>
        </tr>
        </thead>
        <tbody>
        <ng-container *ngFor="let recoveryCart of recoveryCarts;  let index = index;">
            <tr data-cy="items" class="is-expandable" [ngClass]="{'is-expanded': selectedRecoveryCarts && selectedRecoveryCarts.id === recoveryCart.id}">
                <ng-container *ngIf="isOrder; else notOrderContent">
                    <td data-cy="id">{{ recoveryCart.id }}</td>
                    <td data-cy="creation-date">{{ recoveryCart.orderCreation | date: 'dd/MM/yyyy' }}</td>
                    <td data-cy="status">{{ recoveryCart.status }}</td>
                    <td data-cy="motif">{{ recoveryCart.status === ORDER_STATUS.CANCELED ? recoveryCart.statusChangeReason : 'Pas de motif' }}</td>
                    <td data-cy="canal" class="is-uppercase">{{ CHANNEL_LABEL[recoveryCart.canal?.toUpperCase()] }}</td>
                    <td data-cy="acteurCreateur" class="is-uppercase">{{ ACTOR_CREATOR_LABEL[recoveryCart.acteurCreateur?.toUpperCase()] }}</td>
                    <td data-cy="offer-type">{{ offerTypes[index] }}</td>
                </ng-container>
                <ng-template #notOrderContent>
                    <td data-cy="id">{{recoveryCart.id}}</td>
                    <td data-cy="creation-date">{{recoveryCart.orderCreation | date: 'dd/MM/yyyy'}}</td>
                    <td *ngIf="isProposal" data-cy="validity-date">{{recoveryCart.validityDate | date: 'dd/MM/yyyy'}}</td>
                    <td *ngIf="!isProposal" class="is-uppercase" data-cy="canal">{{ CHANNEL_LABEL[recoveryCart.canal?.toUpperCase()] }}</td>
                    <td *ngIf="!isProposal" data-cy="acteurCreateur" class="is-uppercase">{{ ACTOR_CREATOR_LABEL[recoveryCart.acteurCreateur?.toUpperCase()] }}</td>
                    <td *ngIf="!isProposal"  data-cy="status"> {{ recoveryCart.status === ORDER_STATUS.NOT_COMPLETE ? 'Non finalisé' : '-'}} </td>
                    <td data-cy="show-products">
                  <span class="icon-and-text show-products" (click)="toggleProduct(recoveryCart)">
                    <span *ngIf="!(selectedRecoveryCarts && selectedRecoveryCarts.id === recoveryCart.id)">Afficher</span>
                    <span *ngIf="selectedRecoveryCarts && selectedRecoveryCarts.id === recoveryCart.id">Masquer</span>
                    <span [ngClass]="selectedRecoveryCarts && selectedRecoveryCarts.id === recoveryCart.id && 'up'"
                          class="icon is-small icon-rotate">
                        <span class="tri-arrow-down"></span>
                    </span>
                  </span>
                    </td>
                    <td data-cy="offer-type">{{ offerTypes[index] }}</td>
                    <td *ngIf="isProposal" data-cy="monthly-amount">{{recoveryCart.monthlyAmount}}</td>
                    <td *ngIf="isProposal" data-cy="amount">{{recoveryCart.amount}}</td>
                    <td>
                        <button [disabled]="offerTypes[index] === PRINCIPAL_OFFER_TYPE.ABONNEMENT_NU || isClientLockByScoring" [data-cy]="'select-abandonned-cart-'+recoveryCart.id" type="button" class="button is-small is-primary" (click)="loadRecoveryCart(recoveryCart)">
                            {{offerTypes[index] === PRINCIPAL_OFFER_TYPE.ABONNEMENT_NU ?
                                'Non Récupérable' : 'Reprendre'
                            }}
                        </button>
                    </td>
                </ng-template>
            </tr>
            <tr class="is-expansion" data-cy="products">
                <td colspan="9">
                    <div class="subtable">
                        <ul>
                            <li  *ngFor="let product of recoveryCart.products">
                                {{product.name}}
                            </li>
                        </ul>
                    </div>
                </td>
            </tr>
        </ng-container>
        </tbody>
    </table>
</ng-container>
