<section class="section has-text-centered has-background-white" *ngIf="steps.length" data-cy="checkout-breadcrumb">
    <nav aria-label="breadcrumbs" class="breadcrumb">
        <ul>
            <li *ngFor="let step of steps; index as index">
                <a (click)="goToStep(step)"
                   [ngClass]="{'is-active has-text-tertiary': step.status === STATUS.INPROGRESS, 'has-text-grey': step.status !== STATUS.INPROGRESS}"
                   aria-current="page" class="is-size-6 is-size-5-mobile">
                    {{index + 1}}<span [ngClass]="{'is-hidden-mobile': step.status !== STATUS.INPROGRESS}">. {{step.label}}</span>
                </a>
            </li>
        </ul>
    </nav>
</section>
