import { Component, OnDestroy, OnInit } from '@angular/core';
import { CartModel, Product, PRODUCT_TYPES_BASE, PromotionsModel } from '@bytel/bytel-sales';
import { DisplayAccessoryModel } from '@models/cart/display-accessory.model';
import { AccessoryService } from '@services/checkout/accessory.service';
import { CartTeleSalesService } from '@services/checkout/cart-telesales.service';
import { forkJoin, from, Observable, of, Subscription } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import bind from 'src/app/helper/decorators/bind';
import { PromotionsService } from '@services/promotions.service';
import { SalesForceService } from '@services/salesforce.service';
import { OrderModel } from '@models/order/order.model';
import { CatalogService } from '@services/catalog.service';
import { ObjectOf } from 'src/app/helper/types';

@Component({
    selector: 'tlv-accessories',
    templateUrl: './accessories.component.html',
    styleUrls: ['./accessories.component.scss']
})
export class AccessoriesComponent implements OnInit, OnDestroy {
    public accessories: DisplayAccessoryModel[] = [];
    public essentialAccessories: DisplayAccessoryModel[] = [];
    public readonly defaultMaxLength = 3;
    public readonly essentialDefaultMaxLength = 3;
    public length: number = this.defaultMaxLength;
    public essentialLength: number = this.essentialDefaultMaxLength;
    public searchText: string;
    public orderRecovery: OrderModel;
    public orderRecoveryAccList: string[] = [];
    public hasLoaded = false;

    private _subscriptions: Subscription[] = [];

    constructor(private accessoryService: AccessoryService,
                private cartTeleSalesService: CartTeleSalesService,
                private salesForceService: SalesForceService,
                private promotionsService: PromotionsService,
                protected catalogService: CatalogService,) {
        this._subscriptions.push(
            this.accessoryService.update$.subscribe(this._getAvailableAccessories),
        );
    }

    public ngOnInit(): void {
        this.orderRecovery = this.salesForceService.prefilledInfo.order;
        if (this.orderRecovery?.cart?.accessories.length &&
            this.orderRecovery.cart.canRestoreProduct(PRODUCT_TYPES_BASE.accessory)) {
            this._autoAddAccessories();
        }
        this._subscriptions.push(
            this.cartTeleSalesService.refreshObs.subscribe((cart: CartModel) => {
                if (this.accessories.length || this.essentialAccessories.length) {
                    this._getAvailableAccessories([...this.accessories, ...this.essentialAccessories]);
                }
                if (this.orderRecovery?.cart?.accessories.length) {
                    this.orderRecoveryAccList = cart.getQuote().getProductsByType('Accessory')?.map(p => p.name);
                }
            })
        );
    }

    public ngOnDestroy(): void {
        this._subscriptions.forEach((s: Subscription)=>s.unsubscribe());
    }

    public showLess(): void {
        this.length = this.defaultMaxLength;
    }

    public showMore(): void {
        this.length = this.accessories.length;
    }

    public showLessEssential(): void {
        this.essentialLength = this.essentialDefaultMaxLength;
    }

    public showMoreEssential(): void {
        this.essentialLength = this.essentialAccessories.length;
    }

    private _autoAddAccessories(): void {
        const productsToAdd: string[] = this.orderRecovery.cart.accessories
            .map(acc => acc.gencode)
            .filter(
                gencode => !this.cartTeleSalesService.cartModel.getQuote().products.map(p => p.gencode).includes(gencode)
            )
            .filter(gencode => !!this.orderRecovery.cart.canRestoreProduct(gencode));
        if (!productsToAdd.length) {
            this.orderRecoveryAccList = this.orderRecovery.cart.accessories.map(acc => acc.name);
            return;
        }

        this.catalogService.getProductsByGencodes(productsToAdd)
            .pipe(
                mergeMap((products) => this.cartTeleSalesService.addProducts(products.filter(p => !!p.data.in_stock))),
                tap((products) => this.orderRecoveryAccList = [...products].map(p => p.name)),
                mergeMap((products: Product[]) => {
                    const obsQtyUpdate: Observable<Product[]>[] = [];
                    products.forEach(p => {
                        const qty: number = this.orderRecovery.cart.accessories
                            .map(acc => acc.gencode)
                            .filter(genc => genc === p.gencode).length;
                        if (qty > 1) {
                            obsQtyUpdate.push(this.cartTeleSalesService.updateProductQty(p, qty));
                        }
                    });
                    return obsQtyUpdate.length ? forkJoin(obsQtyUpdate) : of(null);
                }),
                tap(() => this.cartTeleSalesService.saveToStorage()),
                mergeMap(() => from(this.cartTeleSalesService.refresh()))
            ).subscribe();
    }

    @bind
    private _getAvailableAccessories(accessories: DisplayAccessoryModel[]): void {
        of(accessories).pipe(
            mergeMap(this._preloadPromotionsAccessories),
            catchError(() => of(this._splitEssential(accessories))),
            map(this._sortAccessories),
            map(this._splitEssential),
        ).subscribe((accessoriesContextedSplited: {essential: DisplayAccessoryModel[]; notEssential: DisplayAccessoryModel[]}) => {
            this.accessories = accessoriesContextedSplited.notEssential;
            this.essentialAccessories = accessoriesContextedSplited.essential;
            this.hasLoaded = true;
        });
    }

    @bind
    private _sortAccessories(accessories: DisplayAccessoryModel[]): DisplayAccessoryModel[]{
        return accessories.sort((a,b)=>a.position - b.position);
    }

    @bind
    private _preloadPromotionsAccessories(accessories: DisplayAccessoryModel[]): Observable<DisplayAccessoryModel[]>{
        return this.promotionsService.preloadPromotions(this.cartTeleSalesService.cartModel,accessories.map(p=>p.gencode))
            .pipe(
                map((promotions: ObjectOf<PromotionsModel>) => this._applyPromotions(accessories, promotions))
            );
    }

    private _applyPromotions(accessories: DisplayAccessoryModel[], promotions: {[index: string]: PromotionsModel}): DisplayAccessoryModel[]{
        accessories.forEach((accessory: DisplayAccessoryModel) => {
            const promotion = promotions[accessory.gencode] ? promotions[accessory.gencode] : new PromotionsModel([],[],[]);
            accessory.calculatePrices(
                this.cartTeleSalesService.cartModel.getQuote(),
                this.promotionsService.GetPromotionsForProduct(accessory,promotion)
            );
        });
        return accessories;
    }

    private _splitEssential(accessories: DisplayAccessoryModel[]):
    {essential: DisplayAccessoryModel[]; notEssential: DisplayAccessoryModel[]} {
        const accObj = {essential: [], notEssential: []};
        accessories.forEach((acc) => {
            if (acc.essential) {
                accObj.essential.push(acc);
            } else {
                accObj.notEssential.push(acc);
            }
        });
        return accObj;
    }

}
