<ng-container *ngIf="hasESim">
   <div class="box">
        <p class="is-size-3">Mise à disposition de votre eSIM</p>
        <div class="boxx-content">
            <div class="resume-notification notification is-small has-body is-info is-marginless">
                <div class="icon has-text-info">
                    <i class="tri-infos-circle"></i>
                </div>
                <div class="body">
                    <p class="text is-1">L'eSIM se présente sous forme d'un QR code, scannable en WIFI avec votre téléphone, depuis votre espace client.<br>Vous serez prévenus de sa mise à disposition par email et <span class="is-uppercase">SMS</span>.</p>
                </div>
            </div>
        </div>
   </div>
</ng-container>