import { Model } from './model';


export class SettingsModel extends Model {

    public environmentsEphUrl: string[];

    constructor(data?: Partial<SettingsModel>){
        super(data);
    }

}

