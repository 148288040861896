import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Plan } from '@bytel/bytel-sales';
import { BasicObject, REGEXS } from '@common-modules';
import { ScoringModalComponent } from '@components/customer/scoring-modal/scoring-modal.component';
import { CustomerProGpSearchModel } from '@interfaces/customer.interface';
import { CustomerSearchModel } from '@models/customer/customer-search.model';
import { ScoringModel } from '@models/scoring.model';
import { EmailValidationModel } from '@models/validators/email-validation.model';
import { DialogService } from '@ngneat/dialog';
import { CartTeleSalesService } from '@services/checkout/cart-telesales.service';
import { ScoringService } from '@services/checkout/scoring.service';
import { CmsService } from '@services/cms.service';
import { ContractService } from '@services/customer/contract.service';
import { CustomerService } from '@services/customer/customer.service';
import { SalesUserService } from '@services/sales-user.service';
import { SalesForceService } from '@services/salesforce.service';
import { EMPTY, Subscription, merge, of, throwError } from 'rxjs';
import { debounceTime, filter, finalize, mergeMap, tap, catchError, map, switchMap } from 'rxjs/operators';
import { searchClientValidator } from '../../../helper/form-rules.helpers';
import { DisplayError } from '../../../models/error/display.error';
import {OpportunityStorage} from '@repositories/storages/opportunity.storage';
import { PrismeLoggerService } from '@services/prisme-logger.service';


@Component({
    selector: 'tlv-customer-search',
    templateUrl: './customer-search.component.html',
    styleUrls: ['./customer-search.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(()=> CustomerSearchComponent),
            multi: true
        }
    ]
})
export class CustomerSearchComponent implements OnInit, OnChanges, OnDestroy, ControlValueAccessor {

    @Input() public calltype: string;
    @Output() public onCustomerSearchComplete: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() public onRedirectAfterSearch: EventEmitter<string> = new EventEmitter<string>();

    public isLoading: boolean = false;
    public isSubmitted: boolean = false;
    public isCompleted: boolean = false;
    public showIdProspectInput: boolean = false;
    public formsFieldsAttributes: BasicObject = {};
    public searchObject: BasicObject = {};
    public clientHasContract: BasicObject = {};
    public customer: CustomerProGpSearchModel;
    public searchForm: FormGroup = new FormGroup({
        searchItem: new FormControl('', [
            Validators.required,
            searchClientValidator
        ]),
        idPrm: new FormControl('')
    });
    public isSearchLocked: boolean = false;

    private _scoringData: ScoringModel;
    private _subscriptions: Subscription[] = [];

    private onChange: (value: boolean) => void;
    private onTouched: () => void;

    constructor(
        protected cartTeleSalesService: CartTeleSalesService,
        private customerService: CustomerService,
        private contractService: ContractService,
        private cmsService: CmsService,
        private salesforceService: SalesForceService,
        public salesUserService: SalesUserService,
        private scoringService: ScoringService,
        private cartService: CartTeleSalesService,
        private router: Router,
        private prismeLogger: PrismeLoggerService,
        private dialogService: DialogService) { }

    public ngOnInit(): void {
        this._initialFromAttr();
        this._checkIdProspectInput();
        this._subscribeFromChange();
        this._autocompleteFromSalesForceData();
        this.scoringService.scoring$.subscribe((data: ScoringModel) => {
            this._scoringData = data;
            // Allow the bypass on scoring locked client to pass the customer search
            if (!!this.scoringService.scoring?.isClientBlockedByScoring()) {
                this.onChange(true);
            }
        });
        if (this.salesforceService.prefilledInfo.customer){
            this._loadSalesForcePrefildCustomer();
        }
        this.searchForm.controls.searchItem.statusChanges.subscribe((status: string) => {
            if (status === 'INVALID') {
                this.onChange(null);
            }
        });
    }

    public ngOnDestroy(): void {
        this._subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
    }

    public ngOnChanges(): void {
        this._checkIdProspectInput();
    }

    public writeValue(): void { return null; }

    public registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    public onPaste(evt: ClipboardEvent): void {
        evt.preventDefault();
        const pastedText = evt.clipboardData?.getData('text');
        if (pastedText) {
            this.searchForm.get('searchItem').setValue(pastedText.replace(/\s/g, ''));
        }
    }

    public onKeyUp(evt: KeyboardEvent): void {
        let weirdChart = '';
        if (evt.isComposing) {
            evt.preventDefault();
            weirdChart = '`';
        }
        if (RegExp(/\s*/g).test(evt.key)) {
            evt.preventDefault();
            this.searchForm.get('searchItem').setValue(`${this.searchForm.get('searchItem').value}${weirdChart}`?.replace(/\s/g, ''));
        }
    }

    public onSearch(): void {
        OpportunityStorage.removeItem(OpportunityStorage.KEYS.DAD);
        this.isSubmitted = true;
        this.isLoading = true;
        this.searchObject = {};
        this.isSearchLocked = false;
        this.customer = null;
        this.searchObject.searchValue = this.searchForm.get('searchItem')?.value;

        this._scoringData = null;
        this.scoringService.set(this._scoringData);
        this._subscriptions.push(this.customerService
            .searchPersonne(this.searchObject.searchValue)
            .pipe(
                mergeMap((customers)=> customers.length ?
                    of(customers[0]) :
                    this.customerService.checkEmail(this.searchObject.searchValue).pipe(
                        catchError((err: EmailValidationModel) => {
                            let errMessage: string;
                            switch (true) {
                                case err.emailContact:
                                    this.searchObject.descriptionError = 'Cet email semble appartenir à un client existant.';
                                    break;
                                case err.isInvalid():
                                    errMessage = 'L’adresse e-mail renseignée n’existe pas, veuillez saisir une nouvelle adresse e-mail';
                                    this.isSearchLocked = true;
                                    this.searchForm.get('searchItem').markAsTouched();
                                    break;
                                default:
                                    this.isSearchLocked = true;
                            }
                            this.searchForm.get('searchItem').setErrors(err.emailContact ? null : {
                                badEmail: errMessage
                            });
                            this.onChange(null);
                            return of(customers[0]);
                        }),
                        map(()=>null)
                    )
                ),
                finalize(() => {
                    this.isCompleted = true;
                    this.isLoading = false;
                    this.isSubmitted = false;
                })
            )
            .subscribe({
                next: (data) => {
                    this.searchObject = Object.assign({}, this.searchObject, this._getSearchPersonType(this.searchObject.searchValue) );
                    if (this.searchObject.isClient && !data) {
                        this.searchObject.isError = true;
                        const emailOrNumTxt = this.searchObject.isNumber ? 'le numéro ' : 'l\'adresse email ';
                        this.searchObject
                            .descriptionError = `Ligne inconnue ou résiliée pour ${emailOrNumTxt} ${this.searchObject.searchValue}`;
                    } else if (!data) {
                        this.searchObject.isError = true;
                        this.searchObject.descriptionError = this.searchObject.descriptionError ?? 'Aucun résultat trouvé';
                    } else {
                        this.customer = data;
                        if (this.customer?.idPerson) {
                            this._checkScoring(this.customer.idPerson);
                            this._checkFaiContract();
                        }
                    }
                },
                error: (error) => {
                    this.searchObject.isError = true;
                    this.searchObject.descriptionError = 'Aucun résultat trouvé';
                    if (error instanceof DisplayError) {
                        this.searchObject.descriptionError = error.message;
                    }
                }
            })
        );
    }

    public close(evt: string = 'saved'): void {
        if (evt.includes('redirect')) {
            this.onRedirectAfterSearch.emit(evt.split('.').pop());
        }
        this.customerService.searchCustomerIhm.next(false);
        this.scoringService.set(this._scoringData);
        this.onChange(true);
        this.onCustomerSearchComplete.emit(true);
    }

    public checkQuotesScoringError(): void {
        let refDialog = null;
        let isSimoKo = false;
        let isFaiKo = false;
        this.scoringService.set(this._scoringData);
        this.cartTeleSalesService.cartModel.quotes
            .forEach((quote) => {
                if (this.scoringService.getScoringError(quote.getPrincipalProduct<Plan>('Plan')) && !refDialog) {
                    if (quote.isAcquisition()) {
                        isSimoKo = true;
                    } else if (quote.isAcquisitionFix()) {
                        isFaiKo = true;
                    }

                }
            });

        if (isSimoKo || isFaiKo) {

            refDialog = this.dialogService.open(ScoringModalComponent, {
                data: {
                    isSimoKo,
                    isFaiKo,
                    continueButton: !(isSimoKo && isFaiKo),
                },
                closeButton: false,
                enableClose: false
            });

            this._subscriptions.push(refDialog.afterClosed$.subscribe((skip) => {
                if (skip) {
                    if (!this.cartTeleSalesService.isQuoteMixed()) {
                        this.cartTeleSalesService.cleanQuote();
                    } else if (isFaiKo && !isSimoKo) {
                        this.cartTeleSalesService.clearQuoteFai();
                    } else if (isSimoKo && !isFaiKo) {
                        this.cartTeleSalesService.clearQuoteAcquisition();
                    }

                    this.customerService.setCustomer(this.customer)
                        .subscribe(() => {
                            this.onClose('saved');
                            this.router.navigate(['panier']);
                        });
                } else {
                    this.salesUserService.closeCall();
                }
            }));
        }
    }

    public continueProspect(): void {
        this.isLoading = true;
        const customer = this.salesforceService.prefilledInfo?.customer || new CustomerSearchModel({});
        const dataKey: string = REGEXS.MOBILE_REGEXP.test(this.searchForm.get('searchItem').value) ?
            'phone' : 'email';
        customer[dataKey] = this.searchForm.get('searchItem').value;
        this._subscriptions.push(this.customerService.setCustomer(customer).pipe(
            switchMap(() => {
                this.onClose('saved');
                return EMPTY;
            }),
            catchError((error) => {
                this.isLoading = false;
                // check if the error is about PU_ID not found
                if (error?.status === 404 && error?.error?.error_parameters?.[0] === customer.idPerson) {
                    customer.idPerson = null;
                    return this.customerService.setCustomer(customer).pipe(
                        tap(() => this.onClose('saved'))
                    );
                }
                return throwError(() => error);
            }),
            finalize(() => {
                this.isLoading = false;
                this.onChange(true);
            })
        ).subscribe());
    }

    public onClose(event: string): void {
        this.prismeLogger.sendDataToPrisme(
            'SESSION',
            {
                message: 'Entrer Session'
            }
        );
        if (event === 'saved' || event.includes('redirect')) {
            this.close(event);
        }

    }

    private _checkScoring(idPerson: string): void {
        this._subscriptions.push(this.scoringService.getScoring(this.cartService.cartModel, idPerson).pipe(
            tap((scoring: ScoringModel) => {
                this._scoringData = scoring;
                if (this._scoringData.isClientBlockedByScoring()) {
                    this.scoringService.set(this._scoringData);
                }
                this.checkQuotesScoringError();
            })
        ).subscribe());
    }

    private _checkFaiContract(): void {
        this._subscriptions.push(this.contractService.hasFaiContractOrCommand(this.customer.idPerson).subscribe((isEligible: boolean) => {
            if (isEligible) {
                this.cmsService
                    .getMessageFaiPremium()
                    .subscribe((response: string) => {
                        if (response) {
                            this.searchObject.infoMessage = response;
                        } else {
                            this.onChange(true);
                        }
                    });
            }
        }));
    }

    private _initialFromAttr(): void {
        const rstFormFct = this._resetForms().bind(this);
        rstFormFct();
    }

    private _checkIdProspectInput(): void {
        this.showIdProspectInput = !this.salesforceService.prefilledInfo?.idProspect && this.calltype === 'out';

        const regex = new RegExp(/^\d{12,13}$/);
        this._subscriptions.push(
            this.searchForm.controls.idPrm.statusChanges
                .pipe(
                    debounceTime(200)
                )
                .subscribe(()=> {
                    const idPrmValue = this.searchForm.get('idPrm').value as string;
                    // no need to save idPrm if wrong length
                    if (regex.exec(idPrmValue)) {
                        this._updateSalesForceData(idPrmValue);
                    }
                })
        );
    }

    private _subscribeFromChange(): void {
        this._subscriptions.push(
            merge(this.searchForm.valueChanges, this.searchForm.statusChanges)
                .pipe(filter(()=>this.isCompleted))
                .subscribe(this._resetForms().bind(this))
        );
    }

    private _resetForms(): () => void {
        return (): void => {
            this.searchObject = Object.assign({}, this.searchObject, this._getSearchPersonType(this.searchForm.get('searchItem').value));
            if (this.searchForm.invalid) {
                this.searchObject = {};
            }
            this.isSubmitted = false;
            this.isCompleted = false;
        };
    }

    private _getSearchPersonType(value: string): BasicObject {
        return {
            isProspect: REGEXS.NOT_EMAIL_BBOX_REGEXP.test(value) && REGEXS.EMAIL_REGEXP.test(value),
            isClient: REGEXS.PHONE_REGEXP.test(value) || REGEXS.EMAIL_BBOX_REGEXP.test(value)
            || REGEXS.VOIP_REGEXP.test(value),
            isNumber: REGEXS.PHONE_REGEXP.test(value)
        };
    }

    private _autocompleteFromSalesForceData(): void {

        const customer = this.salesforceService.prefilledInfo?.customer;
        if (customer?.idPerson || customer?.email || customer?.phone) {
            const puId: string = this.salesforceService.prefilledInfo.customer?.idPerson;

            if (puId) {
                this.isLoading = true;
                this._subscriptions.push(
                    this.customerService.getPersonneCoordonees(puId)
                        .subscribe({next: (personne) => {
                            this.isLoading = false;
                            const mainEmail: string = personne?.mainEmail?.email;
                            if (mainEmail) {
                                this.searchForm.controls.searchItem.setValue(mainEmail);
                                this.onSearch();
                            } else if (this.salesforceService.prefilledInfo?.salesType === 'FAI') {
                                // @TODO: Don't navigate to FAI now but once the form is completed
                                this.router.navigate(['fai']);
                            }
                        }, error: () => {
                            this.isCompleted = true;
                            this.isLoading = false;
                            this.searchObject.isError = true;
                            this.searchObject.descriptionError = 'ID personne non reconnu, veuillez rechercher par adresse email';
                        }})
                );
            } else {
                const searchItem = customer?.email || customer?.phone;
                this.searchForm.controls.searchItem.setValue(searchItem);
                this.onSearch();
            }
        }
    }

    private _loadSalesForcePrefildCustomer(): void {
        this._subscriptions.push(
            this.customerService.setCustomer(this.salesforceService.prefilledInfo.customer).subscribe()
        );
    }

    private _updateSalesForceData(iDProspect: string): void {
        this.salesforceService.prefilledInfo.idProspect = iDProspect;
        this.salesforceService.save(this.salesforceService.prefilledInfo);
    }
}
