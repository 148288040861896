import {Injectable} from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';


@Injectable()
export class CleanPayloadInterceptor implements HttpInterceptor {

    public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (
            (request.method.toLowerCase() === 'post' || request.method.toLowerCase() === 'put') &&
            request.body &&
            typeof request.body === 'object'
        ) {
            const reqClone = request.clone({body: this._cleanPayload(request.body)});
            return next.handle(reqClone);
        }
        return next.handle(request);
    }

    private _cleanPayload(body: unknown): unknown {
        if (Array.isArray(body)) {
            return body.map(o => this._cleanPayload(o));
        }
        if (typeof body === 'object' && body !== null && body !== undefined) {
            const cleanObj: Record<string, unknown> = {};
            Object.keys(body).forEach((field) => {
                if (typeof body[field] === 'object' && body[field] !== null && body[field] !== undefined) {
                    cleanObj[field] = this._cleanPayload(body[field]);
                } else if (body[field] !== '') {
                    cleanObj[field] = body[field];
                }
            });
            return cleanObj;
        }

        return body;
    }
}
