import {Component, EventEmitter, Input, ViewChild, Output} from '@angular/core';
import { WetoComponent } from '../weto/weto.component';
import {Observable, ReplaySubject, Subject} from 'rxjs';

@Component({
    selector: 'tlv-weto-voip',
    templateUrl: './weto-voip.component.html',
    styleUrls: ['./weto-voip.component.scss']
})
export class WetoVoipComponent extends WetoComponent {
    @Output() loaded = new EventEmitter<boolean>();
    @Output() onError = new EventEmitter<boolean>();
    @Input() reservedVoip: string;
    @ViewChild('widgetWetoVoip') widget?: { nativeElement: any };
    public endToLoad = new Subject<boolean>();
    private submit$ = new ReplaySubject<string>(1);

    public render(): void {
        this.widget?.nativeElement.render(
            {
                hiddenController: true,
                typeVente: 'VA',
                api: {
                    baseUrl: this.configService.baseConfig.resourceUrl,
                    token: this.oauth2Service.access_token ?? null,
                    xSource: 'portailvente_tlv'
                }
            },
            this.eligibilityService?.currentCart?.id, 3, [], this.reservedVoip
        );

        this._ngZone.runOutsideAngular(() => {
            this.widget?.nativeElement.onEvent(this.onEvent.bind(this));
        });
    }

    public submit(): Observable<string> {
        this.submit$ = new ReplaySubject<string>(1);
        this.widget?.nativeElement?.next();
        return this.submit$;
    }

    public onEvent(e: any): void {

        this._ngZone.run(() => {

            if (e.type === 'STEP_STATUS') {
                if (e.event.status === 'READY') {
                    this.loaded.emit(true);
                    this.endToLoad.next(true);
                }
            }

            if (e.event?.type === 'TECHNICAL') {
                this.onError.emit(true);
                this.toastService.error(
                    'Erreur: Un problème technique sur la réservation VOIP est survenu, merci de réessayer',
                    {duration: 3000}
                );
            }
            if (e.event?.type === 'FUNCTIONAL') {
                this.onError.emit(true);
                this.toastService.error('Erreur: ' + e.event?.description, {duration: 3000});
            }

            if (e.type === 'END') {
                this.submit$.next(e.event?.result?.voip);
                this.submit$.complete();
            }

        });
    }

}
