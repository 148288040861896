<div class="columns is-centered is-multiline is-marginless has-text-centered" data-cy="open-banking-cancellation-modal">
    <div class="column is-12">
        <p class="has-text-weight-semibold is-size-2">Open banking refusé</p>
    </div>
    <div class="column is-9">
        <p *ngIf="message" class="is-size-5 has-text-weight-semibold">
            {{message}}
        </p>
    </div>
    <div class="column is-9">
        <div class="columns is-marginless is-multiline">
            <div class="column is-6">
                <button class="is-primary button is-fullwidth" data-cy="open-banking-cancellation-end-call"
                    (click)="ref.close({endCall: true})">
                    Terminer la session
                </button>
            </div>
            <div class="column is-6">
                <button class="is-secondary button is-fullwidth" data-cy="open-banking-cancellation-end-continue"
                    (click)="ref.close({endCall: false})">
                    Continuer le parcours
                </button>
            </div>
        </div>
        <div class="is-divider has-background-white"></div>
    </div>
</div>