<div class="columns is-center is-marginless has-text-centered">
    <div class="column is-12">
        <span class="icon is-huge is-circled has-background-grey-light has-text-secondary click-collect-icon"><i class="tri-stopwatch-click-collect"></i></span>

        <p class="title is-size-4" bytelTranslate="panier.livraison.methodes_livraison.modal.titre">En savoir plus sur le Click & Collect</p>

        <div>
            <p class="is-size-7">IMPORTANT : Seule la personne dont le nom est mentionné sur le bon de commande ou la facture sera autorisée à retirer la commande, sur présentation de sa pièce d'identité.</p>

            <p class="is-size-7">Votre commande sera mise à disposition dans les 2 heures pour toute commande passée avant 17 heures aux heures d'ouverture de la boutique du lundi au samedi et avant 15h30 en cas d'ouverture exceptionnelle des boutiques le dimanche ou les jours fériés.</p>

            <p class="is-size-7">Retrait possible à compter de la confirmation de la mise à disposition de la commande par Bouygues Telecom. Cette confirmation est faite par l'envoi d'un SMS et d'un e-mail.</p>

            <p class="is-size-7">L'envoi de ces communications peut prendre jusqu'à 24 heures, délai maximum de la part des équipes Bouygues Telecom pour valider votre commande.</p>

            <p class="is-size-7">A l'issue de cette validation, le mobile est à retirer sous 2 jours (hors dimanche) dans la boutique sélectionnée.</p>
        </div>
    </div>
</div>


