import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { NotificationsService } from '@services/notification.service';
import { NotificationModel } from '@models/notification.model';
import { NOTIFICATIONS_TYPES_LABEL } from 'src/app/constants/notifications';
import { UntypedFormControl } from '@angular/forms';
import { catchError, map, mergeMap, Observable, of, Subscription } from 'rxjs';

@Component({
    selector: 'tlv-notifications-panel',
    templateUrl: './notifications-panel.component.html',
    styleUrls: ['./notifications-panel.component.scss']
})

export class NotificationsPanelComponent implements OnInit, OnDestroy {

    @Output() closeModal: EventEmitter<any> = new EventEmitter();
    public displayNotificationsImages: any;

    public notificationsList: NotificationModel[] = [];
    public readNotificationList: Set<string> = new Set();
    public fullNotificationsList: NotificationModel[] = [];
    public notificationTypesLabelMap: typeof NOTIFICATIONS_TYPES_LABEL = NOTIFICATIONS_TYPES_LABEL;
    public sortByNotificationsTypes = new UntypedFormControl('ALL');

    private subscription: Subscription;

    constructor(
        private notificationService: NotificationsService
    ) {
        this.notificationService.notifications$.subscribe({
            next: (notifications: NotificationModel[]) => {
                this.fullNotificationsList = notifications;
                this.fullNotificationsList
                    .sort((a, b) => Number(b.priority) < Number(a.priority) ? 1 : -1)
                    .sort((a, b) => Number(a.isRead) - Number(b.isRead));
                this.notificationsList = this.fullNotificationsList;
            }
        });
    }

    public ngOnInit(): void {
        this.subscription = this.sortByNotificationsTypes.valueChanges.subscribe(value => {
            this.notificationsList = value === 'ALL' ?
                this.fullNotificationsList : this.fullNotificationsList.filter(item => item.notificationType === value);
        });
    }

    public closeNotifModal(): void {
        if (this.readNotificationList.size) {
            this._updateNotificationReaders()
                .subscribe();
        }
        this.closeModal.emit(null);
    }

    public toggleNotificationsImages(imgInfos: Record<string, unknown>): void {
        this.displayNotificationsImages = imgInfos;
    }

    public markAsRead(evt: string): void {
        this.readNotificationList.add(evt);
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    private _updateNotificationReaders(): Observable<boolean> {
        const notitificationReadersList: string[] =
            [...this.readNotificationList]
                .map(
                    notif => this.notificationsList.find(n => n.id === notif)?.id
                );

        if (!notitificationReadersList.length) {
            return of(false);
        }

        return this.notificationService.updateNotificationsReadersList(notitificationReadersList).pipe(
            catchError(()=>of(false)),
            mergeMap(()=>this.notificationService.getNotifications()),
            map(()=>true)
        );
    }

}
