<div class="column is-12">
    <div class="columns is-multiline">
        <div class="column is-7">
            <div class="field">
                <div class="control has-icons-right">
                    <input class="input has-text-tertiary autocomplete-input"
                        placeholder="Rechercher mon adresse" type="text"
                           [data-cy]="'autocomplete-address'"
                           tlvAutocomplete
                           data-cy="automatic-address-input"
                           [minLength]="1"
                           [autoSelect]="false"
                           [getValues]="getValuesAutoComplete"
                           [(ngModel)]="prediction"
                           (ngModelChange)="selectAddress($event)"
                    >
                    <span class="icon is-small">
                        <i [ngClass]="isSearching ? 'is-searching' : 'tri-search'"></i>
                    </span>
                </div>
                <p class="help is-danger" *ngIf="submitted && !prediction">Adresse requise</p>
            </div>
            <div class="field">
                <div class="control has-dynamic-placeholder has-icons-right">
                    <input [data-cy]="'autocomplete-complement'" class="input" [(ngModel)]="complementaryAddress" type="text" [pattern]="REGEXS.POSTAL_ADDRESS_REGEXP" (ngModelChange)="complementChange()"
                        placeholder="Complément d'adresse (Porte, bâtiment, palier)" maxlength="35" #complementaryField="ngModel" [ngClass]="{'is-danger': complementaryField.errors?.pattern}">
                        <span class="icon is-small" *ngIf="complementaryField.errors?.pattern"><i class="tri-exclamation-circle"></i></span>
                </div>
                <p class="help is-danger" *ngIf="complementaryField.errors?.pattern" [data-cy]="'autocomplete-complement-error'">Champ invalide, caractères incorrectes.</p>
            </div>
        </div>
    </div>
</div>
