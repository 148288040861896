<ng-container *ngIf="products.length">
    <div class="columns is-vcentered is-mobile">
        <div class="column">
            <span class="icon-and-text has-cursor toggle-basic-crosssells" (click)="showProducts = !showProducts">
                <span class="has-text-info is-size-3">{{config[type].title}} ({{products | qytCount}})</span>
                <span class="icon is-medium is-info">
                    <i [ngClass]="{'tri-arrow-right': !showProducts, 'tri-arrow-down': showProducts}"></i>
                </span>
            </span>
        </div>
    </div>
    <div class="columns is-mobile is-multiline crosssell-items" [ngClass]="{'is-collapsed': !showProducts}">
        <div class="column is-4-tablet" *ngFor="let product of products">
            <tlv-basic-crosssell-item [product]="product" [isAutoAdd]=isAutoAdd(product)></tlv-basic-crosssell-item>
        </div>
    </div>
</ng-container>
