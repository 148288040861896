import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Delivery, Product, PromotionModel, QuoteModel } from '@bytel/bytel-sales';
import { ApiOrcomActions, ApiOrcomActionsInput } from '@interfaces/api/order/order.interface';
import { PAYMENT_METHODS } from '@interfaces/payment.interface';
import { AddressModel } from '@models/cart/address.model';
import { PaymentResponseModel } from '@models/payment-response.model';
import { HotToastService } from '@ngneat/hot-toast';
import { SalesRepository } from '@repositories/sales.repository';
import { CustomerService } from '@services/customer/customer.service';
import { FaiEligibilityService } from '@services/fai/fai-eligibility.service';
import { QualificationService } from '@services/qualification.service';
import { SalesForceService } from '@services/salesforce.service';
import { SharedService } from '@services/shared.service';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, delayWhen, map, mergeMap, tap } from 'rxjs/operators';
import { SalesApiHelper } from 'src/app/helper/sales-api.helper';
import { AppointmentService } from './appointment.service';
import { CartTeleSalesService } from './cart-telesales.service';
import { DeliveryService } from './delivery.service';
import { PortabilityService } from './portability.service';

@Injectable({
    providedIn: 'root'
})
export class SalesService {

    constructor(
        private cartService: CartTeleSalesService,
        private salesRepository: SalesRepository,
        private customerService: CustomerService,
        private portabilityService: PortabilityService,
        private faiEligibilityService: FaiEligibilityService,
        private appointmentService: AppointmentService,
        private qualificationService: QualificationService,
        private deliveryService: DeliveryService,
        private toastService: HotToastService,
        private salesForceService: SalesForceService
    ) {}

    public createOrUpdateCart(): Observable<void> {
        return this.cartService.cartModel.cartId ?
            of(null) :
            this.salesRepository.createCart(this.cartService.cartModel, this.qualificationService.qualification)
                .pipe(
                    catchError((err: HttpErrorResponse) => {
                        this.toastService.error('Erreur: ' + err?.error?.error_description || SharedService.DEFAULT_API_ERROR_MESSAGE);
                        return throwError(()=>err);
                    }),
                    tap((cartId) => {
                        this.cartService.setId(cartId);
                    }),
                    map(()=>null)
                );
    }

    public checkCartIntegrity(cartId: number): Observable<any> {
        return this.salesRepository.checkCartIntegrity(cartId);
    }

    public createOrUpdateQuote(quoteIndex: number): Observable<void> {

        const quote: QuoteModel = this.cartService.cartModel.getQuote(quoteIndex, true);
        if (quote && quote.products.length > 0) {
            return quote.id ? this.salesRepository.updateParcours(
                this.cartService.cartModel.cartId,
                quote,
                this.customerService.customer,
                this.faiEligibilityService?.currentCart?.id
            ).pipe(
                mergeMap(() => this.updateProducts(quoteIndex)),
            ) : this.salesRepository.createParcours(
                this.cartService.cartModel.cartId,
                quote,
                this.customerService.customer,
                this.faiEligibilityService?.currentCart?.id
            ).pipe(
                catchError((err: HttpErrorResponse) => {
                    this.toastService.error(SharedService.handleErrorMessage(err));
                    return throwError(()=>err);
                }),
                tap((id) => this.cartService.setQuoteId(quoteIndex, id)),
                mergeMap(() => this.updateProducts(quoteIndex)),
                map(()=>null)
            );
        } else {
            return of(null);
        }
    }

    public updateProducts(quoteIndex: number): Observable<boolean> {
        const checkProducts =  this.cartService.cartModel.getAllProducts()
            .filter(p=>SalesApiHelper.IsApiProduct(p))
            .some(p=> !p.id);

        if (checkProducts) {
            return this.salesRepository.pushProducts(
                this.cartService.cartModel.cartId,
                this.cartService.cartModel.getQuote(quoteIndex),
                this.portabilityService.getPortabilityFromCartModel(quoteIndex),
                this.faiEligibilityService.currentCart
            ).pipe(
                catchError((err: HttpErrorResponse) => {
                    this.toastService.error(SharedService.handleErrorMessage(err));
                    return throwError(()=>err);
                }),
                delayWhen(() => {
                    this.deliveryService.clear();
                    const currentDelivery = this.cartService.cartModel.getQuote(quoteIndex)?.getProductByType(Delivery);
                    if (currentDelivery) {
                        return this.cartService.removeProduct(currentDelivery, quoteIndex);
                    }
                    return of(true);
                }),
                tap(() => this.cartService.saveToStorage())
            );
        } else {
            return of(false);
        }
    }

    public addCoupon(promotion: PromotionModel): Observable<boolean> {
        return this.salesRepository.pushCoupon(this.cartService.cartModel, promotion);
    }

    public addManualPromotion(promotion: PromotionModel): Observable<boolean> {
        return this.salesRepository.pushManualPromotion(this.cartService.cartModel, promotion);
    }

    public removeCoupon(): Observable<boolean> {
        return this.salesRepository.removeCoupon(this.cartService.cartModel);
    }

    public pushPaymentMode(url: string, params: ApiOrcomActionsInput): Observable<PaymentResponseModel> {
        return this.salesRepository.pushPaymentMode(url, params);
    }

    public createOrder(paymentType: string, differedFMSPayment: boolean): Observable<PaymentResponseModel> {
        const paymentMethod = this.cartService.cartModel.fundingMethod?.paymentMethods.find(
            (method: string) => method === paymentType
        );
        return this.salesRepository.createOrder(paymentMethod, differedFMSPayment, this.cartService.cartModel.cartId).pipe(
            catchError((err: HttpErrorResponse) => {
                this.toastService.error(SharedService.handleErrorMessage(err));
                return throwError(()=>err);
            }),
            mergeMap((data: ApiOrcomActions) => {
                const paymentUrl: string = data._actions?.PayerCommande?.action || null;
                if (paymentUrl && paymentType === PAYMENT_METHODS.CB) {
                    return this._pushPaymentData(data);
                } else {
                    if (this.qualificationService.isDadCampaign()) {
                        this.cartService.getIdOpportunitiesUsed();
                    }
                    const orderId: string = this.salesForceService.prefilledInfo?.orderId;
                    return of(new PaymentResponseModel({redirectUrl: `/order-success/${data.orcomId ?? orderId}`}));
                }
            })
        );
    }

    public notifyClientWithOrderSummary(cartId: string, personId: string): Observable<boolean> {
        return this.salesRepository.notifyClientWithOrderSummary(cartId, personId).pipe(
            catchError(()=>of(false))
        );
    }

    public updateIban(params: { iban: string }): Observable<boolean[]> {

        const res: Observable<boolean>[] = [];
        Object.keys(this.cartService.cartModel.quotes)
            .forEach(
                (key) => {
                    res.push(this.salesRepository.checkAndUpdateIban(
                        params,
                        this.cartService.cartModel.cartId,
                        this.cartService.cartModel.getQuote(Number(key)).id
                    ).pipe(
                        catchError((err: HttpErrorResponse) => {
                            this.toastService.error(SharedService.handleErrorMessage(err));
                            return throwError(()=>err);
                        })
                    )
                    );
                });
        return forkJoin(res);
    }

    public updateProduct(product: Product, index?: number): Observable<boolean> {
        return this.salesRepository.updateProduct(
            this.cartService.cartModel.cartId,
            this.cartService.cartModel.getQuote(index),
            product,
            this.portabilityService.getPortabilityFromCartModel(index),
            this.faiEligibilityService.currentCart,
            this.appointmentService.currentAppointment,
        ).pipe(
            catchError((err: HttpErrorResponse) => {
                this.toastService.error(SharedService.handleErrorMessage(err));
                return throwError(()=>err);
            }),
        );
    }

    public pushBilling(address: AddressModel): Observable<void>[] {

        const res: Observable<void>[] = [];
        Object.keys(this.cartService.cartModel.quotes.filter(q=>!!q.products.length))
            .forEach(
                (key) => {
                    res.push(
                        this.salesRepository.pushBilling(
                            this.cartService.cartModel.cartId,
                            this.cartService.cartModel.getQuote(Number(key)),
                            address
                        ).pipe(
                            catchError((err: HttpErrorResponse) => {
                                this.toastService.error(SharedService.handleErrorMessage(err));
                                return throwError(()=>err);
                            }),
                        ));
                }
            );
        return (res.length > 0) ?  res : [of()];

    }

    private _pushPaymentData(data: ApiOrcomActions): Observable<PaymentResponseModel> {
        const paymentUrl: string = data._actions?.PayerCommande?.action || null;
        return this.pushPaymentMode(paymentUrl, data._actions?.PayerCommande?.input).pipe(
            catchError((err: HttpErrorResponse) => {
                this.toastService.error(SharedService.handleErrorMessage(err));
                return throwError(()=>err);
            }),
        );
    }

}
