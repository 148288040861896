<div class="modal-representant-legal is-tri">
    <div class="columns is-multiline is-marginless is-centered has-text-centered">
        <div class="column is-narrow">
            <span class="icon has-text-warning is-huge">
                <i class="tri-exclamation-circle"></i>
            </span>
        </div>

        <div class="column is-12">
            <div>
                <p>{{firstLine}}</p>
                <p>{{secondLine}} </p>
            </div>
        </div>

        <div class="column is-12">
            <div class="buttons is-multiline">
                <button class="button is-small is-primary" (click)="continue()" [class.is-hidden]="!ref?.data?.continueButton">
                    <span class="icon-and-text">
                        <span>Poursuivre</span>
                      </span>
                </button>
                <button class="button is-small is-primary is-outlined"(click)="skip()">
                    <span class="icon-and-text">
                        <span>Terminer session</span>
                      </span>
                </button>
            </div>
        </div>

    </div>
</div>
