import { Injectable } from '@angular/core';
import { ConfigurationService, Oauth2ResourcesService, Oauth2Service } from '@common-modules';
import { CDCStatusList, INotificationData, NOTIFICATION_READ_STATUS } from '@interfaces/api/notification.interface';
import { NotificationModel } from '@models/notification.model';
import { catchError, map, Observable, of } from 'rxjs';
import { NOTIFICATIONS_TYPES_LABEL } from '../constants/notifications';
import bind from '../helper/decorators/bind';

@Injectable({
    providedIn: 'root'
})
export class NotificationsRepository {

    constructor(
        private readonly oauth2Ressource: Oauth2ResourcesService,
        private config: ConfigurationService,
        protected oauth2: Oauth2Service
    ) {}

    public getNotifications(): Observable<NotificationModel[]> {
        if (!this.config.data_refconf?.enable_notifications) {
            return of([]);
        }

        let notificationsSbs$;
        if (this._isInternalProfile()) {
            notificationsSbs$ = this.oauth2Ressource
                .useSalesApi()
                .notifications()
                .get()
                .pipe(
                    map((notifications: INotificationData[]) => notifications.filter(this._isNotificationInScope))
                );
        } else {
            notificationsSbs$ = this.oauth2Ressource
                .useSalesApi()
                .notificationsActive()
                .get()
                .pipe(
                    map((notifications: INotificationData[]) => notifications.filter(this._isNotificationInScope))
                );
        }

        return notificationsSbs$
            .pipe(
                catchError(() => of([])),
                map((notifications: INotificationData[]) => notifications.filter(this._isNotificationExpired)),
                map(this._generateNotificationModel)
            );
    }

    public updateNotificationsReaders(notificationId: string): Observable<boolean> {
        if (this._isInternalProfile()) {
            return of(true);
        };

        return this.oauth2Ressource
            .useSalesApi()
            .notificationsStatus(notificationId)
            .post({notificationId})
            .pipe(
                catchError(() => of([])),
                map(()=>true)
            );
    }

    private _isInternalProfile(): boolean {
        if (this.oauth2.jwtPayload.matricule) {
            return false;
        }
        return this.oauth2.jwtPayload.roles.includes('PA_APP_NOTIFICATIONS_EXPLOITANT_SP') ||
            this.oauth2.jwtPayload.roles.includes('PA_APP_NOTIFICATIONS_ADMINISTRATEUR_SP');
    }

    @bind
    private _isNotificationExpired(notif: INotificationData): boolean {
        return Date.now() < new Date(notif.dateFin).getTime();
    }

    @bind
    private _isNotificationInScope(notif: INotificationData): boolean {
        return notif.porteeDeLaNotification
            .map((scope: string) => scope.toUpperCase())
            .some((scope: string) => Object.keys(CDCStatusList).includes(scope.toUpperCase()));
    }

    @bind
    private _generateNotificationModel(data: INotificationData[]): NotificationModel[] {
        return data.map(notif=>({
            id: notif.id,
            login: notif.identifiant,
            title: notif.titre,
            description: notif.description,
            createdAt: new Date(notif.dateCreation),
            endDate: new Date(notif.dateFin),
            startDate: new Date(notif.dateDebut),
            updatedAt: new Date(notif.dateCreation),
            notificationType: notif.typeNotification as NOTIFICATIONS_TYPES_LABEL,
            isRead: notif.statutLecture === NOTIFICATION_READ_STATUS.LU,
            priority: notif.priorite,
            isResolved: notif.estResolu,
            notificationScope: notif.porteeDeLaNotification
        }));
    }

}
