import { ChangeDetectorRef, Component, OnChanges, OnDestroy, ViewChild } from '@angular/core';
import { Delivery } from '@bytel/bytel-sales';
import { AddressComponent } from '@components/checkout/step/delivery/address/address.component';
import { AddressModel } from '@models/cart/address.model';
import { AddressService } from '@services/checkout/address.service';
import { CartTeleSalesService } from '@services/checkout/cart-telesales.service';
import { DeliveryService } from '@services/checkout/delivery.service';
import { SalesForceService } from '@services/salesforce.service';
import { OrderModel } from '@models/order/order.model';
import { SalesService } from '@services/checkout/sales.service';
import { StepperService } from '@services/checkout/stepper.service';
import { CustomerService } from '@services/customer/customer.service';
import { concat, Subscription } from 'rxjs';
import { SimService } from '@services/checkout/sim.service';

@Component({
    selector: 'tlv-delivery-step',
    templateUrl: './delivery.component.html',
    styleUrls: ['./delivery.component.scss']
})
export class DeliveryComponent implements OnChanges, OnDestroy {
    public page: string = 'Livraison';
    public sameAddress: boolean = true;
    public isLoading: boolean = false;
    public initialBillingAddress: AddressModel;
    public initialShippingAddress: AddressModel;
    public deliveryLoading: boolean = false;
    public shippingAddress: AddressModel;
    public showDeliveryMethod: boolean = false;
    public orderRecovery: OrderModel;
    public recoveredBillingAddress: string;
    public recoveredShippingAddress: string;
    public shouldSkipShippingAddress: boolean = false;
    public hasESimInCart: boolean = false;
    public buttonText = 'Valider';

    @ViewChild('shippingAddressComponent') shippingAddressComponent: AddressComponent;

    private _subscriptions: Subscription[] = [];

    constructor(
        public cartTeleSalesService: CartTeleSalesService,
        public addressService: AddressService,
        public changeDetectorRef: ChangeDetectorRef,
        private customerService: CustomerService,
        private deliveryService: DeliveryService,
        private salesService: SalesService,
        private stepperService: StepperService,
        private salesForceService: SalesForceService,
        private simService: SimService) {
        this.orderRecovery = this.salesForceService.prefilledInfo.order;
        this.initialBillingAddress = this.addressService.currentBillingAddress || null;
        this.initialShippingAddress = this.addressService.currentShippingAddress || null;
        if (this.orderRecovery) {
            this.initialBillingAddress = this.orderRecovery.cart.delivery.billing;
            this.initialShippingAddress = !this.initialBillingAddress.isSameAddress(this.orderRecovery.cart.delivery.shipping) ?
                this.orderRecovery.cart.delivery.shipping : null;
            this.recoveredBillingAddress = this.initialBillingAddress.getInline();
            this.recoveredShippingAddress = this.initialShippingAddress?.getInline() ?? this.recoveredBillingAddress;
        }
        if (this.initialShippingAddress){
            this.sameAddress = false;
        }

        this.hasESimInCart = this.simService.hasESimInCart(this.cartTeleSalesService.cartModel);
        this._subscriptions.push(this.deliveryService.errorShippingAddress$.subscribe((error: string) => {
            if (!!error) {
                this.showDeliveryMethod = false;
            }
            this.shippingAddressComponent?.setShippingModesError(error);
        }));
    }

    public ngOnChanges(dataChange): void {
        if (dataChange.isLoading) {
            this.buttonText = this.isLoading ? 'Traitement en cours...' : 'Valider';
        }
    }

    public ngOnDestroy(): void {
        this.changeDetectorRef?.detach();
        this._subscriptions.forEach(sub => sub.unsubscribe());
    }

    public submitBilling(address: AddressModel): void{
        this.deliveryService.clear();
        this.addressService.currentBillingAddress = address;
        this.showDeliveryMethod = !!address;
        this.shippingAddress = this.addressService.currentShippingAddress || this.addressService.currentBillingAddress;
        this.changeDetectorRef.detectChanges();
    }

    public submitShipping(address: AddressModel): void{
        this.shippingAddress = null;
        if (this.sameAddress){
            this.addressService.currentShippingAddress = null;
        } else {
            this.addressService.currentShippingAddress = address;
        }
        this.shippingAddress = address;
        this.showDeliveryMethod = !!address && (this.addressService.currentBillingAddress.valid ||
            this.addressService.currentBillingAddress.confirmed);
        this.changeDetectorRef.detectChanges();
    }

    public submit(): void {
        this._setIsLoading(true);
        const customer = this.customerService.customer;
        customer.address = this.addressService.currentBillingAddress;

        this._subscriptions.push(concat(...this.salesService.pushBilling(this.addressService.currentBillingAddress),
            ...(this.customerService.customer.isUpdatable ? [this.customerService.updateCustomer(customer)] : []))
            .subscribe({
                next: () => {},
                error: () => this._setIsLoading(false),
                complete: () => this.stepperService.goToNextStep()
            })
        );
    }

    public editAddress(): void{
        this._subscriptions.push(
            this.cartTeleSalesService.removeProduct(this.cartTeleSalesService.cartModel.getQuote().getProductByType(Delivery))
                .subscribe()
        );
        this.sameAddress = false;
        this.showDeliveryMethod = false;
        this.shippingAddressComponent.enableModification();
    }

    public onChangeSameInput(value: boolean): void{
        this.deliveryLoading = true;
        this.showDeliveryMethod = value;
        this._subscriptions.push(
            this.cartTeleSalesService.removeProduct(this.cartTeleSalesService.cartModel.getQuote().getProductByType(Delivery))
                .subscribe(()=>{
                    // This subscription is eventually called after the shipping address is set, so we make sure it's not reset to billing
                    // address if the client intended to set different address for billing and shipping
                    if (value === true) {
                        this.addressService.currentShippingAddress = null;
                    }
                    this.shippingAddress = this.addressService.currentShippingAddress || this.addressService.currentBillingAddress;
                    this.deliveryLoading = false;
                    this.changeDetectorRef.detectChanges();
                })
        );
    }

    private _setIsLoading(value: boolean): void {
        Promise.resolve().then(()=>this.isLoading = value);
    }
}
