<section [ngClass]="isLoading && 'is-loading'" [formGroup]="campaignForm" data-cy="qualification-step">
    <tlv-hero-title title="Qualification" iconName="tri-advisor"></tlv-hero-title>

    <div class="box is-flat is-borderless is-radiusless force-full-height">
        <div class="box-content has-text-centered">
            <div class="columns is-centered">
                <div class="column is-5">
                    <section class="stepper-wrapper">
                        <div [ngClass]="{'is-current': !isLoading && currentStep === STEPS_NAMES.QUALIFICATION, 'has-cursor is-done': currentStep === STEPS_NAMES.CUSTOMER_SEARCH}"
                            (click)="previousStep()"
                            [attr.title]="currentStep === STEPS_NAMES.CUSTOMER_SEARCH ? 'Retour étape qualification' : null"
                            class="stepper-item" data-label="Qualification" step="1">
                            <div class="step-label">Qualification</div>
                        </div>
                        <div [ngClass]="{'is-current': currentStep === STEPS_NAMES.CUSTOMER_SEARCH, 'is-done': isCustomerSearchCompleted === true}" class="stepper-item" data-label="Recherche client" step="2">
                            <div class="step-label">Recherche client</div>
                        </div>
                        <div class="step-count">{{currentStep}}/2</div>
                    </section>
                </div>
            </div>
            @if(currentStep !== STEPS_NAMES.QUALIFICATION) {
                <div class="columns is-centered">
                    <div class="column is-narrow">
                        <div class="is-flex is-justified-center has-text-left" data-cy="call-qualification-summary">
                            <div class="notification is-small is-info has-body is-marginless">
                                <div class="icon">
                                    <i class="tri-exclamation-circle"></i>
                                </div>
                                <div class="body">
                                    <ul>
                                        <li data-cy="call-qualification-calltype-summary">
                                            <span class="has-text-weight-semibold">Type d'appel</span>
                                            : <span class="is-capitalized">{{campaignForm.get('calltype').value === campaignKeys.campagnesEntrant.label ? 'entrant' : 'sortant'}}</span>
                                        </li>
                                        <li data-cy="call-qualification-campaign-summary">
                                            <span class="has-text-weight-semibold">Campagne</span>
                                            : {{campaignForm.get('campaign').value.libelle}}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            @if (currentStep === STEPS_NAMES.QUALIFICATION) {
                <section>
                    <div class="buttons">
                        <button data-cy="call-in" [ngClass]="campaignForm.get('calltype').value === campaignKeys.campagnesEntrant.label && 'is-active'" class="button is-tertiary is-small" (click)="updateQualifictionData({calltype: campaignKeys.campagnesEntrant.label})">Appel entrant</button>
                        <button data-cy="call-out" [ngClass]="campaignForm.get('calltype').value === campaignKeys.campagnesSortant.label && 'is-active'" class="button is-tertiary is-small" (click)="updateQualifictionData({calltype: campaignKeys.campagnesSortant.label})">Appel sortant</button>
                    </div>
                    <br />
                    <div class="columns is-centered is-marginless" *ngIf="campaignsList[campaignForm.get('calltype').value]?.length">
                        <div class="column is-5">
                            <div class="field">
                                <div class="control">
                                    <div class="select">
                                        <select data-cy="call-list" formControlName="campaign" name="campaign">
                                            <option id="campaign-0" [ngValue]="null">{{campaignForm.get('calltype').value === campaignKeys.campagnesEntrant.label ? 'Sélectionner la provenance de l\'appel' : 'Sélectionner une campagne'}}</option>
                                            <ng-container *ngFor="let campaign of campaignsList[campaignForm.get('calltype').value]; let index = index;">
                                                <option id="campaign-{{index+1}}" [ngValue]="campaign">{{campaign.libelle}}</option>
                                            </ng-container>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    @if (error) {
                        <div class="columns is-centered">
                            <div class="column is-narrow">
                                <div class="notification is-small is-danger has-body">
                                    <div class="icon">
                                        <i class="tri-exclamation-circle"></i>
                                    </div>
                                    <div class="body">
                                        <h5 class="title">Erreur récupération liste campagne</h5>
                                        <p class="has-text-weight-semibold">{{error}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </section>
            }
            @if (currentStep === STEPS_NAMES.CUSTOMER_SEARCH && isCustomerSearchCompleted === false) {
                <section>
                    <tlv-customer-search
                        id="customer-search"
                        formControlName="customerSearch"
                        [calltype]="campaignKeys[campaignForm.get('calltype').value]?.value"
                        (onRedirectAfterSearch)="redirectAfterSearchUrl = $event"
                        (onCustomerSearchComplete)="endCustomerSearch()" />
                </section>
            }
        </div>

        <div class="box-content has-text-centered">
            <div class="columns is- is-multiline">
                @if (isCustomerSearchCompleted) {
                    <div class="column is-12">
                        <tlv-partial-scoring />
                    </div>
                }
                <div class="column">
                    <div class="buttons">
                        @if (isCustomerSearchCompleted === true || isClientLockByScoring === true) {
                            <button data-cy="submit" class="is-primary button" [ngClass]="isLoadingData && 'is-loading'" [disabled]="campaignForm?.invalid || !!isClientLockByScoring" (click)="validateQualificationCall()">Continuer la vente</button>
                        }
                    </div>
                </div>
            </div>
        </div>

    </div>
</section>
