import { Component, OnDestroy } from '@angular/core';
import { StepperService } from '@services/checkout/stepper.service';
import { Subscription } from 'rxjs';
import bind from '../../../helper/decorators/bind';
import { IStep, STEP_STATUS } from '@interfaces/step.interface';
import { Router } from '@angular/router';

@Component({
    selector: 'tlv-stepper',
    templateUrl: './stepper.component.html',
    styleUrls: ['./stepper.component.scss']
})
export class StepperComponent implements OnDestroy {

    public steps: IStep[] = [];
    public readonly STATUS = STEP_STATUS;
    private _subscriptions: Subscription[] = [];

    constructor(
        private step: StepperService,
        private router: Router
    ) {
        this._subscriptions.push(
            this.step.change$.subscribe(this.updateSteps)
        );
    }

    public ngOnDestroy(): void{
        this._subscriptions.forEach((sub)=>sub.unsubscribe());
    }

    public goToStep(step: IStep): void {
        const currentIndexStep = this.step.getStepIndex(this.step.getCurrentStep());
        const nextIndexStep = this.step.getStepIndex(step);
        if (step.canAccess() && nextIndexStep < currentIndexStep) {
            this.router.navigate(step.route,{queryParams:{autoSubmit:false}});
        }
    }

    @bind
    public updateSteps(steps: IStep[]): void{
        this.steps = steps.filter((step)=>step.canDisplay());
    }

}
