import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ConfigurationService } from '@common-modules';
import { SettingsService } from '@services/settings.service';
import { Subscription } from 'rxjs';


enum ACCORDION_TYPES {
    SALESFORCE = 'SALESFORCE',
    REPRISE_COMMANDE = 'REPRISE_COMMANDE',
    REPRISE_ID = 'REPRISE_ID',
    MAGENTO_BO = 'MAGENTO_BO',
    LISTE_IHM = 'LISTE_IHM',
    BRANCHES_EPHEMERES = 'BRANCHES_EPHEMERES',
}

interface SimpleValues {
    id: string;
    name: string;
};

@Component({
    selector: 'tlv-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss']
})

export class SettingsComponent implements OnInit, OnDestroy {
    public ACCORDION_TYPES = ACCORDION_TYPES;
    public currentAccordion: ACCORDION_TYPES = null;

    public salesforceUrl = '';
    public commandekoUrl = '';
    public reprisepuUrl = '';

    public apRegexPattern = /^ap\d{4}$/;
    public ap_version: string;
    public environments: string[] = [];

    public typeAppelOptions: SimpleValues[] = [
        { id: 'entrante', name: 'ENTRANTE'},
        { id: 'sortant', name: 'SORTANT'}
    ];

    public typeContactOptions: SimpleValues[] = [
        { id: 'cc', name: 'CC'},
        { id: 'cp', name: 'CP'},
        { id: 'pc', name: 'PC'},
        { id: 'pp', name: 'PP'}
    ];

    public typeVenteOptions: SimpleValues[] = [
        { id: 'fai', name: 'FAI'},
        { id: 'mobile', name: 'MOBILE'}
    ];

    public leadSalutationsOptions: SimpleValues[] = [
        { id: 'm.', name: 'M.'},
        { id: 'mme.', name: 'MME.'}
    ];

    public apList: { value: string }[] = [
        { value: 'ap1000'},
        { value: 'ap1001'},
        { value: 'ap1002'},
        { value: 'ap1003'},
        { value: 'ap1100'},
        { value: 'ap1101'},
        { value: 'ap2000'},
        { value: 'ap2001'},
        { value: 'ap2002'},
        { value: 'ap2100'},
        { value: 'ap2101'},
        { value: 'ap3000'},
        { value: 'ap3001'},
        { value: 'ap3002'},
        { value: 'ap3100'},
        { value: 'ap3101'},
        { value: 'ap4000'},
        { value: 'ap4001'},
        { value: 'ap4002'},
        { value: 'ap4100'},
        { value: 'ap4101'}
    ];

    public urlGeneratorForm = new FormGroup({
        typeappel: new FormControl(''),
        provenanceappel: new FormControl(''),
        codecampagne: new FormControl(''),
        typecontact: new FormControl(''),
        type_vente: new FormControl(''),
        lead_email: new FormControl(''),
        lead_id: new FormControl(''),
        pu_id: new FormControl(''),
        prm_id: new FormControl(''),
        lead_codepostal: new FormControl(''),
        lead_ville: new FormControl(''),
        lead_nomdevoie: new FormControl(''),
        lead_numdevoie: new FormControl(''),
        lead_compvoie: new FormControl(''),
        lead_salutation: new FormControl(''),
        lead_lastname: new FormControl(''),
        lead_firstname: new FormControl(''),
        lead_mobile: new FormControl(''),
        sourceprospect: new FormControl(''),
        caseopportunite: new FormControl(''),
    });

    public repriseCommandeForm = new FormGroup({
        commandeId: new FormControl('', [Validators.required]),
    });

    public repriseIdPuForm = new FormGroup({
        pu_id: new FormControl('', [Validators.required]),
    });

    private _getUrlValueSubs: Subscription[] = [];
    private _getEnvEphSubs: Subscription[] = [];

    constructor(private configurationService: ConfigurationService, private router: Router, private settingsService: SettingsService) {
        this._getUrlValueSubs.push(this.urlGeneratorForm.valueChanges.subscribe(() => {
            const urlData = this.urlGeneratorForm.value;
            const linkUrl = `https://${this.ap_version}.tlv.portailvente.apps.ocp-1.pin.prd.mlb.nbyt.fr/A360?${Object.entries(urlData)
                .filter(([, value]) => value !== '')
                .map(([key, value]) => `${encodeURIComponent(key.toUpperCase())}=${encodeURIComponent(value)}`)
                .join('&')}`;
            this.salesforceUrl = linkUrl;
        }));

        this._getUrlValueSubs.push(this.repriseCommandeForm.valueChanges.subscribe(() => {
            const urlData = this.repriseCommandeForm.value;
            // eslint-disable-next-line max-len
            const linkUrl = `https://${this.ap_version}.tlv.portailvente.apps.ocp-1.pin.prd.mlb.nbyt.fr/#/reprise-commande?${Object.entries(urlData)
                .filter(([, value]) => value !== '')
                .map(([key, value]) => `${encodeURIComponent(key.toUpperCase())}=${encodeURIComponent(value)}`)
                .join('&')}`;
            this.commandekoUrl = linkUrl;
        }));

        this._getUrlValueSubs.push(this.repriseIdPuForm.valueChanges.subscribe(() => {
            const urlData = this.repriseIdPuForm.value;
            const linkUrl = `https://${this.ap_version}.tlv.portailvente.apps.ocp-1.pin.prd.mlb.nbyt.fr/?${Object.entries(urlData)
                .filter(([, value]) => value !== '')
                .map(([key, value]) => `${encodeURIComponent(key.toUpperCase())}=${encodeURIComponent(value)}`)
                .join('&')}`;
            this.reprisepuUrl = linkUrl;
        }));
    };

    public autoComplete(): void {
        this.urlGeneratorForm.patchValue({
            typeappel: 'ENTRANTE',
            provenanceappel: 'DAD',
            codecampagne: '',
            typecontact: '',
            type_vente: '',
            lead_email: 'dan_gfgfgisr@yopmail.com',
            lead_id: '00Q0D000007jCGxUAM',
            pu_id: '',
            prm_id: '',
            lead_codepostal: '75015',
            lead_ville: 'PARIS 15',
            lead_nomdevoie: 'AVENUE FELIX FAURE',
            lead_numdevoie: '133',
            lead_compvoie: '',
            lead_salutation: 'M.',
            lead_lastname: 'ISRAA',
            lead_firstname: 'DAN',
            lead_mobile: '',
            sourceprospect: '',
            caseopportunite: '155988580',
        });
    };

    public ngOnInit(): void {
        if (this.configurationService.data_refconf.api.banc.toUpperCase() === 'PROD') {
            this.goToQualification();
        }
        this.ap_version = this.configurationService.data_refconf.api.banc;
    };

    public ngOnDestroy(): void {
        for (const sub of [...this._getUrlValueSubs, ...this._getEnvEphSubs]) {
            sub.unsubscribe();
        };
    };

    public getDomainFromUrl(url: string): string {
        const domain = new URL(url).hostname;
        return domain.split('.').slice(0, 2).join('.').toUpperCase();
    };

    public onAccordionOpened(): void {
        if (this.currentAccordion === ACCORDION_TYPES.BRANCHES_EPHEMERES) {
            this._getEnvEphSubs.push(this.settingsService.getEnvironmentsEph().subscribe({
                next: (res) => {
                    this.environments = res.environmentsEphUrl;
                }
            }));
        }
    };

    public onSubmitSalesforce(): void {
        if (!this.salesforceUrl) {
            window.open(
                `https://${this.ap_version}.tlv.portailvente.apps.ocp-1.pin.prd.mlb.nbyt.fr/`, '_blank'
            );
        }
        else {
            window.open(this.salesforceUrl, '_blank');
        }
    };

    public onSubmitCommandeKo(): void {
        window.open(
            `https://${this.ap_version}.tlv.portailvente.apps.ocp-1.pin.prd.mlb.nbyt.fr/#/reprise-commande?commandeId=` +
            this.repriseCommandeForm.controls.commandeId.value,
            '_blank'
        );
    };

    public onSubmitReprisePu(): void {
        window.open(
            `https://${this.ap_version}.tlv.portailvente.apps.ocp-1.pin.prd.mlb.nbyt.fr/?PU_ID=` +
            this.repriseIdPuForm.controls.pu_id.value,
            '_blank'
        );
    };

    public onSubmitPromo(): void {
        window.open(
            `https://${this.ap_version}.bo-ca-portailvente.pin.tbd.dolmen.bouyguestelecom.fr/gestion/promotions`,
            '_blank'
        );
    };

    public onSubmitNotif(): void {
        window.open(
            `https://${this.ap_version}.dashboard-portailvente.apps.ocp-1.pin.prd.mlb.nbyt.fr/gestion/notifications`,
            '_blank'
        );
    };

    public onSubmitClosing(): void {
        window.open(
            `https://${this.ap_version}.dashboard-portailvente.apps.ocp-1.pin.prd.mlb.nbyt.fr/gestion/messages`,
            '_blank'
        );
    };

    public onSubmitMagentoBo(): void {
        window.open(
            `https://${this.ap_version}.bo-portailvente.pin.tbd.dolmen.bouyguestelecom.fr/`,
            '_blank'
        );
    };

    public goToQualification(): void {
        this.router.navigate(['qualification']);
    }
}
