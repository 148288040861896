import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InputSkeepers, ResponseSkeepers } from '@interfaces/skeepers-interface';
import { Observable, catchError, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfigurationService } from '../common-modules/services/configuration.service';

@Injectable({
    providedIn: 'root'
})
export class SkeepersRepository {

    public listRatingSkeepers: ResponseSkeepers = {};

    constructor(
        private readonly httpClient: HttpClient,
        private configService: ConfigurationService
    ) { }

    public getSkeepersRate(): Observable<any> {
        return this.httpClient.post(
            'https://awsapis3.netreviews.eu/product',
            this._prepareSkeepersInput(),
            { headers: new HttpHeaders().set('Content-Type', 'application/json') }
        ).pipe(
            map(data => data),
            catchError(() => of(null))
        );
    }

    private _prepareSkeepersInput(): InputSkeepers {
        const recoveryData = {
            query: 'average',
            product: ['all'],
            idWebsite: this.configService.data_refconf.skeepers_idWebsite,
            plateforme: 'fr'
        };
        return recoveryData;
    }

}
