import { Component, OnInit } from '@angular/core';
import { DialogRef } from '@ngneat/dialog';

@Component({
    selector: 'tlv-open-banking-modal',
    templateUrl: './cancel-open-banking-modal.component.html',
})
export class CancelOpenBankingModalComponent implements OnInit {
    public message: string;

    constructor(
        public ref: DialogRef,
    ) { }

    public ngOnInit(): void {
        this.message = this.ref.data?.message;
    }
}
