<section id="address-step" [formGroup]="portabilityForm" data-cy="portabilty-fai">
    <div class="columns is-marginless">
        <div class="column">
            <div class="box">
                <div class="columns is-marginless">
                    <div class="column is-flex is-vcentered is-paddingless">
                        <span class="icon is-medium has-text-info">
                            <i [ngClass]="step.icon"></i>
                        </span>
                        <span class="is-size-3-tablet is-size-4 is-flex is-vcentered" bytelTranslate="panier.portabilite.nom">
                            {{step.name}}
                        </span>
                    </div>
                </div>
                <div class="is-divider is-stretched"></div>
                <div class="columns is-multiline is-marginless has-text-centered is-centered">
                    <div class="column is-12">
                        <span class="is-size-4">Je conserve mon numéro</span>
                        <div class="popover is-popover-top">
                            <span class="icon is-small align-icon"><i class="tri-infos-circle"></i></span>
                            <div class="popover-content">
                                <p class="text is-3 is-marginless">
                                    La portabilité du numéro fixe vous facilite la vie ! Choisissez de porter le numéro de votre ligne et nous nous chargerons de résilier votre contrat internet auprès de votre ancien opérateur.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="column is-narrow">
                        <div class="options buttons is-centered" data-cy="choice-tunnel-portability">
                            <div class="field">
                                <div class="control has-text-centered">
                                    <input type="radio" formControlName="keepNumber" [value]="true" id="keep_number_yes"
                                           name="keepNumber">
                                    <label for="keep_number_yes" data-cy="choice-tunnel-portability-yes" class="button is-size-4"
                                           bytelTranslate="panier.portabilite.choix.oui">Oui</label>
                                </div>
                            </div>
                            <div class="field">
                                <div class="control has-text-centered">
                                    <input type="radio" id="keep_number_no" formControlName="keepNumber" [value]="false"
                                           name="keepNumber">
                                    <label for="keep_number_no" data-cy="choice-tunnel-portability-no" class="button is-size-4"
                                           bytelTranslate="panier.portabilite.choix.non">Non</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="column is-12" [class.is-hidden]="!portabilityForm.get('keepNumber')?.value">
                        <div class="columns is-centered is-multiline">
                            <div class="column is-8">
                                <tlv-weto-portability #wetoPortability [portability]="portability" (onError)="isLoading=false"></tlv-weto-portability>
                            </div>
                            <div class="column is-8">
                                <section class="accordions">
                                    <article class="accordion" [class.is-active]="toggleRioCodes" data-accordion-context="">
                                        <div class="accordion-header toggle" data-accordion-toggle="" (click)="toggleRioCodes = !toggleRioCodes">
                                            <span class="link is-static">Autre méthode de récupération du RIO</span>
                                            <button class="toggle" [class.rotate]="toggleRioCodes" aria-label="toggle"></button>
                                        </div>
                                        <div class="accordion-body">
                                            <div class="accordion-content has-background-white">
                                                <p>Si vous ne pouvez pas appeler le 3179 depuis votre ligne fixe, vous pouvez appeler le numéro correspondant à votre ancien opérateur depuis n'importe quel téléphone fixe ou mobile :</p>
                                                <table class="table is-bordered is-fullwidth">
                                                    <tbody>
                                                    <colgroup>
                                                        <col>
                                                        <col>
                                                    </colgroup>
                                                    <tr *ngFor="let operator of rioOperatorInfos">
                                                        <td><span class="has-text-weight-medium">{{operator.name}}</span></td>
                                                        <td>{{operator.info}}</td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </article>
                                </section>
                            </div>
                        </div>
                    </div>

                    <div [class.is-hidden]="portabilityForm.get('keepNumber')?.value" class="column is-12">
                        <div class="columns is-variable is-2 is-centered">
                            <div class="column is-8">
                                <p class="is-size-6 has-text-centered">Choix du n° VOIP (provisoire dans le cas d’une portabilité)</p>
                                <ng-container *ngIf="!isLoginLoaded else displayVoip">
                                    <tlv-loader iconSize="medium" loadingText="Chargement numéros VOIP en cours..."></tlv-loader>
                                </ng-container>
                                <ng-template #displayVoip>
                                    <tlv-loader iconSize="medium" loadingText="Chargement numéros VOIP en cours..." *ngIf="!isVoipLoaded"></tlv-loader>
                                    <tlv-weto-voip #wetoVoip [reservedVoip]="reservedVoip" (loaded)="setComponantVoipLoaded($event)" (onError)="isLoading=false" [hidden]="!isVoipLoaded"></tlv-weto-voip>
                                </ng-template>
                            </div>
                        </div>
                    </div>

                    <div class="column is-12">
                        <p class="is-size-4">Choix identifiants</p>
                    </div>
                    <div class="column is-8">
                        <div class="has-body is-small notification" *ngIf="reservedLogin">
                            <div class="icon">
                                <i class="tri-infos-circle" aria-hidden="true"></i>
                            </div>
                            <div class="body is-size-6">
                                <p class="title">Voici le login précédemment saisi : <span class="has-text-weight-semibold">{{reservedLogin}}&#64;bbox.fr</span></p>
                            </div>
                        </div>
                        <ng-container *ngIf="!isPortabilityLoaded else displayLogin">
                            <tlv-loader iconSize="medium" loadingText="Chargement identifiants LOGIN en cours..."></tlv-loader>
                        </ng-container>
                        <ng-template #displayLogin>
                            <tlv-loader iconSize="medium" loadingText="Chargement identifiants LOGIN en cours..." *ngIf="!isLoginLoaded"></tlv-loader>
                            <tlv-weto-login #wetoLogin [reservedLogin]="reservedLogin" [isCustomLogin]="isCustomLogin" (loaded)="setComponantLoginLoaded($event)" (onError)="isLoading=false" [hidden]="!isLoginLoaded" ></tlv-weto-login>
                        </ng-template>
                    </div>
                </div>
                <div class="buttons is-centered">
                    <button class="button is-primary button" data-cy="portability-validation" [disabled]="!canDisabled() || isLoading"  (click)="submit()">{{isLoading ? 'Traitement en cours...' : 'Continuer'}}</button>
                </div>
            </div>
        </div>
    </div>
</section>
