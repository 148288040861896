<div class="grid-container" data-cy="order-recovery-error">
    <div class="columns is-centered">
        <div class="colum is-narrow">
            <div class="box">
                <div class="box-content">
                    <p class="is-size-4">Cette commande est introuvable, merci de vérifier de nouveau son statut.</p>
                    <div class="buttons is-centered">
                        <button data-cy="order-recovery-end-call" class="is-primary button" (click)="endCall()">Terminer session</button>
                    </div>
                </div>  
            </div>
        </div>
    </div>
</div> 