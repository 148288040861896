import { Pipe, PipeTransform } from '@angular/core';
import { Fai, ProductFactory, Subscription } from '@bytel/bytel-sales';

@Pipe({
    name: 'isSmartTV'
})

export class SmartTVPipe implements PipeTransform {

    transform(plan: Subscription): boolean {
        if (plan && ProductFactory.Is(plan, Fai)) {
            return [
                Fai.RANGES.BBOX_SMART_TV,
                Fai.RANGES.BBOX_SMART_TV_LARGE,
                Fai.RANGES.SL_LARGE_SMART_TV
            ].includes((plan).range);
        } else {
            return false;
        }
    }

}

