import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Edp, Equipment, Faim, FaimPremium, Phone, ProductFactory } from '@bytel/bytel-sales';
import { DialogService } from '@ngneat/dialog';
import { CartTeleSalesService } from '@services/checkout/cart-telesales.service';
import { EdpService } from '@services/checkout/edp.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'tlv-equipment',
    templateUrl: './equipment.component.html',
    styleUrls: ['./equipment.component.scss']
})
export class EquipmentComponent implements OnInit, OnDestroy {

    @Input() product: Equipment;

    public equipementImages: string;
    public showEdp: boolean;
    public edp: Edp = null;
    public odrAmount: number = 0;
    public rsfAmount: number = 0;
    public rsfPdf: string = '';
    public isFaimType: boolean;
    public isPreOrder: boolean;

    private _subscriptions: Subscription[] = [];

    constructor(
        private dialogService: DialogService,
        private edpService: EdpService,
        public cartService: CartTeleSalesService) {
        this._subscriptions.push(this.cartService.refreshObs.subscribe(() => {
            this.showEdp = this.edpService.isEdpAllowed(this.cartService.cartModel);
            this.edp = this.cartService.cartModel.getQuote()?.getProductByType('Edp');
            if (this.product){
                const equipement = this.cartService.cartModel.getQuote()?.getPrincipalProduct<Equipment>('Equipment');
                this.product = Object.assign(Object.create(Object.getPrototypeOf(this.product)), equipement);
            }
        }));
    }

    public ngOnInit(): void {
        this.isPreOrder = this.cartService.cartModel.getQuote().isPreorder();
        this.equipementImages = this.product.imageHD + ',' + this.product.image;
        this.edp = this.cartService.cartModel.getQuote().getProductByType('Edp');
        this.odrAmount = this.product.data?.odr?.amount ?? 0;
        if (this.product instanceof Phone){
            this.rsfAmount = this.product.rsfAmount ?? 0;
            this.rsfPdf = this.product.rsfPdf ?? '';
        }
        const plan = this.cartService.cartModel.getQuote()?.getProductByType('Plan');
        this.isFaimType = ProductFactory.Is(plan, FaimPremium) || ProductFactory.Is(plan, Faim);
    }

    public ngOnDestroy(): void {
        for (const sub of this._subscriptions){
            sub.unsubscribe();
        }
    }

    public getQuoteIndex(): number {
        return this.cartService.cartModel.currentQuoteIndex;
    }
}
