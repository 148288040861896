import { Injectable, NgZone } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AppointmentStep } from '@components/checkout/step/appointment/appointment.step';
import { CartFaiStep } from '@components/checkout/step/cart/cart-fai.step';
import { CartSimoStep } from '@components/checkout/step/cart/cart-simo.step';
import { CartStep } from '@components/checkout/step/cart/cart.step';
import { CrossSellStep } from '@components/checkout/step/cross-sell/cross-sell.step';
import { CustomerInfoStep } from '@components/checkout/step/customer-info/customer-info.step';
import { DeliveryStep } from '@components/checkout/step/delivery/delivery.step';
import { PaymentStep } from '@components/checkout/step/payment/payment.step';
import { PortabilityInfoStep } from '@components/checkout/step/portability-info/portability-info.step';
import { SirenStep } from '@components/checkout/step/siren/siren.step';
import { IStep, STEP_STATUS } from '@interfaces/step.interface';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class StepperService {

    public change$: Observable<IStep[]>;
    private _steps: IStep[];
    private _changeSubject: BehaviorSubject<IStep[]> = new BehaviorSubject([]);

    constructor(private router: Router,
                cartStep: CartStep,
                cartSimoStep: CartSimoStep,
                cartFaiStep: CartFaiStep,
                crossSellStep: CrossSellStep,
                sirenStep: SirenStep,
                customerInfoStep: CustomerInfoStep,
                deliveryStep: DeliveryStep,
                paymentStep: PaymentStep,
                portabilityStep: PortabilityInfoStep,
                appointmentStep: AppointmentStep,
                private zone: NgZone,
    ) {
        this._steps = [
            cartStep,
            cartSimoStep,
            cartFaiStep,
            crossSellStep,
            sirenStep,
            customerInfoStep,
            portabilityStep,
            deliveryStep,
            appointmentStep,
            paymentStep
        ];
        this._changeSubject.next(this._steps);
        this.change$ = this._changeSubject.asObservable();
        this._updateStatus(this._getCurrentStepIndex());
        this.router.events.subscribe((event)=>{
            if (event instanceof NavigationEnd)
            {
                this._updateStatus(this._getCurrentStepIndex());
            }
        }

        );
    }

    public goToNextStep(): boolean {
        const index = this._getIndexOfStepAfter(this._getCurrentStepIndex());
        if (index === this._steps.length){return;}
        const currentStep: IStep = this.getCurrentStep();
        if (currentStep.validateStep() && this._steps[index].canAccess()) {
            this.goToIndex(index);
        } else {
            return false;
        }
    }

    public goToPreviousStep(): void{
        const index = this._getIndexOfStepBefore(this._getCurrentStepIndex());
        if (index < 0){return;}
        this.goToIndex(index);
    }

    public goToIndex(index: number): void{
        this._updateStatus(index);
        this.zone.run(() => {
            this.router.navigate(this._steps[index].route);
        });
    }

    public goToStep(step: IStep): void{
        const stepIndex = this._steps.findIndex(stp => stp.name === step.name);
        this.goToIndex(stepIndex);
    }

    public getCurrentStep(): IStep{
        return this._steps[this._getCurrentStepIndex()];
    }

    public getStepIndex(step: IStep): number{
        return this._steps.indexOf(step);
    }

    public refresh(): void {
        this._changeSubject.next(this._steps);
    }

    private _updateStatus(currentStepIndex: number): void{
        for (let i = 0;i < this._steps.length; i++){
            if (i < currentStepIndex){
                this._steps[i].status = STEP_STATUS.VALIDATED;
            } else if (i === currentStepIndex){
                this._steps[i].status = STEP_STATUS.INPROGRESS;
            } else {
                this._steps[i].status = STEP_STATUS.WAITING;
            }
        }
        this._changeSubject.next(this._steps);
    }

    private _getIndexOfStepAfter(index: number): number {
        index++;
        while (!this._steps[index].canDisplay()){ index++; }
        return index;
    }

    private _getIndexOfStepBefore(index: number): number {
        index--;
        while (!this._steps[index].canDisplay()){ index--; }
        return index;
    }

    private _getCurrentStepIndex(): number{
        return this._steps.findIndex((step)=>this.router.url.split('?')[0].split('#')[0] === '/' + step.route.join('/'));
    }

}
