import { Injectable } from '@angular/core';
import { Oauth2ResourcesService } from '@common-modules';
import { ICmsBlock } from '@interfaces/cms.interface';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CmsRepository {

    constructor(private oauthService: Oauth2ResourcesService) { }

    public getBlock(idBlock: string): Observable<ICmsBlock>{
        return this.oauthService
            .setLocalService()
            .ventes()
            .cms(idBlock)
            .get();
    }
}
