import { Pipe, PipeTransform } from '@angular/core';
import { PRICE_TYPES, Product } from '@bytel/bytel-sales';

@Pipe({
    name: 'priceType',
    pure: true
})
export class PriceTypePipe implements PipeTransform {
    transform(items: Product[], priceType: PRICE_TYPES): Product[] {
        if (!items) {
            return [];
        }

        return items.filter(product => product.priceType === priceType);
    }
}
