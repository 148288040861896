export enum NOTIFICATIONS_TYPES_CLASS {
    INCIDENT = 'is-warning',
    COMMERCIAL_OFFER = 'is-success',
    INFORMATION = 'is-secondary'
}

export enum NOTIFICATIONS_TYPES_LABEL {
    INCIDENT = 'Incident',
    COMMERCIAL_OFFER = 'Offre commerciale',
    INFORMATION = 'Information'
}
