import { Component } from '@angular/core';
import { Plan, QuoteModel } from '@bytel/bytel-sales';
import { CatalogService } from '@services/catalog.service';
import { AccessoryService } from '@services/checkout/accessory.service';
import { CartTeleSalesService } from '@services/checkout/cart-telesales.service';
import { OptionsAvailableService } from '@services/checkout/options-available.service';
import { StepperService } from '@services/checkout/stepper.service';

@Component({
    selector: 'tlv-cross-sell-step',
    templateUrl: './cross-sell.component.html',
    styleUrls: ['./cross-sell.component.scss']
})
export class CrossSellComponent {
    public isLoading: boolean = false;
    public isPreorder: boolean = false;
    public quotes: QuoteModel[] = [];

    public hasAvailableAccessory: boolean = false;
    public hasAvailableOption: boolean = false;
    public isMultiQuote: boolean = false;
    public displayLoader: boolean = false;

    constructor(
        protected cartTeleSalesService: CartTeleSalesService,
        protected catalogService: CatalogService,
        protected stepperService: StepperService,
        protected accessoryService: AccessoryService,
        protected optionsAvailableService: OptionsAvailableService
    ) {
        this.isMultiQuote = this.cartTeleSalesService.isQuoteMixed();
        this.optionsAvailableService.update$
            .subscribe((opts) => {
                const currentCartPlan: Plan = this.cartTeleSalesService.cartModel.getQuote().getProductByType(Plan);
                this.quotes = []; // Force refresh
                this.quotes = this.cartTeleSalesService.cartModel.quotes.filter(
                    quote => opts[quote.getPrincipalProduct(Plan)?.gencode]?.length
                );
                this.hasAvailableOption = this.cartTeleSalesService.isQuoteMixed()
                    ? !!this.quotes.length : opts[currentCartPlan.gencode]?.length > 0;
            });
        this.accessoryService.update$.subscribe((accs) => {
            this.hasAvailableAccessory = accs.length > 0;
        });

        this.optionsAvailableService.displayCrossSellLoader$.subscribe((state) => {
            this.displayLoader = state;
        });
    }

    public validate(): void {
        this.stepperService.goToNextStep();
    }

    public getQuoteIndex(quoteContext): number {
        return this.cartTeleSalesService.cartModel.quotes.findIndex(
            quote => quote.context.id === quoteContext
        );
    }

}
