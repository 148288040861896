<section data-cy="cross-sell">
    <ng-container *ngIf="!isMultiQuote">
        <h3 class="is-title is-size-3 has-text-weight-semibold">Mon récapitulatif</h3>
        <tlv-cart-summary></tlv-cart-summary>
    </ng-container>

    <div class="columns is-multiline">
        <div class="column is-full">
            <ng-container *ngIf="!isMultiQuote && hasAvailableAccessory">
                <tlv-accessories></tlv-accessories>
            </ng-container>
        </div>
        <div class="column is-full">
            <ng-container *ngIf="hasAvailableOption">
                <ng-container *ngFor="let quote of quotes">
                    <tlv-options [quote]="quote" [quoteIndex]="getQuoteIndex(quote.context.id)"></tlv-options>
                </ng-container>
            </ng-container>
        </div>
        <div class="column has-text-centered">
            <!-- Bouton valider à virer -->
            <div class="field">
                <button class="is-primary button" [disabled]="isLoading" [data-cy]="'cart-validate-btn'" (click)="validate()" type="submit">
                    <span *ngIf="!isPreorder">{{isLoading ? 'Traitement en cours...' : 'Valider'}}</span>
                    <span *ngIf="isPreorder">{{isLoading ? 'Traitement en cours...' : 'Pré-commander'}}</span>
                </button>
            </div>
        </div>
    </div>
</section>

<div class="cart-loader" *ngIf="displayLoader">
    <div class="loader cart-loader-body">
        <div class="loader-body"></div>
    </div>
</div>

