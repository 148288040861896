import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AddressRepository } from '@repositories/address.repository';
import { AddressPredictionModel } from '@models/address-prediction.model';
import { AddressModel } from '@models/cart/address.model';


@Injectable({
    providedIn: 'root'
})
export class AddressAutocompleteService {

    constructor(private addressRepository: AddressRepository) { }

    public getPrediction(term: string): Observable<AddressPredictionModel[]> {
        return this.addressRepository.getAddressesPrediction(term);
    }
    public getAddressOfPrediction(prediction: AddressPredictionModel): Observable<AddressModel> {
        return this.addressRepository.getAddressOfPrediction(prediction);
    }

}
