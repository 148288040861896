<div *ngIf="searchObject.searchValue">
    <p *ngIf="!searchObject.isClient" data-cy="rslt-prospect-msg">Résultat de la recherche prospect sur l'email : <span class="has-text-weight-semibold"> {{searchObject.searchValue}} </span></p>
    <p *ngIf="searchObject.isClient"  data-cy="rslt-client-msg">Résultat de la recherche sur le numéro de téléphone : <span class="has-text-weight-semibold"> {{searchObject.searchValue}} </span></p>
</div>
<div class="columns is-centered" *ngIf="customer && !customer.isClient" data-cy="rslt-prospect-block">
    <div class="column is-6">
        <strong class="field">
            <span>{{customer.firstname}} - </span>
            <span>{{customer.lastname}}</span>
        </strong>
        <div class="field">
            <button [disabled]="isClientLockByScoring || isLoading" type="button" class="button is-small is-primary" [data-cy]="'customer-info-submit'" (click)="nextStep()">
                {{ isLoading ? 'Traitement en cours...' : 'Continuer' }}
            </button>
        </div>
    </div>
</div>
