<div class="has-text-centered-touch" [data-cy]="'cart-item-'+product.data.gencode">
    <p class="has-text-tertiary is-size-1-mobile is-capitalized has-text-weight-semibold" [data-cy]="'cart-item-'+product.data.gencode+'-naming'">
        <span class="is-size-3-tablet has-text-weight-medium">{{product.manufacturer}} </span><span
            class="is-size-2-tablet">{{product.name}}</span>
    </p>
    <div class="is-divider has-background-white"></div>
    <div class="columns is-mobile is-vcentered is-hcentered-tablet is-centered-mobile is-centered-tablet is-multiline">
        <div class="column is-narrow">
            <picture>
                <img tlvImageLoading [btlSrc]="product.image" [btlSrcSet]="equipementImages" [btlSize]="'200w,400w'"
                     alt={{product.data.title}} class="phone-image">
            </picture>
        </div>
        <div class="column is-narrow is-12-desktop has-text-left" [data-cy]="'cart-item-'+product.data.gencode+'-extra-infos'">
            <p>
                <span class="is-block is-size-5" *ngIf="product.capacity">
                    Capacité <span class="has-text-weight-semibold is-size-5">{{product.capacity}}</span>
                </span>
                <span class="is-block is-size-5" *ngIf="product.color">
                    Couleur <span class="has-text-weight-semibold is-size-5">{{product.color}}</span>
                </span>
                <span class="is-block" *ngIf="isPreOrder">
                    <span class="icon-and-text">
                        <span class="icon is-small has-text-info"><i class="tri-calendar-clock-circle"></i></span>
                        <span>Disponible à partir du {{product.data.preorder_to_date | date : 'longDate'}}</span>
                    </span>
                </span>
            </p>
        </div>
    </div>
</div>
<div [ngClass]="{'prices-block':!isFaimType, 'faim-price-spacer': isFaimType}" class="columns is-mobile is-multiline is-vcentered" [data-cy]="'equipement-'+product.gencode">
    <div *ngIf="showEdp" class="column is-12">
        <tlv-payment-methods data-cy="edp-payment-method"></tlv-payment-methods>
    </div>
    <div [class.prices-block-item]="!isFaimType" class="column is-12 has-text-centered-touch">
        <tlv-price data-cy="price" *ngIf="product.prices.base > (product | priceFinalEquipment)" [isDiscountPrice]="true" [price]="product.prices.base" class="is-margin" size-5></tlv-price>
        <tlv-price data-cy="price-discount" [price]="product | priceFinalEquipment" class="is-margin" size-2></tlv-price>

        <p *ngIf="showEdp && edp" class="is-size-6">+{{edp.data.price}} <span>€/mois pendant </span>{{edp.data.duration}}<span> mois</span></p>

        <div *ngIf="odrAmount" class="is-block">
            <a [href]="product.data.odr.file" class="link has-text-info is-size-7" rel="noopener noreferrer"
               target="_blank" rel="noopener noreferrer"><span>après &nbsp;</span>{{odrAmount}}<span>€ remboursés</span></a>
        </div>

        <div *ngIf="rsfAmount" class="is-block">
            <a [href]="rsfPdf" class="link has-text-info is-size-7" rel="noopener noreferrer"
               target="_blank" rel="noopener noreferrer">{{rsfAmount}} <span>€ remboursés sur votre 3ème facture</span></a>
        </div>
    </div>
</div>
