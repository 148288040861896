import { Component, Input, EventEmitter, Output } from '@angular/core';

export interface NotifInfos {
    title: string;
    img: number;
}

@Component({
    selector: 'tlv-notifications-images',
    templateUrl: './notifications-images.component.html',
    styleUrls: ['./notifications-images.component.scss']
})

export class NotificationsImagesComponent {

    @Output() closeModal: EventEmitter<any> = new EventEmitter();
    @Input() imageInfos: NotifInfos;

    public closeImage(): void {
        this.closeModal.emit(null);
    }

}
