<form class="box box-cart insurance"   >
    <div class="has-text-left">
        <div class="columns is-vcentered">
            <div class="column is-8-widescreen is-7-desktop is-6-tablet">
                <p class="is-size-3 has-text-weight-semibold">Je protège mon mobile</p>
            </div>
        </div>
        <div class="columns has-text-left">
            <div class="column is-9">
                <div [formGroup]="insuranceControlForm" class="radios-list">
                    <div (click)="addInsurance()" class="radio-tile is-horizontal" [tlvPrismeLogger]="'Ajout d\'une assurance au panier'"
                        [action]="LOG_ACTIONS.CLICK">
                        <input
                            [value]="true"
                            data-cy="insurance_yes"
                            formControlName="choiceInsurance"
                            id="insuranceCheckedYes"
                            name="choiceInsurance"
                            type="radio"
                            />
                        <label class="radio-label" for="insuranceCheckedYes">
                            <span>
                                <span class="radio-title">
                                    <div class="columns is-mobile is-multiline">
                                        <div class="column is-12-mobile is-8-tablet is-8-desktop">
                                            <span class="is-size-4 is-size-6-mobile">Avec assurance</span>
                                            <span class="is-size-4 is-size-6-mobile ml-1">
                                                {{insurance.prices.base}}
                                            </span>
                                            <span class="is-size-4 is-size-6-mobile">€/mois</span>
                                        </div>
                                        <div
                                            class="column has-text-left-mobile has-text-left-tablet has-text-right-desktop is-12-mobile is-4-tablet is-4-desktop">
                                            <span class="tag is-secondary">Recommandé</span>
                                        </div>
                                    </div>
                                </span>
                                <span class="radio-description">
                                    <hr class="is-divider" />
                                    <div class="has-text-left">
                                        Une assurance qui va à l’essentiel sans franchise sans surprise
                                    </div>
                                    <ul class="icon-list">
                                        <li>
                                            <span class="icon is-small is-secondary">
                                                <i aria-hidden="true" class="tri-check"></i>
                                            </span>
                                            <span class="has-text-weight-semibold">
                                                La garantie est définie par le n°IMEI
                                            </span>
                                        </li>
                                        <li>
                                            <span class="icon is-small is-secondary">
                                                <i aria-hidden="true" class="tri-check"></i>
                                            </span>
                                            <span class="has-text-weight-semibold">
                                                Votre téléphone est réparé ou remplacé sans frais s'il est
                                                inutilisable en cas de casse vol oxydation suite à un
                                                contact avec un liquide
                                            </span>
                                        </li>
                                        <li>
                                            <span class="icon is-small is-secondary">
                                                <i aria-hidden="true" class="tri-check"></i>
                                            </span>
                                            <span class="has-text-weight-semibold">
                                                En cas de sinistre votre téléphone est réparé ou remplacé
                                            </span>
                                        </li>
                                        <li>
                                            <span class="icon is-small is-secondary">
                                                <i aria-hidden="true" class="tri-check"></i>
                                            </span>
                                            <span class="has-text-weight-semibold">
                                                Ne garantit pas la panne ni la perte de votre smartphone
                                            </span>
                                        </li>
                                        <li>
                                            <span class="icon is-small is-secondary">
                                                <i aria-hidden="true" class="tri-check"></i>
                                            </span>
                                            <span class="has-text-weight-semibold">
                                                Engagement 12 mois prélevé par L’assurance SPB délai de renonciation de 30 jours
                                            </span>
                                        </li>
                                    </ul>
                                    <div class="pb-8 mt-2 has-text-left">
                                        <a
                                            class="link is-static"
                                            href="https://www.bouyguestelecom.fr/static/pdf/assurance_mobile.pdf"
                                            rel="noopener noreferrer"
                                            target="_blank">
                                            Lire la notice de l’assurance
                                        </a>
                                    </div>
                                </span>
                            </span>
                        </label>
                    </div>
                    <div (click)="removeInsurance()" class="radio-tile is-horizontal">
                        <input
                            [value]="false"
                            data-cy="insurance_no"
                            formControlName="choiceInsurance"
                            id="insuranceCheckedNo"
                            name="choiceInsurance"
                            type="radio" />
                            <label class="radio-label" for="insuranceCheckedNo">
                                <span>
                                    <span class="radio-title">
                                        <span class="is-size-4 is-size-6-mobile">Sans assurance</span
                                    ></span>
                                </span>
                            </label>
                    </div>
                </div>
            </div>
            <div class="column">
                <img
                    alt=""
                    src="https://www.bouyguestelecom.fr/portailvente/checkout/assets/checkout/insurance.jpg" />
            </div>
        </div>
    </div>
</form>
