<section class="step-container" id="appointment-container" data-cy="appointment">
    <div class="box">
        <div class="columns is-marginless">
            <div class="column is-flex is-vcentered is-paddingless">
                <span class="icon is-medium has-text-info">
                    <i class="tri-calendar-clock-circle"></i>
                </span>
                <span class="is-size-3-tablet is-size-4 has-text-weight-bold is-flex is-vcentered" bytelTranslate="panier.livraison.adresses">
                    Prise de rendez-vous
                </span>
            </div>
        </div>
        <div class="notification is-small is-waiting has-body" *ngIf="preorderFTTH">
            <div class="icon">
                <i class="tri-exclamation-circle"></i>
            </div>
            <div class="body">
                <p>
                    Information : la fibre arrive le {{preorderFTTH}} à cette adresse. Les créneaux de RDV sont donc proposés à partir de cette date
                </p>
            </div>
        </div>
        <div class="is-divider is-stretched"></div>
        <div class="columns is-multiline is-marginless">
            <div class="column is-full">
                <ng-template #customCellTemplate let-day="day" let-locale="locale">
                    <div class="cal-cell-top">
                        <span class="cal-day-number" [ngClass]="{'active': day.badgeTotal > 0}">{{ day.date }}</span>
                        <span class="cal-day-badge" *ngIf="day.badgeTotal > 0">{{ day.badgeTotal }}</span>
                    </div>
                </ng-template>

                <form *ngIf="obsSlots" [formGroup]="appointmentForm" id="appointment-form">
                    <section class="section is-paddingless has-background-white">


                        <p *ngIf="callOut" class="is-5 has-text-centered">Seulement a titre indicatif, ce calendrier  <span class="has-text-weight-bold"> ne réserve pas </span> le rdv d'installation</p>
                        <p *ngIf="!callOut" class="title is-size-3 has-text-centered title-rdv" bytelTranslate="panier.rdv_ftth.titre">Choisissez votre rendez-vous avec un technicien</p>
                        <div>
                            <bytel-appointments-calendar data-cy="appointment-calendar"
                                [appointments$]="obsSlots"
                                [storedAppointment]="currentSlot"
                                [prefillValueDemand]="prefillCalendar"
                                [preselectDate]="preselectedDate"
                                [callOut]="callOut"
                                headerTextAppointment="Le technicien arrivera durant le créneau choisi"
                                (selectAppointmentEmitter)="handleSelectedAppointment($event)">
                            </bytel-appointments-calendar>
                        </div>
                    </section>
                    <div *ngIf="(obsSlots | async)?.length === 0" class="notification is-small has-body is-danger">
                        <div class="icon" aria-label="Danger">
                          <i class="tri-exclamation-circle" aria-hidden="true"></i>
                        </div>
                        <div class="body">
                          <p class="title">Aucun créneau n'est disponible, merci de terminer la session</p>
                        </div>
                    </div>

                    <section class="section box has-background-white" *ngIf="!callOut">
                        <div class="columns is-multiline">
                            <div class="column is-8">
                                <p class="has-text-weight-semibold" bytelTranslate="panier.rdv_ftth.choix.disponibilite.titre">Pour préparer votre rendez-vous, un conseiller va vous contacter. Quelles sont vos disponibilités ?</p>
                            </div>

                            <div class="column is-2">
                                <div class="rows">
                                    <div class="control row">
                                        <div class="field">
                                            <input class="input is-checkradio" type="radio"
                                                    formControlName="disponibilitySlot" data-cy="disponibility-slots"
                                                    name="disponibilitySlot"
                                                    id="morning" [value]="disponibilitySlots.morning" required>
                                            <label for="morning" bytelTranslate="panier.rdv_ftth.choix.disponibilite.matin">Matin</label>
                                        </div>
                                    </div>

                                    <div class="control row">
                                        <div class="field">
                                            <input class="input is-checkradio" type="radio"
                                                    formControlName="disponibilitySlot" data-cy="disponibility-slots"
                                                    name="disponibilitySlot"
                                                    id="afternoon" [value]="disponibilitySlots.afternoon" required>
                                            <label for="afternoon" bytelTranslate="panier.rdv_ftth.choix.disponibilite.apres_midi">Après-midi</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="column is-2">
                                <div class="rows">
                                    <div class="control row">
                                        <div class="field">
                                            <input class="input is-checkradio" type="radio"
                                                    formControlName="disponibilitySlot" data-cy="disponibility-slots"
                                                    name="disponibilitySlot"
                                                    id="evening" [value]="disponibilitySlots.evening" required>
                                            <label for="evening" bytelTranslate="panier.rdv_ftth.choix.disponibilite.soir">Soir</label>
                                        </div>
                                    </div>

                                    <div class="control row">
                                        <div class="field">
                                            <input class="input is-checkradio" type="radio"
                                                    formControlName="disponibilitySlot"
                                                    name="disponibilitySlot" data-cy="disponibility-slots"
                                                    id="weekend" [value]="disponibilitySlots.weekend" required>
                                            <label for="weekend" bytelTranslate="panier.rdv_ftth.choix.disponibilite.week_end">Week-end</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ul *ngIf="appointmentForm.get('disponibilitySlot').invalid  && appointmentForm.get('disponibilitySlot').touched" class="help is-danger column has-text-right">
                                <li *ngIf="appointmentForm.get('disponibilitySlot').errors.required" bytelTranslate="panier.rdv_ftth.choix.disponibilite.erreur">Veuillez indiquer un créneau de disponibilité</li>
                            </ul>
                        </div>
                    </section>

                    <section class="section box has-background-white" *ngIf="!callOut">
                        <p class="title is-size-3" bytelTranslate="panier.rdv_ftth.choix.informations_complementaires">Informations complémentaires</p>
                        <div class="columns">
                            <div class="column is-full">
                                <p><span class="has-text-weight-semibold" bytelTranslate="panier.rdv_ftth.choix.informations_complementaires.adresse">Votre adresse: </span><span data-cy="inline-address-valid">{{ installationAddress }}</span></p>
                            </div>
                        </div>

                        <div class="columns">
                            <div class="column is-4">
                                <div class="field">
                                    <input class="input" formControlName="contactNumber" id="contactNumber"
                                            mask="00 00 00 00 00"
                                            placeholder="Numéro de contact*"
                                            required type="text" data-cy="contactNumber">
                                </div>
                                <ul *ngIf="appointmentForm.get('contactNumber').invalid && appointmentForm.get('contactNumber').touched" class="help is-danger" data-cy="contact-number-error">
                                    <li *ngIf="appointmentForm.get('contactNumber').errors.required" bytelTranslate="panier.rdv_ftth.choix.informations_complementaires.telephone.erreur">Veuillez indiquer un numéro de contact</li>
                                    <li *ngIf="appointmentForm.get('contactNumber').errors.pattern" bytelTranslate="panier.rdv_ftth.choix.informations_complementaires.telephone.erreur">Veuillez indiquer un numéro de contact valid</li>
                                </ul>
                            </div>
                            <div class="column is-4">
                                <div class="field">
                                    <input class="input" formControlName="digicode" id="digicode" data-cy="digicode"
                                            maxlength="10" placeholder="Digicode" type="text">
                                </div>
                            </div>
                            <div class="column is-4">
                                <div class="field">
                                    <input class="input" formControlName="interphone" id="interphone" data-cy="interphone"
                                            maxlength="10" placeholder="Interphone" type="text">
                                </div>
                            </div>
                        </div>

                        <div class="columns">
                            <div class="column is-fullwidth">
                                <textarea class="textarea" formControlName="comment" id="comment" data-cy="comment"
                                            placeholder="Vous pouvez saisir toute information utile pour le technicien (nom du gardien, nom de la personne présente...).">
                                </textarea>
                            </div>
                        </div>
                    </section>

                    <section *ngIf="currentSlot && !callOut" class="section box has-background-white" >
                        <div class="columns">
                            <div>
                                <span class="icon is-large"><i class="tri-calendar"></i></span>
                            </div>

                            <div class="column" data-cy="current-slot">
                                <p class="has-text-weight-semibold" bytelTranslate="panier.rdv_ftth.resume.titre"> Vous avez rendez-vous le :</p>
                                <p data-cy="current-slot-msg">{{ currentSlot.start | date:'EEEE dd LLLL yyyy'}}, entre {{ currentSlot.start | date:'HH' }}h et {{ currentSlot.end | date:'HH' }}h au {{ installationAddress }}</p>
                            </div>
                        </div>
                    </section>

                    <section class="section box has-background-white">
                        <div class="columns">
                            <div>
                                <span class="icon is-large"><i class="tri-lightbulb-on"></i></span>
                            </div>

                            <div class="column">
                                <p class="has-text-weight-semibold" bytelTranslate="panier.rdv_ftth.informations.titre">Bon à savoir</p>
                                <p bytelTranslate="panier.rdv_ftth.informations.description">Un empêchement ? Vous avez jusqu’à 1 jour (hors dimanche) avant la date fixée pour modifier ce rendez-vous.</p>
                            </div>
                        </div>
                    </section>
                    <div class="buttons">
                        <button (click)="prefillFields()" class="is-info button" *ngIf="mock">Remplir</button>
                        <div>
                            <button (click)="submit()" data-cy="appointment-submit" [disabled]="(!callOut && appointmentForm.invalid) || isLoading" class="is-primary button">
                                <span *ngIf="!isLoading" bytelTranslate="panier.rdv_ftth.valider">{{ callOut ? 'Suivant' : 'Valider' }}</span>
                                <span *ngIf="isLoading">Traitement en cours...</span>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>
