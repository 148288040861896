import {ErrorHandler, Injectable} from '@angular/core';
import {PrismeLoggerService} from '@services/prisme-logger.service';
import {HttpErrorResponse} from '@angular/common/http';

@Injectable()
export class ErrorInterceptor extends ErrorHandler {
    constructor(private prismeLoggerService: PrismeLoggerService) {
        super();
    }
    public handleError(error): void {
        super.handleError(error);
        // remove stack car trop long pour prisme
        delete error?.error?.stack;
        if (error instanceof HttpErrorResponse) {
            this.prismeLoggerService.sendDataToPrisme('Erreur HTTP', {error});
        } else {
            this.prismeLoggerService.sendDataToPrisme('Erreur technique', {error});
        }
    }
}
