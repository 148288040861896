<div class="columns is-centered" *ngIf="loading && !options.length">
    <div class="column is-narrow is-size-3">
        <tlv-loader iconSize="medium" isAnimated="true" loadingText="Chargement des options {{isMultiQuote ? planTypeName : ''}}..."></tlv-loader>
    </div>
</div>

<div data-cy="option-block" *ngIf="options.length">
    <div data-cy="order-recovery-options" class="notification is-small is-info has-body" *ngIf="orderRecovery?.cart.options.length">
        <div class="icon">
            <i class="tri-exclamation-circle"></i>
        </div>
        <div class="body">
            <h5 class="title">Options issues de la commande précedente</h5>
            <p class="has-text-weight-semibold">Rappel: voici le listing des options précédemment ajoutées:</p>
            <ul>
                <li *ngFor="let opt of orderRecovery.cart.options">- {{opt.name}}</li>
            </ul>
        </div>
    </div>
    <div class="columns is-fullwidth is-marginless">
        <div class="column is-6 is-flex">
            <span class="is-size-3 has-text-weight-semibold">
                Je choisis mes options<span class="is-capitalized" *ngIf="planTypeName && isMultiQuote">&nbsp;{{planTypeName}}</span>
            </span>
        </div>
    </div>
    <form>
        <div class="columns is-multiline is-marginless">
            <ng-container *ngFor="let mOption of mainOption">
                <div class="column cover-image-ratio" [class.cover-image-ratio]="mainOption.length > 1">
                    <tlv-priority-option [option]="mOption" [mainOption]="mainOption" [quoteIndex]="quoteIndex" [isBullet]="true" class="is-fullheight"></tlv-priority-option>
                </div>
            </ng-container>
            <ng-container *ngIf="isCategoriesViewActive;else defaultView">
                <div class="column tabs is-centered is-clipped is-12" role="tablist" data-cy="option-tab">
                    <ul>
                        <li *ngFor="let category of tabsCategories">
                            <a [ngClass]="{'is-active': selectedCategory?.code === category.code, 'is-hidden':!category.visible}"
                                (click)="selectOptionsCategory(category)" role="tab">{{ category.name }}</a>
                        </li>
                    </ul>
                </div>
                <ng-container  *ngFor="let product of optionsCategories | slice : 0 : length">
                    <div class="column is-full-mobile is-one-third-tablet is-fullwidth-mobile">
                        <tlv-option-block [product]="product" [quoteIndex]="quoteIndex"></tlv-option-block>
                    </div>
                </ng-container>
            </ng-container>
            <ng-template #defaultView>
                <ng-container *ngFor="let product of options | slice: 0:length">
                    <div class="column is-full-mobile is-one-third-tablet is-fullwidth-mobile">
                        <tlv-option-block [product]="product" [quoteIndex]="quoteIndex"></tlv-option-block>
                    </div>
                </ng-container>
            </ng-template>
            <div class="field column is-12" *ngIf="options.length > defaultMaxLength">
                <div class="has-text-centered">
                    <a (click)="showMore()" *ngIf="length !== options.length" data-cy="option-more-btn"
                        class="link has-text-tertiary is-size-7">Voir plus d'options</a>
                    <a (click)="showLess()" *ngIf="length === options.length" data-cy="option-less-btn"
                        class="link has-text-tertiary is-size-7">Voir moins d'options</a>
                </div>
            </div>

        </div>
    </form>
</div>

