import { AfterViewInit, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CartModel, Plan, Product } from '@bytel/bytel-sales';
import { ProExclusiveOfferModalComponent } from '@components/modal/pro-exclusive-offer-modal/pro-exclusive-offer-modal.component';
import { CustomerProDetailsModel } from '@models/customer/customer-pro-details.model';
import { DialogService } from '@ngneat/dialog';
import { CartTeleSalesService } from '@services/checkout/cart-telesales.service';
import { StepperService } from '@services/checkout/stepper.service';
import { CustomerService } from '@services/customer/customer.service';
import { SalesForceService } from '@services/salesforce.service';
import { CartStep } from './step/cart';
import { CrossSellStep } from './step/cross-sell/cross-sell.step';

@Component({
    selector: 'tlv-checkout',
    templateUrl: './checkout.component.html',
    styleUrls: ['./checkout.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CheckoutComponent implements OnInit, AfterViewInit {

    constructor(
        private customerService: CustomerService,
        private dialogService: DialogService,
        private cartService: CartTeleSalesService,
        private stepperService: StepperService,
        private cartStep: CartStep,
        private crosssellStep: CrossSellStep,
        private salesForceService: SalesForceService
    ) {}

    public ngOnInit(): void {
        this._logProductAdditionOnCartRecovery();
    }

    public ngAfterViewInit(): void {
        this.customerService.customer$.subscribe(() => {
            this._checkCustomerCompatibilityWithOffer();
        });
        this._checkCustomerCompatibilityWithOffer();
    }

    private _checkCustomerCompatibilityWithOffer(): void {
        if (![
            this.cartStep.label,
            this.crosssellStep.label
        ].includes(this.stepperService?.getCurrentStep()?.label)) {
            return;
        }
        const isCustomerPro: boolean = this.customerService?.customer instanceof CustomerProDetailsModel;
        // eslint-disable-next-line max-len
        if (this.cartService.cartModel.getQuote()?.getPrincipalProduct<Plan>('Plan')?.isPro
            && this.customerService?.customer?.idPerson && !isCustomerPro) {
            this.dialogService.dialogs.forEach(dialog => dialog.close());
            this.dialogService.open(ProExclusiveOfferModalComponent, {
                closeButton: false,
                enableClose: false
            });
        }
    }


    private _logProductAdditionOnCartRecovery(): void {
        const orderRecovery = this.salesForceService.prefilledInfo?.order;
        if (!orderRecovery) { return; }

        this.cartService.refreshObs.subscribe((cartModel: CartModel) => {
            cartModel.getQuote().products.forEach((p: Product) => {
                orderRecovery.cart.logRestoredProduct(p.gencode);
            });
            this.salesForceService.save(this.salesForceService.prefilledInfo);
        });
    }
}

