<div data-cy="order-recovery-parental-option" class="notification is-small is-info has-body" *ngIf="parentalOptionFromRecovery">
    <div class="icon">
        <i class="tri-exclamation-circle"></i>
    </div>
    <div class="body">
        <h5 class="title">Option parentale issue de la commande initiale</h5>
        <p class="has-text-weight-semibold"></p>
        <p>{{parentalOptionFromRecovery.label}}</p>
    </div>
</div>
<div [formGroup]="parentalControlForm" class="box" *ngIf="parentalControlOptions?.length > 0" data-cy="parental-control">
    <div class="is-updating-data" *ngIf="waitForCartEdit">
        <tlv-loader iconSize="medium" loadingText="Mise à jour des options en cours..."></tlv-loader>
    </div>
    <div class="box-content has-text-left" [class.cancel-click]="!!waitForCartEdit">
        <div class="columns is-marginless is-vcentered">
            <div class="column is-8-widescreen is-7-desktop is-6-tablet">
                <div class="is-size-4 has-text-weight-semibold icon-and-text">
                    <span bytelTranslate="panier.controle_parental.titre">J'opte pour le contrôle parental</span>
                </div>
                <p *ngIf="!isFai" bytelTranslate="panier.controle_parental.utilisateur.question">Cette ligne est
                    destinée à un mineur ?</p>
                <p *ngIf="isFai" bytelTranslate="panier.controle_parental.protection_proches">Souhaitez-vous protéger
                    vos proches de contenus inappropriés ?</p>
            </div>
            <div *ngIf="!isFai" class="column is-4-widescreen is-5-desktop is-6-tablet">
                <div class="options buttons is-centered">
                    <div class="field">
                        <div class="control has-text-centered">
                            <input [value]="true" formControlName="choiceControl" id="control_choice_yes"
                                   name="choiceControl" type="radio" data-cy="choise-parentalC-yes">
                            <label class="button is-size-4" for="control_choice_yes"
                                   bytelTranslate="panier.controle_parental.mineur.oui">Oui</label>
                        </div>
                    </div>
                    <div class="field">
                        <div class="control has-text-centered">
                            <input [value]="false" formControlName="choiceControl" id="control_choice_no"
                                   name="choiceControl" type="radio" data-cy="choise-parentalC-no">
                            <label class="button is-size-4" for="control_choice_no"
                                   bytelTranslate="panier.controle_parental.mineur.non">Non</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ng-container *ngIf="this.parentalControlForm.get('parentalControl').enabled">
            <div class="row has-text-centered" data-cy="form-parentalC">
                <div class="is-small has-body is-shadowless">
                    <div class="is-stretched block-element">
                        <div class="is-divider"></div>
                    </div>
                    <div class="body columns is-fullwidth is-centered">
                        <div *ngFor="let parentalOption of parentalControlOptions" class="column is-4">
                            <div (click)="parentalControlForm.get('parentalControl').setValue(parentalOption.gencode)">
                                <div class="is-flex-mobile has-cursor">
                                    <ng-container *ngIf="premiumParentalControlGencodes.includes(parentalOption.gencode); else freeOptionTemplate">
                                        <div class="is-block mobile-order-2 mobile-salign-center">
                                            <span class="icon is-large">
                                                <i class="tri-users-shield-star"></i>
                                            </span>
                                        </div>
                                        <div class="is-flex is-justified-center mobile-order-1 mobile-salign-center">
                                            <div class="control">
                                                <input class="input is-checkradio"
                                                       [value]="parentalOption.gencode"
                                                       formControlName="parentalControl"
                                                       [attr.id]="parentalOption.gencode"
                                                       [attr.disabled]="waitForCartEdit"
                                                       name="parentalControl"
                                                       type="radio" data-cy="form-parentalC-premuim">
                                                <label [attr.for]="parentalOption.gencode"></label>
                                            </div>
                                        </div>
                                        <div class="is-block mobile-order-3 has-text-left-mobile">
                                            <p bytelTranslate="panier.controle_parental.choix.premium.oui">Oui, je
                                                souscris
                                                au contrôle parental
                                                <span bytelTranslate="panier.controle_parental.choix.premium">premium</span>
                                            </p>
                                            <span *ngIf="!parentalOption.data.wording_promo" [ngClass]="{'is-invisible' : parentalOption.data.price > 0}" class="has-text-weight-semibold is-size-4 is-block"
                                                bytelTranslate="panier.controle_parental.choix.gratuit">Gratuit</span>
                                            <p *ngIf="parentalOption.data.wording_promo"
                                               class="has-text-info has-text-weight-semibold"> {{ parentalOption.data.wording_promo }} </p>
                                            <span *ngIf="parentalOption.data.wording_promo"
                                                  bytelTranslate="panier.controle_parental.choix.premium.details.puis">puis &nbsp;</span>
                                            <tlv-price [hasPeriod]="true" [price]="parentalOption.data.price"
                                                       size-4></tlv-price>
                                            <div class="popover is-popover-top">
                                                <span class="icon is-small">
                                                    <i class="tri-question-circle"></i>
                                                </span>
                                                <div class="popover-content">
                                                    <p bytelTranslate="panier.controle_parental.choix.premium.details.partie_1">
                                                        La solution premium vous permet de protéger vos différents appareils sans limites.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>

                                    <ng-template #freeOptionTemplate>
                                        <div class="is-block mobile-order-2 mobile-salign-center">
                                            <span class="icon is-large">
                                                <i class="tri-user-shield"></i>
                                            </span>
                                        </div>
                                        <div class="is-flex is-justified-center mobile-order-1 mobile-salign-center">
                                            <div class="control">
                                                <input class="input is-checkradio"
                                                       [value]="parentalOption.gencode"
                                                       formControlName="parentalControl"
                                                       [attr.id]="parentalOption.gencode"
                                                       [attr.disabled]="waitForCartEdit"
                                                       name="parentalControl"
                                                       type="radio" data-cy="form-parentalC-free">
                                                <label [attr.for]="parentalOption.gencode"></label>
                                            </div>
                                        </div>
                                        <div class="is-block mobile-order-3 has-text-left-mobile">
                                            <p bytelTranslate="panier.controle_parental.choix.gratuit.oui">Oui, je souscris au
                                                contrôle parental
                                            </p>
                                            <span *ngIf="!parentalOption.data?.wording_promo" [ngClass]="{'is-invisible' : parentalOption.data?.price > 0}" class="has-text-weight-semibold is-size-4 is-block"
                                                bytelTranslate="panier.controle_parental.choix.gratuit">Gratuit</span>
                                            <p *ngIf="parentalOption.data?.wording_promo"
                                            class="has-text-info has-text-weight-semibold"> {{ parentalOption.data?.wording_promo }} </p>
                                            <span *ngIf="parentalOption.data?.wording_promo"
                                                bytelTranslate="panier.controle_parental.choix.premium.details.puis">puis &nbsp;</span>
                                            <tlv-price *ngIf="parentalOption.data?.price > 0" [hasPeriod]="true" [price]="parentalOption.data?.price"
                                                size-4></tlv-price>
                                        </div>
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                        <div (click)="removeParentalControlOp()" class="column is-4">
                            <div class="is-flex-mobile has-cursor" data-cy="form-parentalC-no-cursor">
                                <div class="is-block mobile-salign-center mobile-order-2 mobile-salign-center">
                                    <span class="icon is-large">
                                        <i class="tri-times-circle"></i>
                                    </span>
                                </div>
                                <div class="is-flex is-justified-center mobile-salign-center mobile-order-1">
                                    <div class="control">
                                        <input class="input is-checkradio"
                                            [attr.disabled]="waitForCartEdit"
                                            [value]="false"
                                            formControlName="parentalControl"
                                            id="none"
                                            name="parentalControl"
                                            type="radio" data-cy="form-parentalC-no">
                                        <label for="none"></label>
                                    </div>
                                </div>
                                <div class="is-block mobile-order-3 has-text-left-mobile">
                                    <p bytelTranslate="panier.controle_parental.choix.non">Non, je ne souhaite pas souscrire
                                        au contrôle parental</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>
