import { Pipe, PipeTransform } from '@angular/core';
import { Equipment, Phone, ProductFactory } from '@bytel/bytel-sales';

@Pipe({
    name: 'priceFinalEquipment',
})
export class PriceFinalEquipmentPipe implements PipeTransform {

    public transform(product: Equipment): number {
        let price: number = product.prices.final;
        if (ProductFactory.Is(product, Equipment)) {
            const odrAmount: number = product.data?.odr?.amount ?? 0;
            const rsfAmount: number = ProductFactory.Is(product, Phone) ? (product.rsfAmount ?? 0) : 0;
            price = price - odrAmount - rsfAmount;
            price = Math.max(price, product.minPromoPrice);
        }

        return price;
    }
}
